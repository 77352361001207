import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Button, ButtonToolbar, Card, Col, Container, Form, Row, Spinner, Modal, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
// @ts-ignore
import DualListBox from 'react-dual-listbox';
import moment from 'moment';

import FormHeader from '../../clients/components/forms/FormHeader';
import { StateDropdown } from '../../../components/StateDropdown';
import PageSectionContainer from '../../../components/layout/PageSectionContainer';
import Table from '../../../components/Table';
import { CurrencyFormatter } from '../../../components/Formatters';
import { Client, FundingMethodEnum, DebitFeeMethodEnum, PaymentChannelTypeEnum } from '../../../models/Client';
import { MsbReportItem, ReportDownloadType } from '../../../models/Reports';
import { TransactionTypeEnum, PaymentTypeEnum, SearchList } from "../../../models/Payment";

import { getClientsAction } from '../../../redux/actions/clients/clients';
import { getDynamicQueryReport, SET_RECON_DETAILS_DATA } from '../../../redux/actions/reports/report';
import { IActionResult, IAppState } from '../../../redux/storeTypes';
import { Crumb } from "../../../models/Crumb";
import PageHeader from "../../../components/layout/PageHeader";
import { Routes } from "../../../routes";
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';
import { 
    createExcel, CREATE_DOCUMENT_FAILURE, CREATE_DOCUMENT_REQUEST, CREATE_DOCUMENT_SUCCESS, CLEAR_DOCUMENT 
} from '../../../redux/actions/document';
import { sendErrorToastAction } from "../../../redux/actions/toast";
import { Redirect } from "react-router-dom";

export interface ReconciliationReportProps {
    isFetching: boolean,
    items: Array<MsbReportItem>,
    clients: Array<Client>,
    documentContentUrl?: string,
    searchFields: SearchList,
    documentActionResult: IActionResult,
    blobObject?: Blob,
    actionResult: IActionResult,
}

const ReconciliationReport = ({ isFetching, items, clients, documentContentUrl, searchFields, documentActionResult, blobObject }: ReconciliationReportProps) => {
    const actionToken = "getReconciliationBatchReport";
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");

    const defaultStartDate = moment().subtract(1, "day").format('YYYY-MM-DD');
    const defaultEndDate = moment().subtract(1, "day").format('YYYY-MM-DD');

    const [startDate, setStartDate] = useState<string>(defaultStartDate);
    const [endDate, setEndDate] = useState<string>(defaultEndDate);
    const [fundingType, setFundingType] = useState<FundingMethodEnum>();
    const [debitFeeMethod, setDebitFeeMethod] = useState<DebitFeeMethodEnum>();
    const [state, setState] = useState<string>("");
    const [departmentIds, setDepartmentIds] = useState<Array<string>>([]);
    const [showModal, setModal] = useState(false);
    const [deptToItems, setDeptToItems] = useState<Map<string, Array<MsbReportItem>>>();

    const [waitingForDownload, setWaitingForDownload] = useState<boolean>(false);
    const [documentContentUrlWithSearchParams, setDocumentContentUrlWithSearchParams] = useState<string>();
    const actionTokenCreateExcel = "ReconciliationReport";

    useEffect(() => {
        if (!clients) {
            dispatch(getClientsAction(1, 100));
        }
    }, [clients]);

    useEffect(() => {
        dispatch(getDynamicQueryReport(startDate, endDate, fundingType, debitFeeMethod, state, departmentIds, actionToken));
    }, []);

    const resetSearch = () => {
        setStartDate(defaultStartDate);
        setEndDate(defaultEndDate);
        setFundingType(undefined);
        setDebitFeeMethod(undefined);
        setState("");
        setDepartmentIds([]);
        dispatch(getDynamicQueryReport(defaultStartDate, defaultEndDate, undefined, undefined, "", [], actionToken, []));
    };

    const handleSearch = (event: any) => {
        event.preventDefault();
        setModal(false);

        dispatch(getDynamicQueryReport(startDate, endDate, fundingType, debitFeeMethod, state, departmentIds, actionToken));
    };

    useEffect(() => {
        if (items) {
            const deptToItems =  items.reduce((acc, curr) => {
                if (
                    curr.transactionType == TransactionTypeEnum.Sale && curr.paymentType == PaymentTypeEnum.CreditCardManual ||
                    curr.transactionType == TransactionTypeEnum.AuthorizationCommit && curr.paymentType == PaymentTypeEnum.CreditCardManual ||
                    curr.transactionType == TransactionTypeEnum.Sale && curr.paymentType == PaymentTypeEnum.ECheck ||
                    curr.transactionType == TransactionTypeEnum.Chargeback ||
                    curr.transactionType == TransactionTypeEnum.ChargebackReversal ||
                    curr.transactionType == TransactionTypeEnum.Refund ||
                    curr.transactionType == TransactionTypeEnum.Return
                ) {
                    acc.set(curr.departmentId, (acc.get(curr.departmentId) || []).concat(curr));
                }
                return acc;
            }, new Map<string, Array<MsbReportItem>>());
    
            setDeptToItems(deptToItems);
        }
    }, [items]);


    useEffect(() => {        
        if (documentActionResult && documentActionResult.result) {
            if (documentActionResult.type === CREATE_DOCUMENT_REQUEST && documentActionResult.token === actionTokenCreateExcel) {
                if (documentActionResult.result === CREATE_DOCUMENT_SUCCESS && !!blobObject) {
                    let fileName = "ReconciliationReport_" + `${startDate}-${endDate}`;
                    //downloadFile(blobObject, fileName, "xlsx");
                } else if (documentActionResult.result === CREATE_DOCUMENT_FAILURE) {
                    dispatch(sendErrorToastAction("Excel creation failed"));
                }
                setWaitingForDownload(false);
            }
        }

        dispatch({ type: CLEAR_DOCUMENT });
    }, [documentActionResult]);

    useEffect(() => {
        if (documentContentUrl && searchFields) {
            let url = new URL(documentContentUrl, "http://temp.com");
 
             Object.entries(searchFields).forEach(([key, value]) => {
                 if (value) {
                     if (key === "createdAt") {
                         url.searchParams.append(`${key}:from`, value);
                     } else {
                         url.searchParams.append(key, value);
                     }
                 }
             });
 
             setDocumentContentUrlWithSearchParams(url.toString().substring(16));
        }
     }, [documentContentUrl, searchFields]);

    const doToolbar = () => {
        if (!isFetching) {
            return (
                <div className="flexContainer">
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle className="approvalAction" variant="outline-secondary" disabled={waitingForDownload}>
                                { waitingForDownload?
                                    <Spinner animation="border" />
                                    :
                                    <FontAwesomeIcon icon={faArrowToBottom} size="sm" />
                                } Download Summary
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {
                                    <>
                                    <Dropdown.Item onClick={() => {dispatch(createExcel(startDate, endDate, actionTokenCreateExcel, ReportDownloadType.WithChannelLevelDetail));setWaitingForDownload(true);}}> With Channel Level </Dropdown.Item>
                                    <Dropdown.Item onClick={() => {dispatch(createExcel(startDate, endDate, actionTokenCreateExcel, ReportDownloadType.WithoutChannelLevelDetail));setWaitingForDownload(true);}}> Without Channel Level </Dropdown.Item>
                                    </>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            )
        } else {
            return (<></>);
        }
    }

    const viewDetailsFormatter = (cell: any, row: any) => {
        const detailsData = {
            clientName: row.clientName,
            departmentName: row.departmentName,
            deptMsbReports: cell,
        };

        return(
            <span>
                <a 
                    onClick={() => {
                        dispatch({ type: SET_RECON_DETAILS_DATA, payload: detailsData });
                        setRedirect(Routes.ReconciliationReportDetails.path);
                    }}
                >
                    View Details
                </a>
            </span>
        );
    }

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Reconciliations Report", Routes.ReconciliationReport.path));

    const allReportItems = Array.from(deptToItems?.values() || []).reduce((acc, deptToItem) => acc.concat(deptToItem), Array<MsbReportItem>());
    
    const eCheckWebPayments = (allReportItems || []).filter((item) => item.transactionType === TransactionTypeEnum.Sale && item.paymentType === PaymentTypeEnum.ECheck && item.paymentChannelTypeEnum !== PaymentChannelTypeEnum.IVR);
    const eCheckIVRPayments = (allReportItems || []).filter((item) => item.transactionType === TransactionTypeEnum.Sale && item.paymentType === PaymentTypeEnum.ECheck && item.paymentChannelTypeEnum === PaymentChannelTypeEnum.IVR);
    const eCheckRefundPayments = (allReportItems || []).filter((item) => (item.transactionType === TransactionTypeEnum.Refund || item.transactionType === TransactionTypeEnum.Return) && item.paymentType === PaymentTypeEnum.ECheck);

    const columns = [
        {
            dataField: 'msbId',
            text: 'ID',
            editable: false,
            sort: false,
            hidden: true,
            configurable: false
        },
        {
            dataField: 'clientName',
            text: 'Client',
            editable: false,
            sort: true,
            configurable: true
        },
        {
            dataField: 'departmentName',
            text: 'Department',
            editable: false,
            configurable: true
        }, 
        {
            dataField: 'totalCount',
            text: 'Transactions',
            editable: false,
            configurable: true
        }, 
        {
            dataField: 'amount',
            text: 'Invoice total',
            editable: false,
            formatter: CurrencyFormatter,
            configurable: true
        }, 
        {
            dataField: 'convenienceFee',
            text: 'Convenience fee total',
            editable: false,
            formatter: CurrencyFormatter,
            configurable: true
        }, 
        {
            dataField: 'totalAmount',
            text: 'Total amount',
            editable: false,
            formatter: CurrencyFormatter,
            configurable: true
        }, 
        {
            dataField: 'state',
            text: 'State',
            editable: false,
            configurable: true
        }, 
        {
            dataField: 'fundingMethodEnum',
            text: 'Funding type',
            editable: false,
            formatter: (cell: any) => FundingMethodEnum[cell],
            configurable: true
        }, 
        {
            dataField: 'debitFeeMethodEnum',
            text: 'Debit fee method',
            editable: false,
            formatter: (cell: any) => DebitFeeMethodEnum[cell],
            configurable: true
        }, 
        {
            dataField: 'groupedItemsByDept',
            text: 'Actions',
            editable: false,
            formatter: viewDetailsFormatter,
            configurable: false
        }
    ];
    
    if (redirect != "") return <Redirect push to={redirect} />;
    
    return (
        <>
            <PageHeader title={`Reconciliations Report`} crumbs={crumbs} />

            <Container fluid className="container-table-search">
              <PageSectionContainer>
                  <Form className="table-search advanced-search" onSubmit={handleSearch}>
                      <Row>
                          <Col xl={4} lg={6} md={6} sm={12}>
                              <Form.Group controlId="startDate">
                                  <Form.Label>Start date</Form.Label>
                                  <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                              </Form.Group>
                          </Col>
                          <Col xl={4} lg={6} md={6} sm={12}>
                              <Form.Group controlId="endDate">
                                  <Form.Label>End date</Form.Label>
                                  <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                              </Form.Group>
                          </Col>
                          <Col xl={4} lg={12} md={12} sm={12}>
                              <ButtonToolbar>
                                  <Button variant="link" onClick={() => setModal(true)}>Advanced Search</Button>
                                  <Button variant="outline-secondary" onClick={resetSearch}>Reset</Button>
                                  <Button type="submit">Search</Button>
                              </ButtonToolbar>
                          </Col>
                      </Row>
                  </Form>
              </PageSectionContainer>
            </Container>

            <Container fluid>
                <PageSectionContainer>
                    <Row>
                        <Col>
                          <div className="report-search-summary">
                            <h2 className="mb-0">Reconciliations Search ACH Summary</h2>
                            <span><strong>ACH Web total: </strong>{CurrencyFormatter(eCheckWebPayments.reduce((acc, curr) => acc + curr.totalAmount, 0))}</span>
                            <span><strong>ACH IVR total: </strong>{CurrencyFormatter(eCheckIVRPayments.reduce((acc, curr) => acc + curr.totalAmount, 0))}</span>
                            <span><strong>ACH Refund total: </strong>{CurrencyFormatter(eCheckRefundPayments.reduce((acc, curr) => acc + curr.totalAmount, 0))}</span>
                          </div>
                        </Col>
                    </Row>
                </PageSectionContainer> 
            </Container>
            
            <Container fluid>
                <PageSectionContainer title="Manage Reconciliations Report" toolbar={doToolbar()}>
                    {
                        isFetching ? (
                            <Spinner animation="border" />
                        ) : (
                                Array.from(deptToItems || new Map<string, Array<MsbReportItem>>()).length < 1 ?
                                <>
                                    <h2 className="fw-bold">No records were found</h2>
                                    <span>Please do another search to find the record you are looking for.</span>
                                </> :
                                <Table
                                    keyField="ReconciliationTransactions"
                                    columns={columns}
                                    data={
                                        Array.from(deptToItems || new Map<string, Array<MsbReportItem>>()).map(([_deptId, items]) => ({
                                            clientName: items[0].clientName,
                                            departmentName: items[0].departmentName,
                                            totalCount: items.reduce((acc, curr) => acc + curr.totalCount, 0),
                                            amount: items.reduce((acc, curr) => acc + curr.amount, 0),
                                            convenienceFee: items.reduce((acc, curr) => acc + curr.convenienceFee, 0),
                                            totalAmount: items.reduce((acc, curr) => acc + curr.totalAmount, 0),
                                            state: items[0].state,
                                            fundingMethodEnum: items[0].fundingMethodEnum,
                                            debitFeeMethodEnum: items[0].debitFeeMethodEnum,
                                            groupedItemsByDept: items
                                        }))
                                    }
                                />
                        )
                    }
                </PageSectionContainer>
            </Container>
            
            <Modal show={showModal} onHide={setModal} size="lg" className="advanced-search-modal">
                <Modal.Header closeButton />
                <Modal.Body>
                    <FormHeader title="Advanced Search" description="Please enter the details below to narrow your search results" />
                    <Form className="advanced-search-form" onSubmit={handleSearch}>
                        <Row className="advanced-search-form-columns">
                            <Form.Group controlId="startDate">
                                <Form.Label>Start date</Form.Label>
                                <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="endDate">
                                <Form.Label>End date</Form.Label>
                                <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                            </Form.Group>
                            <Form.Group controlId="fundingType">
                                <Form.Label>Funding Type</Form.Label>
                                <Form.Control as="select" value={fundingType} onChange={(e) => setFundingType(Number(e.target.value))}>
                                    <option key={0} value="">All Funding</option>
                                    <option key={1} value={FundingMethodEnum.SameDay}>Same Day</option>
                                    <option key={2} value={FundingMethodEnum.NextDay}>Next Day</option>
                                    <option key={3} value={FundingMethodEnum.TwoDay}>Two Day</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="debitFeeMethod">
                                <Form.Label>Debit fee method</Form.Label>
                                <Form.Control as="select" value={debitFeeMethod} onChange={(e) => setDebitFeeMethod(Number(e.target.value))}>
                                    <option key={0} value="">All Methods</option>
                                    <option key={1} value={DebitFeeMethodEnum.DirectDebit}>Direct Debit</option>
                                    <option key={2} value={DebitFeeMethodEnum.BillOut}>Bill Out</option>
                                    <option key={3} value={DebitFeeMethodEnum.Net}>Net</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="state">
                                <Form.Label>State</Form.Label>
                                <StateDropdown value={state} onChange={(e: any) => setState(e.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row>
                            { 
                                clients &&
                                <DualListBox
                                    selected={departmentIds}
                                    options={
                                        clients.map(client => ({
                                            label: client.businessName,
                                            options: (client.departments || []).map(department => ({
                                                label: department.name,
                                                value: department.msbId
                                            }))
                                        }))
                                    }
                                    onChange={(ids: any) => setDepartmentIds(ids)}
                                    className="transfer"
                                    showHeaderLabels={true}
                                    lang={{
                                        availableHeader: 'All departments',
                                        selectedHeader: 'Selected departments'
                                    }}
                                    icons={{
                                        moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
                                        moveAllLeft: [
                                            <FontAwesomeIcon icon={faChevronLeft} key={0} />,
                                            <FontAwesomeIcon icon={faChevronLeft} key={1} />,
                                        ],
                                        moveRight: <FontAwesomeIcon icon={faChevronRight} />,
                                        moveAllRight: [
                                            <FontAwesomeIcon icon={faChevronRight} key={0} />,
                                            <FontAwesomeIcon icon={faChevronRight} key={1} />,
                                        ],
                                    }}
                                />
                            }
                        </Row>
                        <Row className="advanced-search-footer">
                            <Col>
                                <Button type="submit">Search</Button>
                            </Col>
                            <Col>
                                <Button form='navientUserSubmit' className="navientUserFooterCancel" variant="link" onClick={() => setModal(false)}>
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        clients: state.clients.currentPage?.data,
        isFetching: state.reports.isFetching,
        items: state.reports.summaryAllTransactions,
        searchFields: state.paymentTransactions.searchFields,
        blobObject: state.document.blobObject,
        actionResult: state.paymentTransactions.actionResult,
        documentActionResult: state.document.actionResult,
    };
};

export default connect(mapStateToProps)(ReconciliationReport);