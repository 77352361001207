import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import RouteWithSidebar from "../components/layout/RouteWithSidebar";
import RouteWithFullView from "../components/layout/RouteWithFullView";
import GetUserClient from "./GetUserClient";
import SignIn from "./SignIn";
import Clients from "./clients/Clients";
import ClientAdd from "./clients/ClientAdd";
import DepartmentAdd from './clients/DepartmentAdd';
import DepartmentManage from './clients/Department';
import MerchantAdd from './clients/MerchantAdd';
import MerchantManage from './clients/Merchant';
import Client from "./clients/Client";
import AddNavientUsers from "./access/navient/components/AddUser";
import EditNavientUsers from './access/navient/components/EditUser';
import AddNavientRoles from './access/navient/components/AddRole';
import EditNavientRoles from './access/navient/components/EditRole';
import AddClientRoles from './access/client/components/AddRole';
import EditClientRoles from './access/client/components/EditRole';
import AddClientUser from "./access/client/components/AddUser";
import EditClientUser from './access/client/components/EditUser';
import PaymentMethodOnFile from "./payments/PaymentMethodOnFile";
import RefundPayment from "./payments/RefundPayment";
import RefundPaymentDetails from "./payments/paymentDetails/RefundPaymentDetails";
import CreateChargebackDetails from "./payments/paymentDetails/CreateChargebackDetails";
import CreateChargeback from "./payments/CreateChargeback";
import ChargebackReversals from './payments/ChargebackReversals';
import ChargebackReversalDetails from './payments/paymentDetails/ChargebackReversalDetails';
import VoidPayment from "./payments/VoidPayment";
import VoidPaymentDetails from "./payments/paymentDetails/VoidPaymentDetails";
import PaymentReceipt from "./payments/components/PaymentReceipt";
import GilaSettings from "./gilasettings/GilaSettings";
import AccountSettings from "./account/Settings";
import WebPaymentChannel from "./paymentChannels/web/WebPaymentChannel";
import WebPaymentChannelPageEditor from "./paymentChannels/web/WebPaymentChannelPageEditor";
import ReceiptEditor from "./receipts/ReceiptEditor";
import EMafReport from "./reports/eMaf";
import FundingReport from "./reports/funding/Funding";
import ManagePMoF from "./customer/PMoF";
import ReconciliationReport from "./reports/reconciliations/Reconciliation";
import ReconciliationDetails from "./reports/reconciliations/ReconciliationDetails";
import ReconciliationTransactions from "./reports/reconciliations/ReconciliationTransactions";
import ACHTimezoneReport from "./reports/financeACH/fundByTimeZoneACH";
import WorldpayTransactionsReport from "./reports/worldpay/WorldpayTransactions";
import WorldpayTransactionsReportDetails from "./reports/worldpay/WorldpayTransactionsDetails";
import ClientBanking from './reports/clientBanking/ClientBanking';
import InvoiceReport from "./reports/InvoiceReport";
import ViewDetails from "./reports/components/ViewDetails";
import DashboardOverview from "./dashboard/Dashboard";
import NotFoundPage from "./examples/NotFound";
import Approvals from "./approvals/Approvals"
import NavientUsers from "./access/navient/Users";
import NavientRoles from "./access/navient/Roles";
import ClientUsers from "./access/client/Users";
import ClientRoles from "./access/client/Roles";
import Preloader from "../components/Preloader";
import Accordion from "./components/Accordion";
import Alerts from "./components/Alerts";
import Badges from "./components/Badges";
import Breadcrumbs from "./components/Breadcrumbs";
import Buttons from "./components/Buttons";
import Forms from "./components/Forms";
import Modals from "./components/Modals";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import Popovers from "./components/Popovers";
import Progress from "./components/Progress";
import Tables from "./components/Tables";
import Tabs from "./components/Tabs";
import Tooltips from "./components/Tooltips";
import Toasts from "./components/Toasts";
import QuickPay from './payments/QuickPay';
import QuickPayReceipt from './payments/QuickPayReceipt';
import TerminalPay from './payments/TerminalPay';
import TerminalPayReceipt from './payments/TerminalPayReceipt';
import PointOfSale from './payments/PointOfSale';
import PointOfSaleReceipt from './payments/PointOfSaleReceipt';
import PrintReceipt from './payments/components/PrintReceipt';
import ReportRouter from './reports/ReportRouter'

import { getWebAppSettingsAction, getWsmTokenAction } from '../redux/actions/config';
import { signInAction } from '../redux/actions/auth';
import store from "../redux/store";
import { GET_WSM_TOKEN_SUCCESS, GET_WSM_TOKEN_FAILURE } from '../redux/actions/config';

const RouteWithLoader = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
    );
};

const HomePage = (props) => {
    const dispatch = useDispatch();
    const [wsmTokenSuccess, setWsmTokenSuccess] = useState(false);
    const [initialBearerToken, setInitialBearerToken] = useState(false)

    useEffect(() => {
        dispatch(getWebAppSettingsAction("HomePage"));
    }, []);

    useEffect(() => {
        if (props.baseApiUrl) {
            if (props.environment !== "DEV") {
                let accountInfo = props.msalInstance.getActiveAccount();
                if (accountInfo) {
                    dispatch(getWsmTokenAction("Homepage"))?.then(() => {
                        setInitialBearerToken(true);
                    })
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.baseApiUrl]);

    useEffect(() => {
        if (store.getState().webAppSettings.actionResult.result === GET_WSM_TOKEN_SUCCESS) { setWsmTokenSuccess(true) }
        if (store.getState().webAppSettings.actionResult.result === GET_WSM_TOKEN_FAILURE) { setWsmTokenSuccess(false) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.getState().webAppSettings.actionResult]);

    useEffect(() => {
        let interval;
        if (wsmTokenSuccess) {
            interval = setInterval(() => {
                dispatch(getWsmTokenAction("Refreshed Token"));
            }, (store?.getState()?.webAppSettings?.wsmRefreshMinutes || 10) * 60 * 1000);
        }
        return () => {
            clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store?.getState()?.webAppSettings?.wsmRefreshMinutes, wsmTokenSuccess]);

    useEffect(() => {
        if (props.baseApiUrl) {
            if (props.environment !== "DEV") {
                let accountInfo = props.msalInstance.getActiveAccount();
                if (accountInfo && initialBearerToken && store.getState().webAppSettings.bearerToken != '') {
                    let idTokenClaims = accountInfo?.idTokenClaims;
                    let email = idTokenClaims.sub;
                    let oid = idTokenClaims.oid;
                    dispatch(signInAction(email, oid, props.msalInstance, "HomePage"));
                }
            } else {
                //dispatch(signInAction("pokemon.dev@automation.com", "25f05b75-eb6c-4f64-88e2-54fd5e96db8f", props.msalInstance, "HomePage"));
                //dispatch(signInAction("user1@test.com", "e2dc57ca-a7fa-450d-b70b-1b42347ee0a7", props.msalInstance, "HomePage"));
                let email = prompt("Enter User Email to access Client account. Cancel to access Navient account");
                if (!email) {
                    dispatch(signInAction("test@navient.com", "7b81f83a-cdbc-4d85-87c2-76fba9a3e94e", props.msalInstance, "HomePage"));
                } else {
                    let oid = prompt("Enter User Object ID");
                    dispatch(signInAction(email, oid, props.msalInstance, "HomePage"));
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.baseApiUrl, initialBearerToken]);


    if (props.baseApiUrl === "") {
        return (<></>)
    } else {
        return (
            <Switch>
                <RouteWithLoader exact path={Routes.SignIn.path} component={SignIn} />
                <RouteWithLoader exact path={Routes.SignInNavient.path} component={SignIn} />

                <RouteWithSidebar exact path={Routes.PointOfSale.path} component={PointOfSale} />
                <RouteWithSidebar exact path={Routes.PointOfSaleReceipt.path} component={PointOfSaleReceipt} />
                <RouteWithSidebar exact path={Routes.PrintReceipt.path} component={PrintReceipt} />

                <RouteWithSidebar exact path={Routes.QuickPay.path} component={QuickPay} />
                <RouteWithSidebar exact path={Routes.QuickPayReceipt.path} component={QuickPayReceipt} />
                <RouteWithSidebar exact path={Routes.TerminalPay.path} component={TerminalPay} />
                <RouteWithSidebar exact path={Routes.TerminalPayReceipt.path} component={TerminalPayReceipt} />
                <RouteWithSidebar exact path={Routes.Approvals.path} component={Approvals} />

                <RouteWithSidebar exact path={Routes.GilaSettings.path} component={GilaSettings} />
                <RouteWithSidebar exact path={Routes.Clients.path} component={Clients} />
                <RouteWithSidebar exact path={Routes.ClientAdd.path} component={ClientAdd} />

                <RouteWithSidebar exact path={Routes.DepartmentAdd.path} component={DepartmentAdd} />
                <RouteWithSidebar exact path={Routes.MerchantAdd.path} component={MerchantAdd} />

                <RouteWithSidebar exact path={Routes.NavientUsers.path} component={NavientUsers} />
                <RouteWithSidebar exact path={Routes.AddNavientUsers.path} component={AddNavientUsers} />
                <RouteWithSidebar exact path={Routes.EditNavientUsers.path} component={EditNavientUsers} />

                <RouteWithSidebar exact path={Routes.NavientRoles.path} component={NavientRoles} />
                <RouteWithSidebar exact path={Routes.AddNavientRoles.path} component={AddNavientRoles} />
                <RouteWithSidebar exact path={Routes.EditNavientRoles.path} component={EditNavientRoles} />

                <RouteWithSidebar exact path={Routes.AddClientRoles.path} component={AddClientRoles} />
                <RouteWithSidebar exact path={Routes.EditClientRoles.path} component={EditClientRoles} />

                <RouteWithSidebar exact path={Routes.DepartmentEdit.path} component={DepartmentManage} />
                <RouteWithSidebar exact path={Routes.MerchantEdit.path} component={MerchantManage} />

                <RouteWithSidebar exact path={Routes.WebPaymentChannel.path} component={WebPaymentChannel} />
                <RouteWithFullView exact path={Routes.WebPaymentChannelPageEditor.path} component={WebPaymentChannelPageEditor} />
                <RouteWithFullView exact path={Routes.ReceiptEditor.path} component={ReceiptEditor} />

                <RouteWithSidebar exact path={Routes.AddClientUser.path} component={AddClientUser} />
                <RouteWithSidebar exact path={Routes.EditClientUser.path} component={EditClientUser} />

                <RouteWithSidebar exact path={Routes.Client.path} component={Client} />
                <RouteWithSidebar exact path={Routes.AddPMoF.path} component={PaymentMethodOnFile} />
                <RouteWithSidebar exact path={Routes.RefundPayment.path} component={RefundPayment} />
                <RouteWithSidebar exact path={Routes.RefundPaymentDetails.path} component={RefundPaymentDetails} />
                <RouteWithSidebar exact path={Routes.CreateChargeback.path} component={CreateChargeback} />
                <RouteWithSidebar exact path={Routes.CreateChargebackDetails.path} component={CreateChargebackDetails} />
                <RouteWithSidebar exact path={Routes.ChargebackReversals.path} component={ChargebackReversals} />
                <RouteWithSidebar exact path={Routes.ChargebackReversalDetails.path} component={ChargebackReversalDetails} />
                <RouteWithSidebar exact path={Routes.VoidPayment.path} component={VoidPayment} />
                <RouteWithSidebar exact path={Routes.VoidPaymentDetails.path} component={VoidPaymentDetails} />
                <RouteWithSidebar exact path={Routes.PaymentReceipt.path} component={PaymentReceipt} />

                <RouteWithSidebar exact path={Routes.ChargebacksReport.path} component={ReportRouter} />
                <RouteWithSidebar exact path={Routes.DeclinedReport.path} component={ReportRouter} />
                <RouteWithSidebar exact path={Routes.EMafReport.path} component={EMafReport} />
                <RouteWithSidebar exact path={Routes.FundingReport.path} component={FundingReport} />
                <RouteWithSidebar exact path={Routes.PaymentsReport.path} component={ReportRouter} />
                <RouteWithSidebar exact path={Routes.PendingPaymentsReport.path} component={ReportRouter} />
                <RouteWithSidebar exact path={Routes.ManagePMoF.path} component={ManagePMoF} />
                <RouteWithSidebar exact path={Routes.ReconciliationReport.path} component={ReconciliationReport} />
                <RouteWithSidebar exact path={Routes.ReconciliationReportDetails.path} component={ReconciliationDetails} />
                <RouteWithSidebar exact path={Routes.ReconciliationReportTransactions.path} component={ReconciliationTransactions} />
                <RouteWithSidebar exact path={Routes.ACHTimezoneReport.path} component={ACHTimezoneReport} />
                <RouteWithSidebar exact path={Routes.WorldpayTransactionsReport.path} component={WorldpayTransactionsReport} />
                <RouteWithSidebar exact path={Routes.WorldpayTransactionsReportDetails.path} component={WorldpayTransactionsReportDetails} />
                <RouteWithSidebar exact path={Routes.ClientBanking.path} component={ClientBanking} />
                <RouteWithSidebar exact path={Routes.InvoiceReport.path} component={InvoiceReport} />
                <RouteWithSidebar exact path={Routes.RefundsReport.path} component={ReportRouter} />
                <RouteWithSidebar exact path={Routes.TransactionsReport.path} component={ReportRouter} />
                <RouteWithSidebar exact path={Routes.VoidsReport.path} component={ReportRouter} />
                <RouteWithSidebar exact path={Routes.AuthorizationsReport.path} component={ReportRouter} />
                <RouteWithSidebar exact path={Routes.ViewDetails.path} component={ViewDetails} />

                <RouteWithSidebar exact path={Routes.ConvenienceFee.path} component={ReportRouter} />
                <RouteWithSidebar exact path={Routes.ComplianceExceptions.path} component={ReportRouter} />
                <RouteWithSidebar exact path={Routes.FeeAudit.path} component={ReportRouter} />
                <RouteWithSidebar exact path={Routes.NoConvenienceFee.path} component={ReportRouter} />

                <RouteWithSidebar exact path={Routes.ClientUsers.path} component={ClientUsers} />
                <RouteWithSidebar exact path={Routes.ClientRoles.path} component={ClientRoles} />

                <RouteWithSidebar exact path={Routes.AccountSettings.path} component={AccountSettings} />

                <RouteWithLoader exact path={Routes.GetUserClient.path} component={GetUserClient} />

                <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
                {/*
                  <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
                  */}

                {/* pages */}
                <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} isAuthenticated={props.isAuthenticated} />

                {/* components */}
                <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
                <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
                <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
                <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
                <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
                <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
                <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
                <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
                <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
                <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
                <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
                <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
                <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
                <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
                <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
                <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

                <Redirect to={Routes.NotFound.path} />
            </Switch>
        )
    }
};


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        userPermissions: state.auth.userPermissions,
        baseApiUrl: state.webAppSettings.baseApiUrl,
        environment: state.webAppSettings.environment,
        bearerToken: state.webAppSettings.bearerToken,
        actionResult: state.webAppSettings.actionResult
    };
};

export default connect(mapStateToProps)(HomePage);