import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Modal } from 'react-bootstrap'
import { useIdleTimer } from 'react-idle-timer'
import { doLogOut } from '../redux/actions/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const IdleTimer = () => {
    const dispatch = useDispatch();
    const gracePeriod = 300; // 5 minutes
    const [open, setOpen] = useState(false);
    const [countdown, setCountdown] = useState(gracePeriod);

    const onIdle = () => {
        console.log("IDLE...");
        setOpen(true);
    }

    const onActive = () => {
        console.log("ACTIVE...");
        setOpen(false);
        setCountdown(gracePeriod);
    }

    useIdleTimer({
        onIdle,
        onActive,
        timeout: 1800_000, // 30 minutes
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            if (countdown > 0) {
                if (open) setCountdown(countdown - 1);
            } else {
                console.log("LOGGING OUT...");
                try { dispatch(doLogOut()) } catch { };
                setOpen(false);
                setCountdown(gracePeriod);
            }
        }, 1_000)

        return () => {
            clearInterval(interval)
        }
    })

    return (
        <Modal size="sm" show={open} onHide={() => { }}>
            <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <FontAwesomeIcon icon={faExclamationCircle} color="red" size="3x" />
                <Modal.Title>&nbsp;&nbsp;&nbsp;Are you still there?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h1>Logging out in {Math.ceil(countdown/60)} minutes . . .</h1>
            </Modal.Body>
        </Modal>
    );
}

export default IdleTimer;
