import _ from "lodash";
import { TransactionTypeEnum } from "../../../models/Payment";
import { ReportType } from "../../../models/Reports";
import { dynamicQueryUrls } from "../../../utils/dynamicQueryReportUrls";
import { formatDynamicSearchParameters } from "../../../utils/advanceSearchParameters";

export const buildUrl = (baseApiUrl: string, controllerName: string, searchFields: any, reportType?: ReportType,
    transactionType?: TransactionTypeEnum, extraTransactionType?: TransactionTypeEnum, currentUser?: any, paymentChannelsByUser?: any): URL => {

    const urlSchema = `/MSB_Order/api/v1/${controllerName}/dynamicQuery?`;

    const departments = extractPaymentChannelsAndDepartmentIds(paymentChannelsByUser, searchFields.paymentChannelId, true)
    const departmentMsbIds = departments?.map((department: any) => department.departmentMsbId) || [];

    if (departmentMsbIds.length > 0) {
        const currentDepartmentIds = searchFields.departmentId ? searchFields.departmentId.split('|') : [];
        const updatedDepartmentIds = _.uniq([...currentDepartmentIds, ...departmentMsbIds]);

        searchFields.departmentId = updatedDepartmentIds.join('|');
    }

    const searchValues = _.omit(_.pickBy(searchFields, Boolean), 'paymentChannelId');

    const url = dynamicQueryUrls(baseApiUrl, urlSchema, reportType, transactionType, extraTransactionType, searchValues);
    const searchParams = url.searchParams;

    const paymentType = searchValues.paymentType || 'CreditCard|eCheck|PayPal|WireTransfer|AuthorizeDotNet';
    searchParams.set(paymentType.includes('|') ? 'paymentType:or' : 'paymentType', paymentType);

    Object.entries(searchValues).forEach(([key, value]) => {
        if (key !== 'paymentType') {
            searchParams.set(formatDynamicSearchParameters(key, value, currentUser, searchValues), value);
            searchParams.delete('itemReferenceToggle')
        }
    });

    return new URL(decodeURIComponent(url.toString().replace(/\+/g, ' ')));
};

export const extractPaymentChannelsAndDepartmentIds = (data: any, paymentChannelMsbIds: string, byDepartment: boolean) => {
    if (paymentChannelMsbIds) {
        const msbIdsArray = paymentChannelMsbIds.split('|');
        return _.uniqBy(
            _.flatMap(data, item =>
                _.flatMap(item.departments || [item], department =>
                    _.map(department.paymentChannels, (channelName, channelId) => {
                        if (msbIdsArray.includes(channelId)) {
                            return {
                                departmentName: department.name,
                                departmentMsbId: department.msbId,
                                channelId,
                                channelName
                            };
                        }
                    }).filter(Boolean)
                )
            ),
            byDepartment ? 'departmentMsbId' : 'channelId'
        );
    }
};

export const matchesQuery = (item: any, params: URLSearchParams, controllerName: string, enums: any, searchFields: any,
    paymentChannelsByUser: any
): { matches: boolean; reason?: string } => {


    if (searchFields.paymentChannelId || searchFields.departmentId) {

        const paymentChannels = extractPaymentChannelsAndDepartmentIds(paymentChannelsByUser, searchFields.paymentChannelId, false) || [];
        const paymentChannelDepartments = paymentChannels?.map((channel: any) => channel.departmentMsbId) as any;

        const departments = searchFields.departmentId.split("|").filter(Boolean);
        const departmentsWithoutPaymentChannel = departments.filter((department: any) => !paymentChannelDepartments.includes(department));

        const matchingChannel = paymentChannels?.find((channel: any) =>
            item.departmentId === channel.departmentMsbId && item.paymentChannelId === channel.channelId
        );
        
        if (!matchingChannel && !departmentsWithoutPaymentChannel.includes(item.departmentId)) {
            return { matches: false, reason: `Payment Channel does not match department for departmentId: ${item.departmentId}` };
        }
    }

    for (const [key, val] of params.entries()) {
        const actualKey = key.replace(/:\w{2,}/g, "");

        if (
            (controllerName === 'OrderLines' && ['itemReferenceNumber', 'createdAt', 'paymentChannelId', 'departmentId'].includes(actualKey)) ||
            (controllerName === 'OrderPayments' && ['nameOnCard', 'lastFourOnCard', 'createdAt', 'paymentChannelId', 'departmentId'].includes(actualKey)) ||
            (controllerName === 'OrderTransactions' && ['createdAt', 'paymentChannelId', 'departmentId'].includes(actualKey))
        ) {
            continue;
        }

        const values = val.split("|");
        const enumValues = enums[actualKey];

        if (item[actualKey] === undefined) {
            return { matches: false, reason: `Key ${actualKey} is missing in the item` };
        }

        if (actualKey === "totalAmount" || actualKey === "amount") {
            let minTotalAmount, maxTotalAmount;

            if (key.includes(":from")) {
                const range = val.includes("<->") ? val.split("<->") : [val];
                minTotalAmount = parseFloat(range[0]);
                if (range[1]) maxTotalAmount = parseFloat(range[1]);
            }

            const itemTotalAmount = parseFloat(item[actualKey]);
            if (isNaN(itemTotalAmount)) {
                return { matches: false, reason: `${actualKey} ${item[actualKey]} is not a valid number` };
            }

            if (
                (minTotalAmount !== undefined && itemTotalAmount < minTotalAmount) ||
                (maxTotalAmount !== undefined && itemTotalAmount > maxTotalAmount)
            ) {
                return {
                    matches: false,
                    reason: `${actualKey} ${itemTotalAmount} is out of range (${minTotalAmount || "-∞"} - ${maxTotalAmount || "∞"})`
                };
            }
            continue;
        }

        const matched = values.some((queryValue: string) => {
            const itemValue = String(item[actualKey]).toLowerCase();
            const enumName = enumValues ? enumValues[Number(queryValue)] : undefined;

            return (
                itemValue.includes(queryValue.toLowerCase()) ||
                (enumName && itemValue === enumName.toLowerCase())
            );
        });

        if (!matched) {
            return {
                matches: false,
                reason: `Value(s) [${values.join(", ")}] do not match item value for ${actualKey}: ${item[actualKey]}`
            };
        }
    }

    return { matches: true };
};


export const processResponseData = (controllerName: string, data: any[], searchFields: any, params: URLSearchParams, enums: any, paymentChannelsByUser?: any): any[] => {

    const excludedItems: { orderIdentifier: any; reason: string }[] = [];
    const processFilter = (items: any[]): any[] => {
        return items.filter((item: any) => {
            const result = matchesQuery(item, params, controllerName, enums, searchFields, paymentChannelsByUser);
            if (!result.matches) {
                excludedItems.push({
                    orderIdentifier: item.orderIdentifier || item.id || "Unknown",
                    reason: result.reason || "No matching reason provided"
                });
                return false;
            }
            return true;
        });
    };

    const filteredData = data.flatMap((item: any) => {
        const processedItem = {
            ...item,
            orderLines: item.orderLines || [],
            orderSummary: item.orderSummary || {},
            orderPayment: item.orderPayment || {}
        };

        if (controllerName === 'OrderLines') {
            const orderTransaction = item?.orderTransaction || {};
            if (!orderTransaction.orderLines) orderTransaction.orderLines = [];
            orderTransaction.orderLines.unshift({ ...item });

            return processFilter([orderTransaction]);
        }

        if (controllerName === 'OrderPayments') {
            const transactions = Array.isArray(processedItem.orderTransaction)
                ? processedItem.orderTransaction
                : [processedItem.orderTransaction];
            transactions.forEach((transaction: any) => {
                if (!transaction.orderPayment) {
                    transaction.orderPayment = { ...processedItem };
                }
            });
            return processFilter(transactions);
        }
        return processFilter([processedItem]);
    }).map((item: any) => {
        if (Array.isArray(item.orderLines) && item.orderLines.length > 0) {
            const matchedLine = item.orderLines.find(
                (lineItem: any) => lineItem.itemReferenceNumber === searchFields.itemReferenceNumber
            );
            const defaultLine = item.orderLines[0];

            item.itemReferenceNumber = matchedLine?.itemReferenceNumber || defaultLine?.itemReferenceNumber || '';
            item.itemName = matchedLine?.itemName || defaultLine?.itemName || '';
        }

        if (item.paymentType === 'ECheck') {
            item.lastFourOnCard = item.orderPayment?.accountNumberLastFour;
        }

        if (item.paymentType === 'CreditCard') {
            const isDebit = item.orderSummary?.isDebitCardTransaction ? ' - Debit' : ' - Credit';
            item.orderPayment.cardLogo = `${item.orderPayment.cardLogo}${isDebit}`;
        }

        return item;
    });

    const orderLineFilter = Object.values(filteredData.reduce((acc, item) => {
        const { orderIdentifier, transactionType, orderLines, id } = item;
        const key = `${orderIdentifier}-${transactionType}-${id}`;
        if (!acc[key]) {
            acc[key] = { ...item, orderLines: [] };
        }
        orderLines.forEach((lineItem: any) => {
            const exists = acc[key].orderLines.some((existingLine: any) => existingLine.itemReferenceNumber === lineItem.itemReferenceNumber);

            if (!exists) {
                acc[key].orderLines.push(lineItem);
            }
        });

        return acc;
    }, {}));

    console.log("Excluded Items:", excludedItems);

    return controllerName === 'OrderLines' ? orderLineFilter : filteredData;
};

