import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Col, Row, Dropdown, Modal, Container, Spinner } from 'react-bootstrap';
import PageHeader from '../../components/layout/PageHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import Table from '../../components/Table';
import { DateFormatter, CurrencyFormatter, ClientNameFormatter, DepartmentNameFormatter, PaymentChannelFormatter } from '../../components/Formatters';
import { IPageableResults } from "../../models/PageableResults";
import { Redirect } from 'react-router-dom';
import { IAppState, IActionResult } from '../../redux/storeTypes';
import { Routes } from "../../routes";
import { transactionDetailAction,resetActionResult } from '../../redux/actions/payments/paymentTransactions';
import { getClientNamesAction } from "../../redux/actions/clients/clients";
import { getDepartmentNamesAction } from "../../redux/actions/clients/departments";
import { PaymentTransactions, SearchList, TransactionTypeEnum } from '../../models/Payment';
import { ClientName, DepartmentName, PaymentChannelName } from "../../models/Client";
import Summary from './components/Summary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToBottom, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { PdfExport, CsvExport } from './components/Export';
import { defaultSorted } from './RefundPayment'
import { Crumb } from "../../models/Crumb";
import moment from "moment";
import { User } from "../../models/User";
import { centralOffset } from "../../utils/time";
import Search from "./components/Search";
import { sendErrorToastAction } from "../../redux/actions/toast";
import { getAllPaymentChannelNamesAction } from "../../redux/actions/clients/paymentChannels";
import { clearActionResult } from "../../redux/actions/payments/orderManagement";

interface IVoidPayments {
    currentPage: IPageableResults<PaymentTransactions>,
    clientNames: Array<ClientName>,
    departmentNames: Array<DepartmentName>,
    paymentChannelNames: Array<PaymentChannelName>,
    isFetching: boolean,
    transactionDetail: PaymentTransactions,
    currentUser: User
}

const VoidPayments = ({ isFetching, transactionDetail, currentPage, clientNames, departmentNames, paymentChannelNames, currentUser }: IVoidPayments) => {
    const dispatch = useDispatch();
    const actionToken = "Void";
    const [redirect, setRedirect] = useState<string>("");
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState<Array<any>>(Array<any>());

    const [searchReady, setSearchReady] = useState<boolean>(false);
    const [tableReady, setTableReady] = useState<boolean>(false);

    const [startDate, setStartDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
    const [endDate, setEndDate] = useState<string>(moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'));

    const validateStartDate = (startDateString: string) => {
        const allowedDate = moment().startOf('day').format('YYYY-MM-DD');
        if (Date.parse(startDateString) < Date.parse(allowedDate)) {
            dispatch(sendErrorToastAction(`Start date must be on or after ${moment(allowedDate).format('MM/DD/YYYY')}`));
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (!clientNames) {
            dispatch(getClientNamesAction(actionToken));
        }
    }, [clientNames]);

    useEffect(() => {
        if (!departmentNames) {
            dispatch(getDepartmentNamesAction(actionToken));
        }
    }, [departmentNames]);

    useEffect(() => {
        if (!paymentChannelNames) {
            dispatch(getAllPaymentChannelNamesAction(actionToken));
        }
    }, [paymentChannelNames]);

    useEffect(() => {
        if (clientNames && departmentNames && paymentChannelNames) {
            setSearchReady(true);
        }
    }, [clientNames, departmentNames, paymentChannelNames]);

    useEffect(() => {
        setTableReady(false);
        if (currentPage) {
            if (currentUser.userDepartments.length === 1) {
                currentPage.data = currentPage.data.filter(d => d.departmentId === currentUser.userDepartments[0].departmentMSBId);
            }
            const grouped = currentPage.data.reduce((group: any, transaction) => {
                const { orderIdentifier } = transaction;
                group[orderIdentifier] = group[orderIdentifier] ?? [];
                group[orderIdentifier].push(transaction);
                return group;
            }, {});
            let filtered: any = [];
            Object.values(grouped).forEach((slice: any) => { filtered = [...filtered, slice.reverse()[0]] });
            setData(filtered);
            setTableReady(true);
        }
    }, [currentPage]);

    const dropDownOptions = (cell: any, row: any) => {
        return (
            <Dropdown className="req-action-dropdown">
                <Dropdown.Toggle className="approvalAction" variant="link">
                    Manage
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {
                        dispatch(resetActionResult());
                        dispatch(clearActionResult());
                        dispatch(transactionDetailAction(row.msbId, actionToken));
                        setRedirect(Routes.VoidPaymentDetails.path)
                    }}>View Details
                    </Dropdown.Item>
                    {row?.orderPayment?.processorType !== 'PayPal' &&
                        <Dropdown.Item onClick={() => {
                            dispatch(resetActionResult());
                            dispatch(clearActionResult());
                            dispatch(transactionDetailAction(row.msbId, actionToken));
                            setShowModal(true)
                        }}>Void Payment</Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const columns = [
        {
            dataField: 'clientId',
            text: 'Client',
            editable: false,
            formatter: (cell: string) => {
                return ClientNameFormatter(cell, clientNames)
            },
            sort: true
        }, {
            dataField: 'departmentId',
            text: 'Department',
            formatter: (cell: string) => {
                return DepartmentNameFormatter(cell, departmentNames)
            },
            editable: false
        }, {
            dataField: 'paymentChannelId',
            text: 'Payment Channel',
            formatter: (cell: string) => {
                return PaymentChannelFormatter(cell, paymentChannelNames)
            },
            editable: false,
            sort: true,
        }, {
            dataField: 'orderIdentifier',
            text: 'Transaction ID',
            editable: false,
            sort: true
        }, {
            dataField: 'createdAt',
            text: 'Transaction date',
            formatter: DateFormatter,
            sort: true,
            editable: false
        }, {
            dataField: 'itemReferenceNumber',
            text: 'Item reference',
            editable: false,
            sort: true
        }, {
            dataField: 'itemName',
            text: 'Item name',
            editable: false,
            sort: true
        }, {
            dataField: 'nameOnCard',
            text: 'Customer name',
            editable: false
        }, {
            dataField: 'totalAmount',
            text: 'Total amount',
            sort: true,
            formatter: CurrencyFormatter,
            editable: false
        }, {
            dataField: '',
            text: 'Actions',
            editable: false,
            formatter: dropDownOptions,
        }
    ];

    const doToolbar = () => {
        if (!isFetching && data && data.length > 0) {

            return (
            <Dropdown>
                <Dropdown.Toggle className="approvalAction" variant="outline-secondary">
                    <FontAwesomeIcon icon={faArrowToBottom} size="sm" />
                    Download Summary
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => PdfExport(data, "voidable_payments.pdf")}>PDF File</Dropdown.Item>
                    <Dropdown.Item onClick={() => CsvExport(data, "voidable_payments.csv")}>CSV File</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            );
        } else {
            return (<></>);
        }
    }

    const renderTable = () => {
        if (searchReady) {
            if (!data || isFetching || !searchReady) {
                return (<Spinner animation="border" />);
            } else if (!isFetching && data && data.length === 0) {
                return (
                    <>
                        <h2 className="fw-bold">No records were found</h2>
                        <span>Please do another search to find the record you are looking for.</span>
                    </>
                );
            } else if (tableReady) {
                return (
                    <Table
                        keyField="msbId"
                        data={data}
                        columns={columns}
                        defaultSorted={defaultSorted}
                    />);
            }
        }
        return (<></>);
    };

    if (redirect != "") {
        return (<Redirect push to={redirect} />)
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Void Payment", Routes.VoidPayment.path));

        return (
            <>
                {transactionDetail &&
                    <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static">
                        <Modal.Header closeButton />
                        <Modal.Body>
                        <Summary details={transactionDetail} closeModal={() => setShowModal(false)} action={actionToken} />
                        </Modal.Body>
                    </Modal>
                }
                <PageHeader title="Void Payment" crumbs={crumbs} />

                <Container fluid className="container-table-search">
                    <Row>
                        <Col>
                            <PageSectionContainer>
                                <Search
                                    defaultStartDate={startDate}
                                    defaultEndDate={endDate}
                                    searchReady={searchReady}
                                    validateStartDate={validateStartDate} />
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>

                <Container fluid>
                    <Row>
                        <Col>
                            <PageSectionContainer title="Manage Voids" toolbar={doToolbar()}>
                                {renderTable()}
                            </PageSectionContainer>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        searchFields: state.paymentTransactions.searchFields,
        transactionDetail: state.paymentTransactions.transactionDetail,
        currentPage: state.paymentTransactions.currentPage,
        isFetching: state.paymentTransactions.isFetching,
        clientNames: state.clients.clientNames,
        departmentNames: state.clients.departmentNames,
        paymentChannelNames: state.clients.paymentChannelNames,
        currentUser: state.auth.currentUser
    };
};

export default connect(mapStateToProps)(VoidPayments);