import {
    GET_TRANSACTIONS_REQUEST, GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_FAILURE,
    GET_ECHECK_TRANSACTIONS_REQUEST, GET_ECHECK_TRANSACTIONS_SUCCESS, GET_ECHECK_TRANSACTIONS_FAILURE,
    RESET_PAYMENTS_STORE_REQUEST, GET_TRANSACTIONS_DETAIL_REQUEST,
    GET_PAYMENT_CHANNELS_USER_TYPE_REQUEST, GET_PAYMENT_CHANNELS_USER_TYPE_SUCCESS, GET_PAYMENT_CHANNELS_USER_TYPE_FAILURE, 
    SET_SELECTED_DEPARTMENT_PAYMENT_CHANNEL_ITEMS, CLEAR_SELECTED_DEPARTMENT_PAYMENT_CHANNEL_ITEMS,
    GET_TRANSACTIONS_DETAIL_SUCCESS, GET_TRANSACTIONS_DETAIL_FAILURE, GET_AVAILABLE_PAYMENT_CHANNELS,
    RESET_PAYMENTS_STORE_SUCCESS, GET_PAYMENT_REQUEST, GET_PAYMENT_SUCCESS, GET_PAYMENT_FAILURE, RESET_ACTION_RESULT,
    GET_CONVENIENCE_FEE_REQUEST, GET_CONVENIENCE_FEE_SUCCESS, CLEAR_FEES, GET_CONVENIENCE_FEE_FAILURE, CANCEL_FETCH_TRANSACTIONS,
    GET_AVAILABLE_PAYMENT_CHANNELS_SUCCESS, SET_SEARCH_FIELDS, CANCEL_STEP, GET_CLIENT_BANKING_REQUEST, GET_CLIENT_BANKING_SUCCESS, GET_CLIENT_BANKING_FAILURE
} from '../actions/payments/paymentTransactions';

export default function paymentTransactions(state = {
    actionResult: null,
    currentPage: null,
    downloadUrl: null,
    errorMessage: '',
    feeCalculation: null,
    isFetching: false,
    isSaving: null,
    orderTransaction: null,
    paymentChannels: null,
    breadCrumbDetails: null,
    receiptDetails: null,
    searchFields: null,
    transactionDetail: null,
    cancelTokenSource: null,
    paymentChannelsByUser: null,
    selectedDepartmentPaymentChannelItems: null,
}, { type, payload }) {
    switch (type) {
        case GET_TRANSACTIONS_REQUEST:
            return Object.assign({}, state, {
                currentPage: null,
                isFetching: true,
                cancelTokenSource: payload.cancelToken,
                searchFields: payload.searchFields,
                actionResult: { type: GET_TRANSACTIONS_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_TRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                currentPage: payload.results,
                downloadUrl: payload.downloadUrl,
                isFetching: false,
                cancelTokenSource: null,
                searchFields: payload.searchFields,
                actionResult: { type: GET_TRANSACTIONS_REQUEST, result: GET_TRANSACTIONS_SUCCESS, token: payload.actionToken },
            });
        case GET_TRANSACTIONS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                cancelTokenSource: null,
                actionResult: { type: GET_TRANSACTIONS_REQUEST, result: GET_TRANSACTIONS_FAILURE, token: payload.actionToken },
            });
        case GET_PAYMENT_CHANNELS_USER_TYPE_REQUEST:
            return Object.assign({}, state, {
                paymentChannelsByUser: null,
                isFetching: true,
                actionResult: { type: GET_PAYMENT_CHANNELS_USER_TYPE_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_PAYMENT_CHANNELS_USER_TYPE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                paymentChannelsByUser: payload.paymentChannels,
                actionResult: { type: GET_PAYMENT_CHANNELS_USER_TYPE_REQUEST, result: GET_PAYMENT_CHANNELS_USER_TYPE_SUCCESS, token: payload.actionToken },
            });
        case GET_PAYMENT_CHANNELS_USER_TYPE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                actionResult: { type: GET_PAYMENT_CHANNELS_USER_TYPE_REQUEST, result: GET_PAYMENT_CHANNELS_USER_TYPE_FAILURE, token: payload.actionToken },
            });
        case SET_SELECTED_DEPARTMENT_PAYMENT_CHANNEL_ITEMS:
            return Object.assign({}, state, {
                selectedDepartmentPaymentChannelItems: { ...payload.selectedDepartmentPaymentChannelItems }
            });
        case CLEAR_SELECTED_DEPARTMENT_PAYMENT_CHANNEL_ITEMS:
            return Object.assign({}, state, {
                selectedDepartmentPaymentChannelItems: null
            });
        case GET_ECHECK_TRANSACTIONS_REQUEST:
            return Object.assign({}, state, {
                currentPage: null,
                isFetching: true,
                actionResult: { type: GET_TRANSACTIONS_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_ECHECK_TRANSACTIONS_SUCCESS:
            return Object.assign({}, state, {
                currentPage: payload.results,
                isFetching: false,
                searchFields: payload.searchFields,
                actionResult: { type: GET_TRANSACTIONS_REQUEST, result: GET_TRANSACTIONS_SUCCESS, token: payload.actionToken },
            });
        case GET_ECHECK_TRANSACTIONS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                actionResult: { type: GET_TRANSACTIONS_REQUEST, result: GET_TRANSACTIONS_FAILURE, token: payload.actionToken },
            });
        case GET_TRANSACTIONS_DETAIL_REQUEST:
            return Object.assign({}, state, {
                transactionDetail: null,
                actionResult: { type: GET_TRANSACTIONS_DETAIL_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_TRANSACTIONS_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                transactionDetail: payload.transactionDetail,
                breadCrumbDetails: payload.breadCrumbDetails,
                actionResult: { type: GET_TRANSACTIONS_DETAIL_REQUEST, result: GET_TRANSACTIONS_DETAIL_SUCCESS, token: payload.actionToken },
            });
        case GET_TRANSACTIONS_DETAIL_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error,
                actionResult: { type: GET_TRANSACTIONS_DETAIL_REQUEST, result: GET_TRANSACTIONS_DETAIL_FAILURE, token: payload.actionToken },
            });
        case GET_CONVENIENCE_FEE_REQUEST:
            return Object.assign({}, state, {
                feeCalculation: null,
            });
        case GET_CONVENIENCE_FEE_SUCCESS:
            return Object.assign({}, state, {
                feeCalculation: payload.results
            });
        case GET_CONVENIENCE_FEE_FAILURE:
            return Object.assign({}, state, {
                errorMessage: payload.error
            });
        case CLEAR_FEES:
            return Object.assign({}, state, {
                feeCalculation: null,
            });
        case GET_PAYMENT_REQUEST:
            return Object.assign({}, state, {
                actionResult: { type: GET_PAYMENT_REQUEST, result: null, token: payload.actionToken },
                isSaving: true,
                receiptDetails: null
            });
        case GET_PAYMENT_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: GET_PAYMENT_REQUEST, result: GET_PAYMENT_SUCCESS, token: payload.actionToken },
                isSaving: false,
                receiptDetails: payload.receiptDetails,
            });
        case GET_PAYMENT_FAILURE:
            return Object.assign({}, state, {
                actionResult: { type: GET_PAYMENT_REQUEST, result: GET_PAYMENT_FAILURE, token: payload.actionToken },
                errorMessage: payload.error,
                isSaving: false
            });
        case GET_AVAILABLE_PAYMENT_CHANNELS:
            return Object.assign({}, state, {
                isFetching: true
            });
        case GET_AVAILABLE_PAYMENT_CHANNELS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                paymentChannels: payload.results,
            });
        case SET_SEARCH_FIELDS:
            return Object.assign({}, state, {
                searchFields: { ...payload.searchFields }
            });
        case GET_CLIENT_BANKING_REQUEST:
            return Object.assign({}, state, {
                currentPage: null,
                isFetching: true,
                actionResult: { type: GET_CLIENT_BANKING_REQUEST, result: null, token: payload.actionToken },
            });
        case GET_CLIENT_BANKING_SUCCESS:
            return Object.assign({}, state, {
                currentPage: payload.clientBankingDetail,
                isFetching: false,
                actionResult: { type: GET_CLIENT_BANKING_REQUEST, result: GET_CLIENT_BANKING_SUCCESS, token: payload.actionToken },
            });
        case GET_CLIENT_BANKING_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: payload.error,
                actionResult: { type: GET_CLIENT_BANKING_REQUEST, result: GET_CLIENT_BANKING_FAILURE, token: payload.actionToken },
            });
        case CANCEL_STEP:
            return Object.assign({}, state, {
                isFetching: false,
                searchFields: null,
                selectedDepartmentPaymentChannelItems: null
            });
        case RESET_ACTION_RESULT:
            return Object.assign({}, state, {
                transactionDetail: null,
                actionResult: null
            });
        case RESET_PAYMENTS_STORE_REQUEST:
            return Object.assign({}, state, {
                actionResult: { type: RESET_PAYMENTS_STORE_REQUEST, result: null, token: payload.actionToken },
            });
        case RESET_PAYMENTS_STORE_SUCCESS:
            return Object.assign({}, state, {
                actionResult: { type: RESET_PAYMENTS_STORE_REQUEST, result: RESET_PAYMENTS_STORE_SUCCESS, token: payload.actionToken },
                currentPage: null,
                downloadUrl: null,
                errorMessage: '',
                feeCalculation: null,
                isFetching: false,
                paymentChannels: null,
                receiptDetails: null,
                searchFields: null,
                transactionDetail: null,
                breadCrumbDetails: null,
                selectedDepartmentPaymentChannelItems: null
            });
        case CANCEL_FETCH_TRANSACTIONS:
            if (state.cancelTokenSource) {
                state.cancelTokenSource.cancel('Request canceled by user.');
            }
            return {
                ...state,
                cancelTokenSource: null
            };
        default:
            return state;
    }
}


