import { Button, Card, Col, Container, ListGroup, Modal, Row, Spinner } from 'react-bootstrap';
import PageSectionContainer from '../../../components/layout/PageSectionContainer';
import Table from '../../../components/Table';
import { CurrencyFormatter } from '../../../components/Formatters';
import { PaymentTransactions, TransactionDetails } from '../../../models/Payment';
import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { IAppState } from '../../../redux/storeTypes';
import { transactionDetailAction } from '../../../redux/actions/payments/paymentTransactions';
import moment from 'moment';

export interface ITransactionsByTypeAndChannelProps {
    orderId: string,
    orderMsbId: string,
    transactionDetail: TransactionDetails
}

const TransactionDetailModal = ({ orderId, orderMsbId, transactionDetail } : ITransactionsByTypeAndChannelProps) => {
    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(transactionDetailAction(orderMsbId, "ReconTransactionDetailModal"));
    }, []);
   
    const getRow = (label: string, value?: string | number, currency?: boolean) => (
      <div className="transactionDetail">
        <h3>{label}</h3>
        {
          currency ? (
              <span>
                  { CurrencyFormatter(value) }
              </span>
          ) : (
              value || 'N/A'
          )
        }
      </div>        
    );

    return (
        <>
          <div className="container-transaction-details">
            <div className="transactionTitle">
              <h2 style={{marginBottom: '4px'}}>{`${orderId} Transaction Details`}</h2>
              <p>Below are the transaction details</p>
            </div>

            <div className="transactionList transactionListModal">
              {
                  !transactionDetail ? (
                      <><Spinner animation="border" /><br /></>
                  ) : (
                      <>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                        { getRow("Reference number", transactionDetail.transactionIdentifier) }
                        { getRow("Lane number", "N/A") }
                        { getRow("Terminal ID", transactionDetail.orderPayment?.terminalIdentifier) }
                        { getRow("Terminal name", "N/A") }
                        </ListGroup.Item>

                        <ListGroup.Item>
                        { getRow("Subtotal", transactionDetail.amount, true) }
                        { getRow("Convenience fee", transactionDetail.convenienceFee, true) }
                        { getRow("Total amount", transactionDetail.totalAmount, true) }
                        </ListGroup.Item>

                        <ListGroup.Item>
                        { getRow("Gateway transaction ID", "N/A") }
                        { getRow("Transaction ID", transactionDetail.orderIdentifier) }
                        { getRow("Transaction date", moment.utc(transactionDetail.transactionDate || transactionDetail.orderSummary?.createdAt).local().format('MM/DD/YYYY h:mm:ss A')) }
                        { getRow("Transaction type", transactionDetail.transactionType) }
                        { getRow("Transaction status (Code)", transactionDetail.transactionState) }
                        </ListGroup.Item>

                        <ListGroup.Item>
                        { getRow("Payment date", moment.utc(transactionDetail.orderPayment?.createdAt).local().format('MM/DD/YYYY h:mm:ss A')) }
                        { getRow("Payment type", transactionDetail.paymentType) }
                        { getRow("Payment method", transactionDetail.orderPayment?.paymentMethod) }
                        { getRow("Cardholder name", transactionDetail.nameOnCard) }
                        { getRow("Card number", transactionDetail.orderPayment?.lastFourOnCard ? `XXXX-XXXX-XXXX-${transactionDetail.orderPayment.lastFourOnCard}` : "N/A") }
                        { getRow("Customer name", transactionDetail.customerName) }
                        </ListGroup.Item>
                      </ListGroup>
                    </>
                  )
              }
              
            </div>
          </div>

        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        transactionDetail: state.paymentTransactions.transactionDetail
    };
};

export default connect(mapStateToProps)(TransactionDetailModal);