import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { Button, ButtonToolbar, Modal, Form, Row as RowLayout, Col } from 'react-bootstrap';
import 'react-dropdown/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { ProcessedOrder, ReceiptWrapper } from '../../models/Payment';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import { Routes } from "../../routes";
import { clearOrderAction } from '../../redux/actions/payments/orderManagement';
import { IActionResult, IAppState } from '../../redux/storeTypes';
import { Client, Department, PaymentChannel, Printer, Receipt as ReceiptModel, ReceiptTypeEnum } from '../../models/Client';
import FormHeader from '../clients/components/forms/FormHeader';
import { sendEmailAction, SEND_EMAIL_REQUEST, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILURE } from '../../redux/actions/emails';
import { printPrinterReceipt } from '../../redux/actions/clients/printers';

import ReceiptContainerPlugin from "./components/plugins/ReceiptContainerPlugin";
import MerchantIdPropertyPlugin from "./components/plugins/MerchantIdPropertyPlugin";
import TransactionDatePropertyPlugin from "./components/plugins/TransactionDatePropertyPlugin";
import TransactionIdPropertyPlugin from "./components/plugins/TransactionIdPropertyPlugin";
import AccountNumberPropertyPlugin from "./components/plugins/AccountNumberPropertyPlugin";
import AmountPropertyPlugin from "./components/plugins/AmountPropertyPlugin";
import ApprovalCodePropertyPlugin from "./components/plugins/ApprovalCodePropertyPlugin";
import BillingZipPropertyPlugin from "./components/plugins/BillingZipPropertyPlugin";
import CardLogoPropertyPlugin from "./components/plugins/CardLogoPropertyPlugin";
import ConvenienceFeePropertyPlugin from "./components/plugins/ConvenienceFeePropertyPlugin";
import DisclaimerPropertyPlugin from "./components/plugins/DisclaimerPropertyPlugin";
import ReferenceNumberPropertyPlugin from "./components/plugins/ReferenceNumberPropertyPlugin";
import ResponseCodePropertyPlugin from "./components/plugins/ResponseCodePropertyPlugin";
import TotalAmountPropertyPlugin from "./components/plugins/TotalAmountPropertyPlugin";
import TransactionTypePropertyPlugin from "./components/plugins/TransactionTypePropertyPlugin";
import Editor, { Cell, CellPlugin, Row, Value } from '@react-page/editor';
import image from '@react-page/plugins-image';

import { Content, StatusEnum } from '../../models/CMS';
import { getContentAction } from '../../redux/actions/cms';
import NameOnCardPropertyPlugin from './components/plugins/NameOnCardPropertyPlugin';

import './receipts.css';
import CheckingAccountNumberPropertyPlugin from './components/plugins/CheckingAccountNumberPropertyPlugin';
import ClientNamePropertyPlugin from './components/plugins/ClientNamePropertyPlugin';
import DepartmentNamePropertyPlugin from './components/plugins/DepartmentNamePropertyPlugin';
import OrderCapturedByEmployeePropertyPlugin from './components/plugins/OrderCapturedByEmployeePropertyPlugin';
import PaymentChannelNamePropertyPlugin from './components/plugins/PaymentChannelNamePropertyPlugin';
import PaymentTypePropertyPlugin from './components/plugins/PaymentTypePropertyPlugin';
import PhoneNumberPropertyPlugin from './components/plugins/PhoneNumberPropertyPlugin';
import RemainingBalanceAmountPropertyPlugin from './components/plugins/RemainingBalanceAmountPropertyPlugin';
import TransactionDateTimePropertyPlugin from './components/plugins/TransactionDateTimePropertyPlugin';
import TransactionTimePropertyPlugin from './components/plugins/TransactionTimePropertyPlugin';
import DepartmentAddressPropertyPlugin from './components/plugins/DepartmentAddressPropertyPlugin';
import SignaturePropertyPlugin from './components/plugins/SignaturePropertyPlugin';
import TerminalIdentifierPropertyPlugin from './components/plugins/TerminalIdentifierPropertyPlugin';
import EmailPropertyPlugin from './components/plugins/EmailPropertyPlugin';
import EntryPropertyPlugin from './components/plugins/EntryPropertyPlugin';
import CryptogramPropertyPlugin from './components/plugins/CryptogramPropertyPlugin';
import PinVerifiedPropertyPlugin from './components/plugins/PinVerifiedPropertyPlugin';
import OrderLinesPropertyPlugin from './components/plugins/OrderLinesPropertyPlugin';
import ClientMetadataPropertyPlugin from './components/plugins/ClientMetadataPropertyPlugin';
import HorizontalRulePlugin from "../paymentChannels/web/components/plugins/HorizontalRulePlugin";
import ItemReferencePropertyPlugin from "./components/plugins/ItemReferencePropertyPlugin";
import SignatureDisclaimerPropertyPlugin from "./components/plugins/SignatureDisclaimerPropertyPlugin"
import CustomerNamePropertyPlugin from './components/plugins/CustomerNamePropertyPlugin';
import LinkPropertyPlugin from './components/plugins/LinkPropertyPlugin';
import PlainTextPlugin from './components/plugins/PlainTextPlugin';
import SpacerPlugin from './components/plugins/SpacerPlugin';
import ButtonPlugin from './components/plugins/ButtonPlugin';
import { EmailRequest, EmailType, TemplateType } from '../../models/EmailRequest';
import { sendSuccessToastAction, sendErrorToastAction } from '../../redux/actions/toast';
import EscPosEncoder from 'esc-pos-encoder';
import _ from 'lodash';
import moment from 'moment';
import { getDepartmentAction } from '../../redux/actions/clients/departments';
import { getPaymentChannelAction } from '../../redux/actions/clients/paymentChannels';
import { resetPaymentStoreAction } from '../../redux/actions/payments/paymentTransactions';
import TriPOSPrinter from './components/TriPOSPrinter';

import ReasonPropertyPlugin from './components/plugins/ReasonPropertyPlugin';

interface IReceiptProps {
    client: Client,
    department: Department,
    paymentChannel: PaymentChannel,
    orderedPage?: string,
    processedOrder: ProcessedOrder,
    content: Content,
    emailIsSending: boolean,
    emailActionResult: IActionResult
    printReceipt?: boolean,
    isPaymentReceiptComponent?: boolean,
}

const Receipt = ({ client, department, paymentChannel, orderedPage, processedOrder, content, emailIsSending, emailActionResult, printReceipt, isPaymentReceiptComponent }: IReceiptProps) => {

    const dispatch = useDispatch();
    const actionToken = "Receipt";
    const [receipt, setReceipt] = useState<ReceiptModel>();
    const [value, setValue] = useState<Value>();

    const [showPrintModal, setShowPrintModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [validated, setValidated] = useState<boolean>(false);
    const [redirect, setRedirect] = useState<string>("");
    const [printParse, setPrintParse] = useState<[]>();

    let receiptContainerPlugin = ReceiptContainerPlugin as CellPlugin<unknown, unknown>;

    let accountNumberPropertyPlugin = AccountNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let amountPropertyPlugin = AmountPropertyPlugin as CellPlugin<unknown, unknown>;
    let approvalCodePropertyPlugin = ApprovalCodePropertyPlugin as CellPlugin<unknown, unknown>;
    let billingZipPropertyPlugin = BillingZipPropertyPlugin as CellPlugin<unknown, unknown>;
    let buttonPlugin = ButtonPlugin as CellPlugin<unknown, unknown>;
    let cardLogoPropertyPlugin = CardLogoPropertyPlugin as CellPlugin<unknown, unknown>;
    let checkingAccountNumberPropertyPlugin = CheckingAccountNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let clientNamePlugin = ClientNamePropertyPlugin as CellPlugin<unknown, unknown>;
    let clientMetadataPropertyPlugin = ClientMetadataPropertyPlugin as CellPlugin<unknown, unknown>;
    let convenienceFeePropertyPlugin = ConvenienceFeePropertyPlugin as CellPlugin<unknown, unknown>;
    let cryptogramPropertyPlugin = CryptogramPropertyPlugin as CellPlugin<unknown, unknown>;
    let departmentAddressPropertyPlugin = DepartmentAddressPropertyPlugin as CellPlugin<unknown, unknown>;
    let departmentNamePropertyPlugin = DepartmentNamePropertyPlugin as CellPlugin<unknown, unknown>;
    let disclaimerPropertyPlugin = DisclaimerPropertyPlugin as CellPlugin<unknown, unknown>;
    let emailPropertyPlugin = EmailPropertyPlugin as CellPlugin<unknown, unknown>;
    let entryPropertyPlugin = EntryPropertyPlugin as CellPlugin<unknown, unknown>;
    let horizontalRulePlugin = HorizontalRulePlugin as CellPlugin<unknown, unknown>;
    let itemReferencePropertyPlugin = ItemReferencePropertyPlugin as CellPlugin<unknown, unknown>;
    let linkPropertyPlugin = LinkPropertyPlugin as CellPlugin<unknown, unknown>;
    let merchantIdPropertyPlugin = MerchantIdPropertyPlugin as CellPlugin<unknown, unknown>;
    let nameOnCardPropertyPlugin = NameOnCardPropertyPlugin as CellPlugin<unknown, unknown>;
    let orderCapturedByEmployeePropertyPlugin = OrderCapturedByEmployeePropertyPlugin as CellPlugin<unknown, unknown>;
    let orderLinesPropertyPlugin = OrderLinesPropertyPlugin as CellPlugin<unknown, unknown>;
    let paymentChannelNamePropertyPlugin = PaymentChannelNamePropertyPlugin as CellPlugin<unknown, unknown>;
    let paymentTypePropertyPlugin = PaymentTypePropertyPlugin as CellPlugin<unknown, unknown>;
    let phoneNumberPropertyPlugin = PhoneNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let pinVerifiedPropertyPlugin = PinVerifiedPropertyPlugin as CellPlugin<unknown, unknown>;
    let plainTextPlugin = PlainTextPlugin as CellPlugin<unknown, unknown>;
    let receiptImagePlugin = image as CellPlugin<unknown, unknown>;
    let referenceNumberPropertyPlugin = ReferenceNumberPropertyPlugin as CellPlugin<unknown, unknown>;
    let remainingBalanceAmountPropertyPlugin = RemainingBalanceAmountPropertyPlugin as CellPlugin<unknown, unknown>;
    let responseCodePropertyPlugin = ResponseCodePropertyPlugin as CellPlugin<unknown, unknown>;
    let signatureDisclaimerPropertyPlugin = SignatureDisclaimerPropertyPlugin as CellPlugin<unknown, unknown>;
    let signaturePropertyPlugin = SignaturePropertyPlugin as CellPlugin<unknown, unknown>;
    let spacerPlugin = SpacerPlugin as CellPlugin<unknown, unknown>;
    let terminalIdentifierPropertyPlugin = TerminalIdentifierPropertyPlugin as CellPlugin<unknown, unknown>;
    let totalAmountPropertyPlugin = TotalAmountPropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionDatePropertyPlugin = TransactionDatePropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionDateTimePropertyPlugin = TransactionDateTimePropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionIdPropertyPlugin = TransactionIdPropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionTimePropertyPlugin = TransactionTimePropertyPlugin as CellPlugin<unknown, unknown>;
    let transactionTypePropertyPlugin = TransactionTypePropertyPlugin as CellPlugin<unknown, unknown>;

    let customerNamePropertyPlugin = CustomerNamePropertyPlugin as CellPlugin<unknown, unknown>;
    let reasonPropertyPlugin = ReasonPropertyPlugin as CellPlugin<unknown, unknown>;

    const cellPlugins = new Array<CellPlugin>();
    const checkReceiptData = processedOrder?.processorData?.receipt!;
    const checkSupplementalFields = checkReceiptData?.orderTransaction?.clientMetadata || checkReceiptData?.orderTransaction?.orderSummary?.orderTransactions[0]?.clientMetadata;
    //change this as part of cms refactor
    const checkClientMetaData = checkSupplementalFields &&
        Object.fromEntries(
            Object.entries
            (checkSupplementalFields)
            .filter(([key]) => key.includes('cmd_')));

    cellPlugins.push(receiptContainerPlugin);

    checkReceiptData?.orderTransaction?.lastFourOnCard &&
        cellPlugins.push(accountNumberPropertyPlugin);

    checkReceiptData?.amount &&
        cellPlugins.push(amountPropertyPlugin);

    checkReceiptData?.approvalNumber &&
        cellPlugins.push(approvalCodePropertyPlugin);

    cellPlugins.push(buttonPlugin);

    checkReceiptData?.orderTransaction?.zip &&
        cellPlugins.push(billingZipPropertyPlugin);

    checkReceiptData?.cardLogo &&
        cellPlugins.push(cardLogoPropertyPlugin);

    cellPlugins.push(checkingAccountNumberPropertyPlugin);

    cellPlugins.push(clientNamePlugin);

    checkReceiptData?.convenienceFee &&
        cellPlugins.push(convenienceFeePropertyPlugin);

    checkReceiptData?.cryptogram &&
        cellPlugins.push(cryptogramPropertyPlugin);

    checkReceiptData?.nameOnCard &&
        cellPlugins.push(customerNamePropertyPlugin);

    cellPlugins.push(departmentAddressPropertyPlugin);

    cellPlugins.push(departmentNamePropertyPlugin);

    cellPlugins.push(disclaimerPropertyPlugin);

    checkReceiptData?.orderTransaction?.email &&
        cellPlugins.push(emailPropertyPlugin);

    checkReceiptData?.entry &&
        cellPlugins.push(entryPropertyPlugin);

    cellPlugins.push(horizontalRulePlugin);

    cellPlugins.push(linkPropertyPlugin);

    checkReceiptData?.merchantIdentifier &&
        cellPlugins.push(merchantIdPropertyPlugin);

    checkReceiptData?.nameOnCard &&
        cellPlugins.push(nameOnCardPropertyPlugin);

    checkReceiptData?.orderTransaction?.initiatedBy &&
        cellPlugins.push(orderCapturedByEmployeePropertyPlugin);

    checkReceiptData?.orderTransaction?.orderLines &&
        cellPlugins.push(orderLinesPropertyPlugin);

    !_.isEmpty(checkClientMetaData) &&
        cellPlugins.push(clientMetadataPropertyPlugin);

    cellPlugins.push(paymentChannelNamePropertyPlugin);

    checkReceiptData?.orderTransaction?.paymentType &&
        cellPlugins.push(paymentTypePropertyPlugin);

    checkReceiptData?.orderTransaction?.phone &&
        cellPlugins.push(phoneNumberPropertyPlugin);

    checkReceiptData?.pinVerified &&
        cellPlugins.push(pinVerifiedPropertyPlugin);

    cellPlugins.push(plainTextPlugin);

    cellPlugins.push(receiptImagePlugin);

    checkReceiptData?.orderTransaction?.orderLines &&
        cellPlugins.push(itemReferencePropertyPlugin);

    checkReceiptData?.orderTransaction?.orderIdentifier &&
        cellPlugins.push(referenceNumberPropertyPlugin);

    cellPlugins.push(remainingBalanceAmountPropertyPlugin);

    checkReceiptData?.hostResponseCode &&
        cellPlugins.push(responseCodePropertyPlugin);

    checkReceiptData?.signatureBase64 &&
        cellPlugins.push(signatureDisclaimerPropertyPlugin);

    checkReceiptData?.signatureBase64 &&
        cellPlugins.push(signaturePropertyPlugin);

    cellPlugins.push(spacerPlugin);

    checkReceiptData?.terminalIdentifier &&
        cellPlugins.push(terminalIdentifierPropertyPlugin);

    checkReceiptData?.totalAmount &&
        cellPlugins.push(totalAmountPropertyPlugin);

    checkReceiptData?.createdAt &&
        cellPlugins.push(transactionDatePropertyPlugin);

    cellPlugins.push(transactionDateTimePropertyPlugin);

    checkReceiptData?.transactionIdentifier &&
        cellPlugins.push(transactionIdPropertyPlugin);

    cellPlugins.push(transactionTimePropertyPlugin);

    checkReceiptData?.orderTransaction?.transactionType &&
        cellPlugins.push(transactionTypePropertyPlugin);

    checkReceiptData?.orderTransaction?.reason &&
        cellPlugins.push(reasonPropertyPlugin);

    var pluginPropertyMap = new Map<string, string>();
    pluginPropertyMap.set(accountNumberPropertyPlugin.id, "processorData.receipt.orderTransaction.lastFourOnCard");
    pluginPropertyMap.set(checkingAccountNumberPropertyPlugin.id, "processorData.receipt.orderTransaction.lastFourOnCard");
    pluginPropertyMap.set(amountPropertyPlugin.id, "processorData.receipt.amount");
    pluginPropertyMap.set(approvalCodePropertyPlugin.id, "processorData.receipt.approvalNumber");
    pluginPropertyMap.set(billingZipPropertyPlugin.id, "processorData.receipt.orderTransaction.zip");
    pluginPropertyMap.set(cardLogoPropertyPlugin.id, "processorData.receipt.cardLogo");
    pluginPropertyMap.set(clientNamePlugin.id, "clientName");
    //change this as part of cms refactor
    pluginPropertyMap.set(clientMetadataPropertyPlugin.id, `${processedOrder?.processorData?.receipt?.orderTransaction?.clientMetadata ? 'processorData.receipt.orderTransaction.clientMetadata' : 'processorData.receipt.orderTransaction.orderSummary.orderTransactions'}`);
    pluginPropertyMap.set(convenienceFeePropertyPlugin.id, "processorData.receipt");
    pluginPropertyMap.set(cryptogramPropertyPlugin.id, "processorData.receipt.cryptogram");
    pluginPropertyMap.set(customerNamePropertyPlugin.id, "processorData.receipt.nameOnCard");
    pluginPropertyMap.set(departmentAddressPropertyPlugin.id, "processorData.address");
    pluginPropertyMap.set(departmentNamePropertyPlugin.id, "departmentName");
    pluginPropertyMap.set(emailPropertyPlugin.id, "processorData.receipt.orderTransaction.email");
    pluginPropertyMap.set(entryPropertyPlugin.id, "processorData.receipt.entry");
    pluginPropertyMap.set(itemReferencePropertyPlugin.id, "processorData.receipt.orderTransaction.orderLines");
    pluginPropertyMap.set(merchantIdPropertyPlugin.id, "processorData.receipt.merchantIdentifier");
    pluginPropertyMap.set(nameOnCardPropertyPlugin.id, "processorData.receipt.nameOnCard");
    pluginPropertyMap.set(orderCapturedByEmployeePropertyPlugin.id, "processorData.receipt.orderTransaction.initiatedBy");
    pluginPropertyMap.set(orderLinesPropertyPlugin.id, "processorData.receipt.orderTransaction.orderLines");
    pluginPropertyMap.set(paymentChannelNamePropertyPlugin.id, "paymentChannelName");
    pluginPropertyMap.set(paymentTypePropertyPlugin.id, "processorData.receipt.orderTransaction.paymentType");
    pluginPropertyMap.set(phoneNumberPropertyPlugin.id, "processorData.receipt.orderTransaction.phone");
    pluginPropertyMap.set(pinVerifiedPropertyPlugin.id, "processorData.receipt.pinVerified");
    pluginPropertyMap.set(referenceNumberPropertyPlugin.id, "processorData.receipt.orderTransaction.orderIdentifier");
    pluginPropertyMap.set(responseCodePropertyPlugin.id, "processorData.receipt.hostResponseCode");
    pluginPropertyMap.set(signaturePropertyPlugin.id, "processorData.receipt.signatureBase64");
    pluginPropertyMap.set(terminalIdentifierPropertyPlugin.id, "processorData.receipt.terminalIdentifier");
    pluginPropertyMap.set(totalAmountPropertyPlugin.id, "processorData.receipt.totalAmount");
    pluginPropertyMap.set(transactionDateTimePropertyPlugin.id, "processorData.receipt.orderTransaction.createdAt");
    pluginPropertyMap.set(transactionDatePropertyPlugin.id, "processorData.receipt.createdAt");
    pluginPropertyMap.set(transactionIdPropertyPlugin.id, "processorData.receipt.transactionIdentifier");
    pluginPropertyMap.set(transactionTypePropertyPlugin.id, "processorData.receipt.orderTransaction.transactionType");
    pluginPropertyMap.set(reasonPropertyPlugin.id, "processorData.receipt.orderTransaction.reason");

    useEffect(() => {
        if (content && receipt && content.msbId! === receipt.contentId) {
            for (let x = 0; x < content.revisions.length; x++) {
                if (content.revisions[x].statusEnum === StatusEnum.Draft) {
                    let revision = content.revisions[x];
                    let value = JSON.parse(revision.value);
                    populateCMSValueWithReceiptData(value);
                    setValue(value);
                    break;
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);
    
    useEffect(() => {
        if (processedOrder && processedOrder.processorData && processedOrder.processorData.receipt && client && department && paymentChannel && department.msbId === processedOrder.processorData.receipt.departmentId && paymentChannel.msbId === processedOrder.processorData.receipt.paymentChannelId) {
            var processorType = processedOrder.processorData.receipt.processorType;
            var paymentType = processedOrder.processorData.receipt.orderTransaction!.paymentType;
            var receiptType = ReceiptTypeEnum.CardPresent;

            if (paymentType === "ECheck") {
                receiptType = ReceiptTypeEnum.eCheck;
            } else if (processorType === "VantivExpress") {
                receiptType = ReceiptTypeEnum.CardNotPresent;
            } else if (processorType === "PayPal") {
                receiptType = ReceiptTypeEnum.CardNotPresent;
            }

            var _receipt = department.receipts!.filter(_ => _.receiptType === receiptType)[0];
            setReceipt(_receipt);

            var _processedOrder = processedOrder as any;
            _processedOrder.clientName = client.businessName;
            _processedOrder.departmentName = department.name;
            _processedOrder.paymentChannelName = paymentChannel.name;

            if (_receipt && (_receipt.contentId !== '00000000-0000-0000-0000-000000000000' || _receipt.contentId !== content.msbId)) {
                dispatch(getContentAction(_receipt.contentId, actionToken));
            } else {
            }
        } else {
            if (processedOrder && processedOrder.processorData && processedOrder.processorData.receipt && (!department || department.msbId !== processedOrder.processorData.receipt.departmentId)) {
                dispatch(getDepartmentAction(processedOrder.processorData.receipt.departmentId));
            }
            if (processedOrder && processedOrder.processorData && processedOrder.processorData.receipt && (!paymentChannel || paymentChannel.msbId !== processedOrder.processorData.receipt.paymentChannelId)) {
                dispatch(getPaymentChannelAction(processedOrder.processorData.receipt.paymentChannelId));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processedOrder, client, department, paymentChannel]);

    const populateCMSValueWithReceiptData = (value: Value) => {
        value.rows?.map(populateCMSValueReceiptDataRow);
    }

    const populateCMSValueReceiptDataRow = (row: Row, index: number, rows: Array<Row>) => {
        row.cells.map((cell: Cell, index: number) => {
            if (cell.plugin && cell.dataI18n && pluginPropertyMap.has(cell.plugin.id)) {
                var property = pluginPropertyMap.get(cell.plugin.id)!;
                var defaultValue = cell.dataI18n.default as any;
                defaultValue.value = getNestedObjectValue(processedOrder, property);
            }

            cell.rows?.map(populateCMSValueReceiptDataRow);
        });
    }

    const getNestedObjectValue = (data: any, field: string) => {
        const pList = field.split('.');
        let _data = data;

        pList.map((property: string, index: number) => {
            if (_data[property]) {
                _data = _data[property];
            }
        });

        return _data;
    }

    //==========================================================================================================================================
    // MSB Local Thermal Printer Handling...

    const [autoPrint, setAutoPrint] = useState(sessionStorage.getItem("autoPrint") || 'false');

    useEffect(() => {
        sessionStorage.setItem("autoPrint", autoPrint);
    }, [autoPrint]);

    useEffect(() => {
        let _printParse: any = [];
        const iterateValue = (obj: any) => {
            Object.keys(obj).forEach(key => {
                if (key === "dataI18n" && typeof obj[key] === 'object' && obj[key] !== null) _printParse.push(obj[key].default);
                if (typeof obj[key] === 'object' && obj[key] !== null) iterateValue(obj[key]);
            })
        }
        if (value && !printParse && navigator.usb) {
            iterateValue(value);
            setPrintParse(_printParse);
        }
    }, [value]);

    useEffect(() => {
        if (printParse && autoPrint === 'true') checkLocalThermalPrinter();
    }, [printParse]);

    const printEncode = () => {
        let encoder = new EscPosEncoder();
        let data = encoder.initialize().align('center').encode();
        try {
            printParse?.forEach(async (item: any) => {
                let block = new Uint8Array([]);
                const encodeSignature = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        let img = new Image();
                        img.src = 'data:image/png;base64,' + item.value;
                        block = encoder.newline().image(img, 480, 160, 'atkinson').align('left').size('small').line('Signature').size('normal').underline(true).line(''.padStart(48)).underline(false).align('center').newline().encode();
                    }
                }
                const encodeOrderDetails = () => {
                    block = encoder.bold(true).line(item.label).bold(false).encode();
                    item.value.forEach((line: any) => {
                        let cost = line.quantity + " x " + line.unitPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        let price = (line.quantity * line.unitPrice).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        let unit = encoder.align('left').bold(true).line(line.itemReferenceNumber).line(line.itemName).bold(false).text(cost).text(price.toString().padStart(48 - cost.length - 1)).align('center').newline().encode();
                        block = new Uint8Array([...block, ...unit]);
                    });
                }
                const encodeItemReference = () => {
                    if (typeof item.value === 'object' && item.value) {
                        let value = item.value.map((line: any) => { return line.itemReferenceNumber }).join(", ");
                        if (value.replace(", ", "") !== "") {
                            if ((item.label + value).length > 47) {
                                block = encoder.align('left').bold(true).line(item.label).bold(false).align('right').line(value).align('center').encode();
                            } else {
                                block = encoder.bold(true).text(item.label).bold(false).text(value.padStart(48 - item.label.length)).newline().encode();
                            }
                        }
                    }
                }
                const encodeCryptogram = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        block = encoder.bold(true).text(item.label).bold(false).text("APPROVED".padStart(48 - item.label.length)).newline().align('right').line(item.value).align('center').newline().encode();
                    }
                }
                const encodeTransactionDateTime = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        block = encoder.bold(true).text(item.label).bold(false).text(moment.utc(item.value).local().format('MM-DD-YYYY h:mm a').padStart(48 - item.label.length)).newline().encode();
                    }
                }
                const encodeDepartmentAddress = () => {
                    if (typeof item.value === 'object' && item.value) {
                        let value = `${item.value.addressLine1} ${item.value.addressLine2}, ${item.value.city} ${item.value.state} ${item.value.zipCode}`
                        if ((item.label + value).length > 47) {
                            block = encoder.align('left').bold(true).line(item.label).bold(false).align('right').line(value).align('center').encode();
                        } else {
                            block = encoder.bold(true).text(item.label).bold(false).text(value.padStart(48 - item.label.length)).newline().encode();
                        }
                    }
                }
                const encodePinVerified = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        block = encoder.line("PIN VERIFIED").newline().bold(true).size('small').line("I agree to pay the above total amount").line("according to the card issuer agreement").size('normal').bold(false).newline().line("THANK YOU").newline().encode();
                    }
                }
                const encodeAmount = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        let value = item.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        if ((item.label + item.value).length > 47) {
                            block = encoder.align('left').bold(true).line(item.label).bold(false).align('right').line(value).align('center').encode();
                        } else {
                            block = encoder.bold(true).text(item.label).bold(false).text(value.padStart(48 - item.label.length)).newline().encode();
                        }
                    }
                }
                const encodeFee = () => {
                    if (!item.value.orderTransaction?.orderSummary?.convenienceFeeIsClientAbsorbed) {
                        let value = item.value.convenienceFee.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
                        if ((item.label + item.value).length > 47) {
                            block = encoder.align('left').bold(true).line(item.label).bold(false).align('right').line(value).align('center').encode();
                        } else {
                            block = encoder.bold(true).text(item.label).bold(false).text(value.padStart(48 - item.label.length)).newline().encode();
                        }
                    }
                }
                const encodeDefault = () => {
                    if (typeof item.value !== 'object' && item.value) {
                        let value = item.value.toString();
                        if ((item.label + item.value).length > 47) {
                            block = encoder.align('left').bold(true).line(item.label).bold(false).align('right').line(value.trim()).align('center').encode();
                        } else {
                            block = encoder.bold(true).text(item.label).bold(false).text(value.trim().padStart(48 - item.label.length)).newline().encode();
                        }
                    } else {
                        if (!item.value) {
                            if (item.label.length > 65) {
                                let chunks = item.label.match(/\S.{1,62}\S(?= |$)/g);
                                block = encoder.newline().encode();
                                chunks.forEach((chunk: any) => {
                                    let unit = encoder.align('left').size('small').line(chunk.trim()).size('normal').align('center').encode();
                                    block = new Uint8Array([...block, ...unit]);
                                });
                            }
                        }
                    }
                }
                if (_.isEmpty(item) || item.height) { block = encoder.newline().encode(); }
                if (item.text) { block = encoder.line(item.text).encode(); }
                if (item.tag) {
                    switch (item.tag) {
                        case 'Signature':
                            encodeSignature();
                            break;
                        case 'Order Details':
                            encodeOrderDetails();
                            break;
                        case 'Item Reference':
                            encodeItemReference();
                            break;
                        case 'Cryptogram':
                            encodeCryptogram();
                            break;
                        case 'Transaction Date/Time':
                            encodeTransactionDateTime();
                            break;
                        case 'Department Address':
                            encodeDepartmentAddress();
                            break;
                        case 'Pin Verified':
                            encodePinVerified();
                            break;
                        case 'Subtotal':
                        case 'Total Amount':
                            encodeAmount();
                            break;
                        case 'Convenience Fee':
                            encodeFee();
                            break;
                        default:
                            encodeDefault();
                            break;
                    }
                } else {
                    if (item.label) {
                        switch (item.label) {
                            case 'Signature':
                                encodeSignature();
                                break;
                            case 'Order Details':
                                encodeOrderDetails();
                                break;
                            case 'Item Reference':
                                encodeItemReference();
                                break;
                            case 'Cryptogram':
                                encodeCryptogram();
                                break;
                            case 'Transaction Date/Time':
                                encodeTransactionDateTime();
                                break;
                            case 'Department Address':
                                encodeDepartmentAddress();
                                break;
                            case 'Pin Verified':
                                encodePinVerified();
                                break;
                            case 'Subtotal':
                            case 'Total Amount':
                                encodeAmount();
                                break;
                            case 'Convenience Fee':
                                encodeFee();
                                break;
                            default:
                                encodeDefault();
                                break;
                        }
                    }
                }
                if (item.src) {
                    let img = new Image();
                    img.crossOrigin = "Anonymous";
                    img.src = item.src;
                    await img.decode().then(() => { block = encoder.newline().image(img, 320, 320, 'atkinson').newline().encode(); }).catch(() => { });
                }
                data = new Uint8Array([...data, ...block]);
            })
            let feed = encoder.newline().newline().newline().newline().newline().newline().cut().encode();
            data = new Uint8Array([...data, ...feed]);
        } catch (e) {
            dispatch(sendErrorToastAction("MSB Local USB Thermal Printer: Unable to parse receipt..."));
        }
        return data;
    }

    const endpoint = (device: any) => {
        let endpoints = device.configuration.interfaces[0].alternates[0].endpoints;
        for (let e in endpoints) {
            if (endpoints[e].direction === 'out') return endpoints[e].endpointNumber;
        }
    }

    const localPrint = async (device: any) => {
        await device.open()
            .then(async () => { await device.selectConfiguration(1); })
            .then(async () => { await device.claimInterface(device.configuration.interfaces[0].interfaceNumber); })
            .then(async () => { await device.transferOut(endpoint(device), printEncode()).catch(() => { dispatch(sendErrorToastAction("MSB Local USB Thermal Printer not printing...")); }) })
            .catch(() => { dispatch(sendErrorToastAction("MSB Local USB Thermal Printer not configurable...")); });
    }

    const checkLocalThermalPrinter = async () => {
        await navigator.usb.getDevices()
            .then(async devices => { devices.forEach(device => { if (device.vendorId === 4070 && device.productId == 33054) localPrint(device); }); })
    }

    const handleLocalThermalPrinter = async () => {
        await navigator.usb.getDevices()
            .then(async devices => {
                if (devices.length > 0) {
                    devices.forEach(device => { if (device.vendorId === 4070 && device.productId == 33054) localPrint(device); });
                } else {
                    await navigator.usb.requestDevice({ filters: [{ vendorId: 4070, productId: 33054 }] })
                        .then(selectedDevice => { localPrint(selectedDevice); })
                        .catch(() => { dispatch(sendErrorToastAction("MSB Local USB Thermal Printer not found...")); })
                }
            })
    }

    //==========================================================================================================================================

    const handleMakeAnotherPayment = () => {
        dispatch(clearOrderAction());
        dispatch(resetPaymentStoreAction());
        if (orderedPage === "QuickPay") {
            setRedirect(Routes.QuickPay.path)
        } else if (orderedPage === "TerminalPay") {
            setRedirect(Routes.TerminalPay.path)
        }else if (orderedPage === "Refund") {
            setRedirect(Routes.RefundPayment.path)
        }else if (orderedPage === "Chargeback") {
            setRedirect(Routes.CreateChargeback.path)
        }else if (orderedPage === "ChargebackReversal") {
            setRedirect(Routes.ChargebackReversals.path)
        }else if (orderedPage === "Void" || orderedPage === "Reversal") {
            setRedirect(Routes.VoidPayment.path)
        }else {
            setRedirect(Routes.PointOfSale.path)
        }
    };

    const handlePrintReceipt = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;

        const clientAddress = processedOrder.processorData?.address;
        const orderReceipt = processedOrder.processorData?.receipt;
        let receiptWrapper: ReceiptWrapper = new ReceiptWrapper();

        if (form.checkValidity() !== false) {

            receiptWrapper.orderPayment = orderReceipt;
            receiptWrapper.receiptAddress = clientAddress;
            receiptWrapper.printerId = 3;
            receiptWrapper.printerName = "TestSK";

            let printer: Printer = new Printer();

            dispatch(printPrinterReceipt(printer, receiptWrapper, actionToken));

        } else {
        }
        setShowPrintModal(false);
    };


    const PrintModal = () => (
        <Modal show={showPrintModal} onHide={() => setShowPrintModal(false)}>
            <Modal.Header closeButton />

            <Modal.Body>
                <Form onSubmit={(e) => handlePrintReceipt(e)} className="m-0">
                    <FormHeader title="Print receipt" description="Please select the printer to use." />
                    <Form.Group controlId="printTerminal" >
                        <Form.Control as="select">
                            <option>Web browser</option>
                            <option>Xeror L1011</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="form-footer">
                        <Button variant="outline-secondary" onClick={() => setShowPrintModal(false)}>Cancel</Button>
                        <Button type="submit">Print</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    );

    const elementRef = useRef<any>();

    const getClassStyles = (element: any) => {
        const classNames = Array.from(element.getElementsByTagName('*')).map((el: any) => el.className).join(' ');
        const styleSheets = Array.from(document.styleSheets);
        let classStyles = '';
        styleSheets.forEach(sheet => {
            const cssRules = Array.from(sheet.cssRules);
            cssRules.forEach((rule: any) => {
                if (rule.selectorText && classNames.includes(rule.selectorText.split('.')[1])) {
                    classStyles += rule.cssText;
                }
            });
        });
        return classStyles;
    };

    const replaceRows = (html: any) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        const rows = div.querySelectorAll('div.row');
        rows.forEach((row: Element) => {
            const colChildren = row.querySelectorAll('.col');
            if (colChildren.length === 2) {
                const content1 = colChildren[0].innerHTML;
                const content2 = colChildren[1].innerHTML;
                const newStructure = `
                    <div class="row" style="width: 100%;">
                        <table style="width: 100%; border-collapse: collapse;">
                            <tr>
                                <td style="width: 50%; text-align: left;">
                                    <div class="col">${content1}</div>
                                </td>
                                <td style="width: 50%; text-align: left;">
                                    <div class="col">${content2}</div>
                                </td>
                            </tr>
                        </table>
                    </div>`;
                const contentDiv = document.createElement('div');
                contentDiv.insertAdjacentHTML('beforeend', newStructure);
                row.innerHTML = contentDiv.innerHTML;
            }
        });
        return div.innerHTML;
    };

    const handleEmailReceipt = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            let emailRequest = new EmailRequest();
            emailRequest.emailType = EmailType.Receipt;
            emailRequest.templateType = TemplateType.StandardReceipt;
            emailRequest.recipientAddresses.push(form.elements.emailAddress.value);
            emailRequest.subject = "Order Receipt";
            emailRequest.model = processedOrder;
            //emailRequest.renderedBody = `<!doctype html><head><meta name="viewport" content="width=device-width, initial-scale=1.0" /><style>${getClassStyles(elementRef.current)}</style></head><body>${replaceRows(elementRef.current.innerHTML)}</body></html>`;
            dispatch(sendEmailAction(emailRequest, actionToken));
            setShowEmailModal(false);
        }
        setValidated(true);
    };

    useEffect(() => {
        if (emailActionResult && emailActionResult.result) {
            if (emailActionResult.type === SEND_EMAIL_REQUEST && emailActionResult.token === actionToken) {
                if (emailActionResult.result === SEND_EMAIL_SUCCESS) {
                    setShowEmailModal(false);
                    dispatch(sendSuccessToastAction("Email successfully sent."));
                } else if (emailActionResult.result === SEND_EMAIL_FAILURE) {
                    setShowEmailModal(false);
                    dispatch(sendErrorToastAction("Email failed to send. Reason(s) : " + emailActionResult.message));
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailActionResult]);

    const EmailModal = () => {
        const orderReceipt = processedOrder?.processorData?.receipt;
        return (
            <Modal show={showEmailModal} onHide={() => setShowEmailModal(false)}>
                <Modal.Header closeButton />

                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleEmailReceipt}>
                        <FormHeader title="Email receipt" description="Please enter the email address below to receive a copy of the receipt." />
                        <Form.Group controlId="emailAddress">
                            <Form.Control required type="email" placeholder="Enter email address" defaultValue={orderReceipt?.orderTransaction?.email} />
                        </Form.Group>
                        <Form.Group className="form-footer">
                            <Button variant="outline-secondary" onClick={() => setShowEmailModal(false)}>Cancel</Button>
                            <Button type="submit">Send</Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }

    const docWrite = (doc: any) => {
        if (doc) {
            doc.open();
            var doctype = document.implementation.createDocumentType('html', '', '');
            doc.appendChild(doctype);
            var html = doc.createElement('html');
            doc.appendChild(html);
            var head = doc.createElement('head');
            html.appendChild(head);
            var style = doc.createElement('style');
            style.textContent = getClassStyles(elementRef.current);
            head.appendChild(style);
            var body = doc.createElement('body');
            body.style.fontFamily = "'Lato', sans-serif";
            body.style.fontSize = '0.875rem';
            body.style.textAlign = 'center';
            html.appendChild(body);
            body.innerHTML = replaceRows(elementRef.current.innerHTML);
            doc.close();
        }
    }

    const handlePrintDIV = () => {
        var iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.width = '0';
        iframe.style.height = '0';
        iframe.style.border = 'none';
        document.body.appendChild(iframe);
        var doc = iframe?.contentDocument || iframe?.contentWindow?.document;
        docWrite(doc);
        iframe.onload = function () {
            iframe?.contentWindow?.print();
            document.body.removeChild(iframe);
        };
    }

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                <PrintModal />

                <EmailModal />

                <PageSectionContainer>
                    <div className="container-receipt-details">
                        <div className="receipt-header">
                            {!printReceipt &&
                                <>
                                    <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#52C41A" }} size="4x" className=" d-print-none" />
                                    <h1> {`${isPaymentReceiptComponent ? orderedPage  : `Payment`} successful`} </h1>
                                </>
                            }
                            <p>Gila LLC / MSB Receipt</p>

                            <ButtonToolbar className="d-print-none">
                                <Button variant="outline-secondary" onClick={() => handlePrintDIV()}>
                                    <FontAwesomeIcon icon={faPrint} />
                                </Button>
                                <Button variant="outline-secondary" onClick={() => setShowEmailModal(true)}>
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </Button>
                            </ButtonToolbar>
                        </div>
                    </div>
                </PageSectionContainer>

                <div className="mb-4" />

                <PageSectionContainer>
                    <div id="receiptSectionContainer" className="container-receipt-details" ref={elementRef}>
                        <div className="receipt-body" style={{marginLeft: '20px'}}>
                            <Editor cellPlugins={cellPlugins} value={value} readOnly={true} />
                        </div>
                    </div>
                </PageSectionContainer>

                <div className="mb-4 d-print-none" />
                
                <PageSectionContainer>
                    <div className="container-receipt-details d-print-none">
                        <div className={` ${ printReceipt ? `print-receipt-buttons` : 'receipt-buttons'}`}>
                            {!printReceipt &&
                                <ButtonToolbar>
                                    <Button variant="primary"
                                        onClick={handleMakeAnotherPayment}>
                                        {`${isPaymentReceiptComponent ? orderedPage : `Make`} another Payment`}
                                    </Button>
                                </ButtonToolbar>
                            }
                            <ButtonToolbar >
                                <Button variant="outline-secondary" onClick={() => handlePrintDIV()}>Print Receipt</Button>
                                <Button variant="outline-secondary" onClick={() => setShowEmailModal(true)}>Email Receipt</Button>
                            </ButtonToolbar>
                        </div>
                    </div>
                </PageSectionContainer>
                

                <div className="mb-4 d-print-none" />

                {navigator.usb ?
                    <PageSectionContainer>
                        <div className="container-receipt-details d-print-none">
                            <RowLayout className="align-items-center">
                                <Col md="auto">
                                    <Button variant="secondary" onClick={handleLocalThermalPrinter}>Print</Button>
                                </Col>
                                <Col>
                                    <i>Use this button only if a <b>MSB Local USB Thermal Printer</b> is directly attached
                                        <Form.Check type="checkbox" label="Enable automatic printing for the duration of the current session" checked={autoPrint === 'true'} onChange={(e) => setAutoPrint((e.target.checked) ? 'true' : 'false')} />
                                    </i>
                                </Col>
                            </RowLayout>
                        </div>
                    </PageSectionContainer>
                    :
                    ""
                }

                <div className="mb-4 d-print-none" />

                <TriPOSPrinter
                    client={client}
                    department={department}
                    paymentChannel={paymentChannel}
                    orderReceipt={checkReceiptData}
                />
            </>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        client: state.clients.client,
        department: state.clients.department,
        paymentChannel: state.clients.paymentChannel,
        processedOrder: state.orderManagement.processedOrder,
        content: state.cms.content,
        emailIsSending: state.emails.isFetching,
        emailActionResult: state.emails.actionResult
    };
};

export default connect(mapStateToProps)(Receipt);


