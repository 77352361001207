import React, { useEffect, useState } from "react";
import { ButtonToolbar, Button, Dropdown, Modal, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import { connect, useDispatch } from "react-redux";
import { IActionResult, IAppState } from '../../../../redux/storeTypes';
import { ADD_PAYMENTCHANNEL_REQUEST, ADD_PAYMENTCHANNEL_SUCCESS, ADD_PAYMENTCHANNEL_FAILURE, SAVE_PAYMENTCHANNEL_REQUEST ,SAVE_PAYMENTCHANNEL_SUCCESS, SAVE_PAYMENTCHANNEL_FAILURE, DELETE_PAYMENTCHANNEL_REQUEST, DELETE_PAYMENTCHANNEL_SUCCESS, DELETE_PAYMENTCHANNEL_FAILURE } from '../../../../redux/actions/clients/paymentChannels';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { PaymentChannelTypeEnum, Department, PaymentChannel, IntegrationType, Client } from '../../../../models/Client';
import { Routes } from '../../../../routes'
import { Redirect } from 'react-router-dom';
import FormHeader from '../forms/FormHeader';
import DepartmentPaymentChannelsForm from "../forms/department/DepartmentPaymentChannelsForm";
import { deletePaymentChannelAction } from '../../../../redux/actions/clients/paymentChannels';
import { WebApplication } from '../../../../models/PaymentChannelWebApplication';
import Table from '../../../../components/Table';
import { DateFormatter } from '../../../../components/Formatters';
import { getWebApplicationForPaymentChannelAction } from "../../../../redux/actions/clients/paymentChannelWebApplications";
import FormHeaderConfirmation from "../../../../components/FormHeaderConfirmation";
//@ts-ignore
import icoWarning from "../../../../assets/img/icons/ico-warning-outline.svg";
import PermissionModule, { Permissions } from '../../../../components/usePermissions';
import DepartmentProductCatalogForm from "../forms/department/DepartmentProductCatalogForm";
import DepartmentSupplementalFieldsForm from "../forms/department/DepartmentSupplementalFieldsForm";
import DepartmentPayPalForm from "../forms/department/DepartmentPayPalForm";
import DepartmentConvenienceFeesForm from "../forms/department/DepartmentConvenienceFeesForm";
import DepartmentAutoReceiptsForm from "../forms/department/DepartmentAutoReceiptsForm";
import * as _ from "lodash";
import DepartmentAuthorizeNetForm from "../forms/department/DepartmentAuthorizeNetForm";

export interface IDeptManagementSectionProps {
    client: Client,
    department: Department,
    isFetching: boolean,
    isSaving: boolean,
    actionResult?: IActionResult,
    webApplication: WebApplication
}

const DepartmentPaymentChannelsSection = ({ client, department, isFetching, isSaving, actionResult, webApplication }: IDeptManagementSectionProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const [paymentChannelMsbId, setPaymentChannelMsbId] = useState("");
    const [showPaymentChannelForm, setShowPaymentChannelForm] = useState(false);
    const [showProductCatalogForm, setShowProductCatalogForm] = useState(false);
    const [showSupplementalFieldForm, setShowSupplementalFieldForm] = useState(false);
    const [showPayPalForm, setShowPayPalForm] = useState(false);
    const [showAuthorizeNetForm, setShowAuthorizeNetForm] = useState(false);
    const [showConvenienceFeesForm, setShowConvenienceFeesForm] = useState(false);
    const [showAutoReceiptsForm, setShowAutoReceiptsForm] = useState(false);
    const [showModal, setModal] = useState(false); 
    const actionToken = "DepartmentPaymentChannelsSection";

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === ADD_PAYMENTCHANNEL_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === ADD_PAYMENTCHANNEL_SUCCESS) {
                    setShowPaymentChannelForm(false)
                    dispatch(sendSuccessToastAction("Payment Channel has been successfully added."));
                } else if (actionResult.result === ADD_PAYMENTCHANNEL_FAILURE) {
                    dispatch(sendErrorToastAction("Payment Channel could not be added."));
                }
            }

            if (actionResult.type === SAVE_PAYMENTCHANNEL_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === SAVE_PAYMENTCHANNEL_SUCCESS) {
                    setShowPaymentChannelForm(false)
                    dispatch(sendSuccessToastAction("Payment Channel has been successfully updated."));
                } else if (actionResult.result === SAVE_PAYMENTCHANNEL_FAILURE) {
                    dispatch(sendErrorToastAction("Payment Channel could not be updated."));
                }
            }

            if (actionResult.type === DELETE_PAYMENTCHANNEL_REQUEST && actionResult.token === actionToken) {
                if (actionResult.result === DELETE_PAYMENTCHANNEL_SUCCESS) {
                    dispatch(sendSuccessToastAction("Payment Channel has been successfully deleted."));
                } else if (actionResult.result === DELETE_PAYMENTCHANNEL_FAILURE) {
                    dispatch(sendErrorToastAction("Payment Channel could not be deleted."));
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionResult]);  

    const paymentChannelTypeFormatter = (cell: any, _row: any) => {
        return _.startCase(_.camelCase(cell));
    }

    const cellRouteFormatter = (cell: any, _row: any) => {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a tabIndex={0} onClick={() => {
                if (_row.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.Web]) {
                    setActionOperations(cell, _row, "Redirect");
                } else {
                    setPaymentChannelMsbId(_row.msbId);
                    setShowPaymentChannelForm(true);
                }
            }}>{_row.name}</a>
        );
    }

    const paymentChannelIsActiveFormatter = (_cell: any, _row: any) => {
        if (_row.isActive) return (<span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: '#52C41A' }} />Active</span>);
        else return (<span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: '#D9D9D9' }} />In Active</span>);
    }

    const setActionOperations = (cell: any, _row: any, actionOperations: any) => {
        if (actionOperations === "Delete") {
            setPaymentChannelMsbId(cell);
            setModal(true);
        }
        else if (actionOperations === "Redirect") {
            dispatch(getWebApplicationForPaymentChannelAction(_row.msbId, actionToken));
        }
    }

    const renderWebEditorDropdown = (cell: any, paymentChannel: PaymentChannel) => {
        if (paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.Web]) {
            return (
                <PermissionModule
                    permission=
                    {[
                        Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                    ]}
                >
                    <Dropdown.Item onSelect={() => { setActionOperations(cell, paymentChannel, "Redirect"); }}>Web Editor</Dropdown.Item>
                </PermissionModule>
            )
        } else {
            return (<></>);
        }
    }

    const renderManageProductCatalogDropdown = (cell: any, paymentChannel: PaymentChannel) => {
        if (paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.PointOfSale] && paymentChannel.integrationType === IntegrationType[IntegrationType.None] && paymentChannel.requiresReferenceNumber) {
            return (
                <PermissionModule
                    permission=
                    {[
                        Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                    ]}
                >
                    <Dropdown.Item onSelect={() => { setPaymentChannelMsbId(cell); setShowProductCatalogForm(true); }}>Manage Product Catalog</Dropdown.Item>
                </PermissionModule>
            )
        } else {
            return (<></>);
        }
    }

    const renderSupplementalDropdown = (cell: any, paymentChannel: PaymentChannel) => {
        if ((paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.PointOfSale] || paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.QuickPay] || paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.TerminalPay]) && paymentChannel.integrationType === IntegrationType[IntegrationType.None]) {
            return (
                <PermissionModule
                    permission=
                    {[
                        Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                    ]}
                >
                    <Dropdown.Item onSelect={() => { setPaymentChannelMsbId(cell); setShowSupplementalFieldForm(true); }}>Manage Supplemental Fields</Dropdown.Item>
                </PermissionModule>
            )
        } else {
            return (<></>);
        }
    } 

    const renderPayPalDropdown = (cell: any, paymentChannel: PaymentChannel) => {
        if (paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.Web] && paymentChannel.payPalPaymentsEnabled) {
            return (
                <PermissionModule
                    permission=
                    {[
                        Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                    ]}
                >
                    <Dropdown.Item onSelect={() => { setPaymentChannelMsbId(cell); setShowPayPalForm(true); }}>Manage PayPal</Dropdown.Item>
                </PermissionModule>
            )
        } else {
            return (<></>);
        }
    }

    const renderAuthorizeNetDropdown = (cell: any, paymentChannel: PaymentChannel) => {
        if (paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.ThirdParty]) {
            return (
                <PermissionModule
                    permission=
                    {[
                        Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                    ]}
                >
                    <Dropdown.Item onSelect={() => { setPaymentChannelMsbId(cell); setShowAuthorizeNetForm(true); }}>Manage Authorize.Net</Dropdown.Item>
                </PermissionModule>
            )
        } else {
            return (<></>);
        }
    }

    const renderConvenienceFeesDropdown = (cell: any, paymentChannel: PaymentChannel) => {
        if (paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.Web] || paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.PointOfSale] || paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.WebAPI] || paymentChannel.paymentChannelType === PaymentChannelTypeEnum[PaymentChannelTypeEnum.ThirdParty]) {
            return (
                <PermissionModule
                    permission=
                    {[
                        Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                    ]}
                >
                    <Dropdown.Item onSelect={() => { setPaymentChannelMsbId(cell); setShowConvenienceFeesForm(true); }}>Manage Convenience Fees</Dropdown.Item>
                </PermissionModule>
            )
        } else {
            return (<></>);
        }
    }  
    const renderManageAutoReceiptsDropdown = (cell: any, paymentChannel: PaymentChannel) => {
        if (paymentChannel.enableAutoReceipts) {
            return (
                <PermissionModule
                    permission=
                    {[
                        Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                    ]}
                >
                    <Dropdown.Item onSelect={() => { setPaymentChannelMsbId(cell); setShowAutoReceiptsForm(true); }}>Manage Auto Receipts</Dropdown.Item>
                </PermissionModule>
            )
        } else {
            return (<></>);
        }
    }

    useEffect(() => {
        if (webApplication != null) {
            setRedirect(Routes.WebPaymentChannel.path);
        }
    }, [webApplication]);   

    const actionsFormatter = (cell: any, _row: any) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="link" id="manage-paymentchannel-dropdown">Manage</Dropdown.Toggle>
                <Dropdown.Menu>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <Dropdown.Item onSelect={() => { setPaymentChannelMsbId(cell); setShowPaymentChannelForm(true); }}>Edit</Dropdown.Item>
                    </PermissionModule>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsDelete
                        ]}
                    >
                        <Dropdown.Item onSelect={() => { setActionOperations(cell, _row, "Delete"); }}>Delete</Dropdown.Item>
                    </PermissionModule>
                    {renderManageProductCatalogDropdown(cell, _row)}
                    {renderManageAutoReceiptsDropdown(cell, _row)}
                    {renderSupplementalDropdown(cell, _row)}
                    {renderPayPalDropdown(cell, _row)}
                    {renderAuthorizeNetDropdown(cell, _row)}
                    {renderConvenienceFeesDropdown(cell, _row)}
                    {renderWebEditorDropdown(cell, _row)}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    function doToolbar() {
        return (
            <PermissionModule
                permission=
                {[
                    Permissions.ManageClientsDepartmentsPaymentChannelsCreate
                ]}
            >
                <ButtonToolbar>
                    <Button onClick={() => { setPaymentChannelMsbId(""); setShowPaymentChannelForm(true) }}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Payment Channel
                    </Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Payment Channel',
            editable: false,
            width:'200',
            formatter: cellRouteFormatter
        }, {
            dataField: 'paymentChannelType',
            text: 'Type',
            editable: false,
            formatter: paymentChannelTypeFormatter
        }, {
            dataField: 'isActive',
            text: 'Status',
            editable: false,
            formatter: paymentChannelIsActiveFormatter
        },{
            dataField: 'createdAt',
            text: 'Date added',
            editable: false,
            formatter: DateFormatter
        }, {
            dataField: 'updatedAt',
            text: 'Last updated',
            editable: false,
            formatter: DateFormatter
        }, {
        dataField: 'msbId',
        text: 'Actions',
        editable: false,
        formatter: actionsFormatter
    }];

    const deletePaymentChannelConfirmAction = (event: any, deletePaymentChannelMSBId: string) => {
        event.preventDefault();
        setModal(false);
        dispatch(deletePaymentChannelAction(deletePaymentChannelMSBId, actionToken));
    }

    const renderSpinner = (isSaving:boolean) => {
        if (isSaving) {
            return(<Spinner as="span" animation="border" size="sm" className={"button-spinner"} />);
        } else {
            return(<></>);
        }
    }
    
    const handleCancelRedirectClick =() =>{
       setRedirect(Routes.Clients.path);
    }

    if (redirect) {
        return <Redirect push to={redirect} />;
    } else {
        return (
            <>
                <Modal size="lg" show={showPaymentChannelForm} onHide={() => setShowPaymentChannelForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body  className="add-entry">
                      <h2>{paymentChannelMsbId?"Edit":"Add"} Payment Channel</h2>
                      <p>Please enter the required fields to setup a Payment Channel. If your selected Payment Channel requires a Printer and/or Terminal, you will have the ability to complete the setup process for those devices.</p>
                        <DepartmentPaymentChannelsForm
                            client={client}
                            department={department}
                            paymentChannelMsbId={paymentChannelMsbId}
                            actionToken={actionToken}
                            // isFetching={isFetching} 
                            // isSaving={isSaving}
                             formActionProps={{
                                showSpinner: isSaving,
                                disabled: isSaving,
                                hasSubmit: true,
                                onCancel: () => setShowPaymentChannelForm(false),
                                isEditMode: paymentChannelMsbId ? true : false,
                                customProps: {
                                    submitButtonDisplay: paymentChannelMsbId ? "Save" : "Add"
                                }                            
                            }}
                        />
                    </Modal.Body>
                </Modal>

                <Modal show={showModal} className="modal-confirmation">
                    <Modal.Body>
                        <FormHeaderConfirmation iconImg={icoWarning} title="Delete payment channel" />
                        <div className="confirmation-body">
                            <p>Are you sure you want to delete this payment channel?</p>
                            <Form.Group className="confirmation-footer">
                                <Button variant="outline-secondary" onClick={() => setModal(false)}>
                                    Cancel
                                </Button>
                                <Button onClick={(e) => deletePaymentChannelConfirmAction(e, paymentChannelMsbId.toString())}>
                                    Confirm
                                </Button>
                            </Form.Group>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal size="lg" show={showProductCatalogForm} onHide={() => setShowProductCatalogForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body className="add-entry">
                        <h2>Manage Product Catalog</h2>
                        <DepartmentProductCatalogForm
                            department={department}
                            paymentChannelMsbId={paymentChannelMsbId}
                        />
                    </Modal.Body>
                </Modal>

                <Modal size="lg" show={showSupplementalFieldForm} onHide={() => setShowSupplementalFieldForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body className="add-entry">
                        <h2>Manage Supplemental Fields</h2>
                        <DepartmentSupplementalFieldsForm
                            paymentChannelMsbId={paymentChannelMsbId}
                            onClose={() => setShowSupplementalFieldForm(false)}
                        />
                    </Modal.Body>
                </Modal>

                <Modal size="lg" show={showPayPalForm} onHide={() => setShowPayPalForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body className="add-entry">
                        <h2>Manage PayPal</h2>
                        <DepartmentPayPalForm
                            department={department}
                            paymentChannelMsbId={paymentChannelMsbId}
                            onClose={() => setShowPayPalForm(false)}
                        />
                    </Modal.Body>
                </Modal>

                <Modal size="lg" show={showAuthorizeNetForm} onHide={() => setShowAuthorizeNetForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body className="add-entry">
                        <h2>Manage Authorize.Net</h2>
                        <DepartmentAuthorizeNetForm
                            department={department}
                            paymentChannelMsbId={paymentChannelMsbId}
                            onClose={() => setShowAuthorizeNetForm(false)}
                        />
                    </Modal.Body>
                </Modal>

                <Modal size="lg" show={showConvenienceFeesForm} onHide={() => setShowConvenienceFeesForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body className="add-entry">
                        <h2>Manage Convenience Fees</h2>
                        <DepartmentConvenienceFeesForm
                            paymentChannelMsbId={paymentChannelMsbId}
                            onClose={() => setShowConvenienceFeesForm(false)}
                        />
                    </Modal.Body>
                </Modal>

                <Modal size="lg" show={showAutoReceiptsForm} onHide={() => setShowAutoReceiptsForm(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body className="add-entry">
                        <h2>Manage Auto Receipts</h2>
                        <DepartmentAutoReceiptsForm
                            department={department}
                            paymentChannelMsbId={paymentChannelMsbId}
                        />
                    </Modal.Body>
                </Modal>

                <FormHeader title="Payment Channels" description="Below are the Payment Channels the department requires." />

                <div className="manage-card">
                    <PageSectionContainer title="Manage Payment Channels" toolbar={doToolbar()}>
                        <Table 
                            keyField="id" 
                            columns={columns} 
                            data={department.paymentChannels}
                        />
                    </PageSectionContainer>
                </div>

                <div className="manage-footer">
                    <Button type="button" variant="link" onClick={() => handleCancelRedirectClick()} style={{ float: "right" }}>Cancel</Button>                
                </div>

            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.clients.isFetching,
        isSaving: state.clients.isSaving,
        actionResult: state.clients.actionResult,
        client: state.clients.client,
        department: state.clients.department,
        webApplication: state.paymentChannelWebApplication.webApplication,
    };
};

export default connect(mapStateToProps)(DepartmentPaymentChannelsSection);
