import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Card, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';

import { Routes } from '../../../routes';
import Table from '../../../components/Table';
import { CurrencyFormatter, DateFormatter } from '../../../components/Formatters';
import { IPageableResults } from '../../../models/PageableResults';
import { PaymentTransactions, PaymentTypeEnum, SearchList, TransactionTypeEnum } from '../../../models/Payment';
import { ReportType } from '../../../models/Reports';
import { IAppState } from '../../../redux/storeTypes';
import { getTransactionsAction } from '../../../redux/actions/payments/paymentTransactions';
import TransactionDetailModal from './TransactionDetailModal';
import { Crumb } from '../../../models/Crumb';
import PageHeader from '../../../components/layout/PageHeader';
import PageSectionContainer from '../../../components/layout/PageSectionContainer';
import { User } from '../../../models/User';

export interface IReconTransactionsProps {
    startDate: string,
    endDate: string,
    clientName: string,
    currentUser?: User,
    departmentName: string,
    paymentChannelName: string,
    paymentChannelId: string,
    transactionType: TransactionTypeEnum,
    paymentType: PaymentTypeEnum,
    isFetchingTransactions: boolean,
    transactions: IPageableResults<PaymentTransactions>
}

const ReconciliationTransactions = ({ 
    clientName, departmentName, currentUser, paymentChannelName, paymentChannelId, startDate, endDate, transactionType, paymentType, isFetchingTransactions, transactions
} : IReconTransactionsProps) => {
    const dispatch = useDispatch();
    const actionToken = "ReconciliationTransactions";

    const [orderId, setOrderId] = useState("");
    const [orderMsbId, setOrderMsbId] = useState("");
    
    useEffect(() => {
        var searchList = new SearchList();

        searchList.paymentChannelId = paymentChannelId;
        searchList.createdAt = `${startDate}T00:00:00.000<->${endDate}T23:59:59.999`;
        if (transactionType === TransactionTypeEnum.Sale && !!paymentType) {
            searchList.paymentType = paymentType === PaymentTypeEnum.ECheck ? "ECheck" : "CreditCard";
        }

        if (transactionType === TransactionTypeEnum.Refund) {
            // Include Returns for Refund transaction types. Business requirement.
            dispatch(getTransactionsAction(1, searchList, actionToken, currentUser, transactionType, ReportType.Payment, '', TransactionTypeEnum.Return));
        } else {
            dispatch(getTransactionsAction(1, searchList, actionToken, currentUser, transactionType, ReportType.Payment));
        }
    }, []);

    const columns = [
        {
            dataField: 'orderIdentifier',
            text: 'Transaction ID',
            editable: false
        },
        {
            dataField: 'transactionDate',
            text: 'Transaction date',
            editable: false,
            sort: true,
            formatter: DateFormatter
        },
        {
            dataField: 'nameOnCard',
            text: 'Customer name',
            editable: false
        }, 
        {
            dataField: 'amount',
            text: 'Subtotal',
            editable: false,
            formatter: CurrencyFormatter
        }, 
        {
            dataField: 'convenienceFee',
            text: 'Convenience fee',
            editable: false,
            formatter: CurrencyFormatter
        }, 
        {
            dataField: 'totalAmount',
            text: 'Total amount',
            editable: false,
            formatter: CurrencyFormatter
        }, 
        {
            dataField: 'transactionReference',
            text: 'Actions',
            editable: false,
            formatter: (_c: string, pt: PaymentTransactions) => (<a onClick={() =>{setOrderId(pt.orderIdentifier); setOrderMsbId(pt.msbId || "")}}>View Details</a>)
        }
    ];

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Reconciliations Report", Routes.ReconciliationReport.path));
    crumbs.push(new Crumb(`${clientName} ${departmentName} Details`, Routes.ReconciliationReportDetails.path));
    crumbs.push(new Crumb(`${clientName} ${departmentName} ${paymentChannelName} Transactions`, Routes.ReconciliationReport.path)); 

    return (
        <>
            <PageHeader title={`${clientName} ${departmentName} ${paymentChannelName} Transactions`} crumbs={crumbs} />

            <Modal show={!!orderId && !!orderMsbId} onHide={() =>{setOrderId(""); setOrderMsbId("")}}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <TransactionDetailModal orderId={orderId} orderMsbId={orderMsbId} />
                    <div className="form-footer justify-content-start">
                      <Button variant="primary" onClick={() =>{setOrderId(""); setOrderMsbId("")}}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Container fluid className="container-payment-details">
              <Row>
                <Col>
                  <PageSectionContainer>
                    <div className="container-transaction-details">
                      <div className="transactionTitle">
                        <h2>{`${clientName} ${departmentName} ${paymentChannelName} Transactions`}</h2>
                        <p>Below are the reconciliation transaction details.</p>
                      </div>

                      <div className="manage-card">
                        <Card className="cf-card">
                          <Card.Header>
                            <h2>Transactions</h2>
                          </Card.Header>
                          <Card.Body>
                            {
                              (isFetchingTransactions) ? (
                                <Spinner animation="border" />
                              ) : (
                                <Table keyField="paymentChannelName" columns={columns} data={transactions?.data || []} sizePerPage={(transactions?.data || []).length} totalSize={(transactions?.data || []).length} />
                              )
                            }
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </PageSectionContainer>
                </Col>
              </Row>
            </Container>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        startDate: state.reports.searchState.startDate,
        endDate: state.reports.searchState.endDate,
        clientName: state.reports.searchState.clientName,
        currentUser: state.auth.currentUser,
        departmentName: state.reports.searchState.departmentName,
        paymentChannelName: state.reports.searchState.paymentChannelName,
        paymentChannelId: state.reports.searchState.paymentChannelId,
        transactionType: state.reports.searchState.transactionType,
        paymentType: state.reports.searchState.paymentType,
        isFetchingTransactions: state.paymentTransactions.isFetching,
        transactions: state.paymentTransactions.currentPage,
    };
};

export default connect(mapStateToProps)(ReconciliationTransactions);