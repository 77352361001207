import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { Container, Col, Row, Form, Button, Dropdown, ButtonToolbar, Image, Modal } from 'react-bootstrap';
import { IActionResult, IAppState } from '../../redux/storeTypes';
import PageHeader from '../../components/layout/PageHeader';
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import { DateFormatter } from '../../components/Formatters';
import { getClientsAction, getClientAction, GET_CLIENT_REQUEST, GET_CLIENT_SUCCESS, deleteClientAction, DELETE_CLIENT_REQUEST, DELETE_CLIENT_SUCCESS } from '../../redux/actions/clients/clients';
import { Routes } from "../../routes";
import { Redirect } from 'react-router-dom';
import { Client, ClientStatusEnum } from '../../models/Client';
import uuid from 'react-uuid';
import 'react-dropdown/style.css';
import Departments from './Departments';
import icoExpanded from "../../assets/img/icons/ico-PlusSquare.svg";
import icoCollapsed from "../../assets/img/icons/ico-MinusSquare.svg";
import FormHeaderConfirmation from "../../components/FormHeaderConfirmation";
import icoWarning from "../../assets/img/icons/ico-warning-outline.svg";
import { sendErrorToastAction, sendSuccessToastAction } from "../../redux/actions/toast";
import PermissionModule, { Permissions } from '../../components/usePermissions';
import { Crumb } from "../../models/Crumb";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BasicTableWithProps from "../../components/TableProviderKit";

interface ClientsProps {
    currentPage: any,
    isFetching: boolean,
    actionResult: IActionResult
}

const Clients = ({ currentPage, isFetching, actionResult }: ClientsProps) => {
    const [redirect, setRedirect] = useState<string>("");
    const [_actionToken, setActionToken] = useState(uuid());
    const [clientToDelete, setClientToDelete] = useState<Client>();
    const dispatch = useDispatch();
    const actionToken = "Clients";
    const expandClientActionToken = "ExpandClient";
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [searchBusinessName, setSearchBusinessName] = useState<string>("");
    const [searchStatusEnum, setSearchStatusEnum] = useState<string>("");

    const [sizePerPage, setSizePerPage] = useState<number>(currentPage?.total ? currentPage.total : 10);
    const [page, setPage] = useState<number>(currentPage?.page ? currentPage.page : 1);
    const [data, setData] = useState<Array<any>>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);

    useEffect(() => {
        if (currentPage != null) {
            setData(currentPage.data);
            setTotalRecords(currentPage.totalRecords);
        }
    }, [currentPage]);

    useEffect(() => {
        dispatch(getClientsAction(page, sizePerPage, searchBusinessName, searchStatusEnum, _actionToken));
    }, [page, sizePerPage, searchBusinessName, searchStatusEnum]);

    const expandRow = {
        showExpandColumn: true,
        expandByColumnOnly: true,
        onlyOneExpanding: true,
        expandColumnRenderer: ({ expanded, rowKey, expandable }: any) => {
            if (expanded) {
                dispatch(getClientAction(rowKey, expandClientActionToken));
                return (
                    <Image src={icoCollapsed} alt="Collapse icon" />
                );
            } else {
                return (
                    <Image src={icoExpanded} alt="Expand icon" />
                );
            }
        },
        renderer: (row: any) => (
            <div className="departments-expanded">
                <Departments client={row} sizePerPage={10} actionResult={actionResult} mode={'tableView'} />
            </div>
        ),
    }

    const actionsFormatter = (cell: any, client: Client) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="link" id="manage-client-dropdown">Manage</Dropdown.Toggle>
                <Dropdown.Menu>
                    <PermissionModule
                        permission={[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <Dropdown.Item
                            onSelect={() => {
                                dispatch(getClientAction(client.msbId!, actionToken));
                            }}
                        >
                            Manage Client
                        </Dropdown.Item>
                    </PermissionModule>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsDelete
                        ]}
                    >
                        <Dropdown.Item onSelect={() => doDeleteClient(client)}>
                            Delete Client
                        </Dropdown.Item>
                    </PermissionModule>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const cellRouteFormatter = (cell: any, client: Client) => {
        return (
            <a tabIndex={0} onClick={() => {
                dispatch(getClientAction(client.msbId!, actionToken));
                //setClient(client);
            }}>{cell}</a>
        );
    }

    const stateFormatter = (cell: any, row: any) => {
        const stateAddress = (row?.clientAddresses ?? []).find((address: any) => address?.addressType === "Physical")?.state as any;
        return (
            stateAddress && <span>{stateAddress}</span>
        )
    }

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === GET_CLIENT_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === GET_CLIENT_SUCCESS) {
                setRedirect(Routes.Client.path);
            }
        }
        if (actionResult && actionResult.result && actionResult.type === DELETE_CLIENT_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === DELETE_CLIENT_SUCCESS) {
                dispatch(getClientsAction(currentPage.page, sizePerPage, searchBusinessName, searchStatusEnum, _actionToken));
                setShowConfirmationModal(false);
                dispatch(sendSuccessToastAction("Client was successfully deleted."));
            } else {
                dispatch(sendErrorToastAction("Client could not deleted."));
            }
        }
    }, [actionResult]);

    const cellStatusFormatter = (cell: number, row: Client) => {
        let value = "Unknown";
        let color: string = "#D9D9D9";

        if (row.onBoardingStatus == ClientStatusEnum[ClientStatusEnum.Initialized]) {
            value = "Initial setup";
            color = "#FADB14";
        } else if (row.onBoardingStatus == ClientStatusEnum[ClientStatusEnum.ApprovalPending]) {
            value = "Setup approval pending";
            color = "#FAAD14";
        } else if (row.onBoardingStatus == ClientStatusEnum[ClientStatusEnum.ApprovalRejected]) {
            value = "Setup approval rejected";
            color = "#F5222D";
        } else if (row.onBoardingStatus == ClientStatusEnum[ClientStatusEnum.Complete]) {
            value = "Complete";
            color = "#52C41A";
        } else {
            value = "Setup in progress";
            color = "#0057B6";
        }

        return (
            <span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: `${color}` }} />{value}</span>
        );
    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        }, {
            dataField: 'businessName',
            text: 'Client',
            editable: false,
            formatter: cellRouteFormatter
        }, {
            dataField: 'state',
            isDummyField: true,
            text: 'State',
            editable: false,
            formatter: stateFormatter
        }, {
            dataField: 'updatedAt',
            text: 'Last updated',
            formatter: DateFormatter,
            editable: false
        }, {
            dataField: 'statusEnum',
            text: 'Status',
            formatter: cellStatusFormatter,
            editable: false
        }, {
            dataField: 'id',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter,
        }
    ];

    const doDeleteClient = (client: Client) => {
        setClientToDelete(client);
        setShowConfirmationModal(true);
    }

    const deleteClient = (e: any) => {
        dispatch(deleteClientAction(clientToDelete!.msbId!, actionToken))
    }

    const renderConfirmationModal = () => (
        <Modal show={showConfirmationModal} className="modal-confirmation">
            <Modal.Body>
                <FormHeaderConfirmation iconImg={icoWarning} title="Delete client" />
                <div className="confirmation-body">
                    <p>Are you sure you want to delete this client?</p>
                    <Form.Group className="confirmation-footer">
                        <Button variant="outline-secondary" onClick={() => setShowConfirmationModal(false)}>
                            Cancel
                        </Button>
                        <Button onClick={(e) => deleteClient(e)}>
                            Confirm
                        </Button>
                    </Form.Group>
                </div>
            </Modal.Body>
        </Modal>
    );

    function doToolbar() {
        return (
            <PermissionModule
                permission=
                {[
                    Permissions.ManageClientsDepartmentsPaymentChannelsCreate
                ]}
            >
                <ButtonToolbar>
                    <Button onClick={() => { /*dispatch(cancelAction());*/ setRedirect(Routes.ClientAdd.path) }}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Client</Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }


    const SearchFilter = (props: any) => {
        let input: any;
        const handleSubmit = (event: any) => {
            event.preventDefault();
            props.onSearch(input.value);
        };
        const handleReset = () => {
            props.onSearch(input.value = '');
        }
        return (
            <Form onSubmit={handleSubmit} className="table-search">
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12}>
                        <Form.Group>
                            <Form.Label>Client</Form.Label>
                            <Form.Control ref={(filterValue: any) => input = filterValue} type="text" placeholder="Enter client" />
                        </Form.Group>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12}>
                        <Form.Group controlId="statusEnum">
                            <Form.Label>Status</Form.Label>
                            <Form.Control as="select" placeholder="Select status">
                                <option value="">All</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xl={3} lg={12} md={12} sm={12}>
                        <ButtonToolbar>
                            <Button variant="outline-secondary" onClick={handleReset}>Reset</Button>
                            <Button onClick={handleSubmit}>Search</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>
            </Form>
        );
    };

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        var crumbs = new Array<Crumb>();
        crumbs.push(new Crumb("Clients", Routes.Clients.path));

        return (
            <>
                {renderConfirmationModal()}
                <PageHeader title="Clients" crumbs={crumbs} />
                <ToolkitProvider keyField="msbId" data={data} columns={columns} search>
                    {
                        (props: any) => (
                            <>
                                <Container fluid className="container-table-search">
                                    <Row>
                                        <Col>
                                            <PageSectionContainer>
                                                <SearchFilter {...props.searchProps} />
                                            </PageSectionContainer>
                                        </Col>
                                    </Row>
                                </Container>

                                <Container fluid>
                                    <Row>
                                        <Col>
                                            <PageSectionContainer title="Manage Clients" toolbar={doToolbar()}>
                                                <BasicTableWithProps
                                                    expandRow={expandRow}
                                                    {...props.baseProps}
                                                />
                                            </PageSectionContainer>
                                        </Col>
                                    </Row>
                                </Container>
                            </>
                        )
                    }
                </ToolkitProvider>
            </>
        );
    }
};

const mapStateToProps = (state: IAppState) => {
    return {
        currentPage: state.clients.currentPage,
        isFetching: state.clients.isFetching,
        actionResult: state.clients.actionResult
    };
};

export default connect(mapStateToProps)(Clients);