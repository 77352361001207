
import { OrderStatus, PaymentType, TransactionTypeEnum } from '../models/Payment';

export const dynamicQueryUrls = (baseApiUrl: any, urlSchema: string, reportType?: any, transactionType?: any, extraTransactionType?: any, searchValues?: any) => {

    const defaultUrl = "parentIsImmutable=false&transactionType:or=1|6|7&orderStatus=" + OrderStatus.Success;

    const paymentURL = (transactionType: any, extraTransactionType: any) => {
        const transactionTypes = [transactionType, 
            TransactionTypeEnum.AuthorizationCommit, 
            TransactionTypeEnum.ChargebackReversal, 
            extraTransactionType].filter(Boolean).join('|');
        
        return `orderStatus=Success&transactionType:or=${transactionTypes}`;
    };
    
    const allTransactionsURL = (searchValues: any) => {
        const modifiedURL = searchValues?.transactionType ? `orderStatus=${OrderStatus.Success}` :
            `transactionType:or=${[TransactionTypeEnum.Sale,
            TransactionTypeEnum.Void,
            TransactionTypeEnum.Refund,
            TransactionTypeEnum.Reversal,
            TransactionTypeEnum.Return,
            TransactionTypeEnum.AuthorizationCommit,
            TransactionTypeEnum.Chargeback,
            TransactionTypeEnum.ChargebackReversal].join('|')
            + "&orderStatus=" + OrderStatus.Success}`
        return modifiedURL
    }

    const achTimeZoneURL = () => {
        return `transactionType:or=${[TransactionTypeEnum.Sale, TransactionTypeEnum.Refund, TransactionTypeEnum.Return].join('|')}`
    }

    const parametersMap: any = {
        "Declined": `orderStatus=${OrderStatus.Declined}`,
        "Payment": paymentURL(transactionType, extraTransactionType),
        "Pending": `orderStatus=${OrderStatus.Pending}`,
        "Transactions": allTransactionsURL(searchValues),
        "Chargeback": `transactionType=${TransactionTypeEnum.Chargeback + "&orderStatus=" + OrderStatus.Success}`,
        "Refund": `transactionType:or=${[TransactionTypeEnum.Refund, TransactionTypeEnum.Return].join('|') + "&orderStatus=" + OrderStatus.Success}`,
        "Void": `transactionType:or=${[TransactionTypeEnum.Void, TransactionTypeEnum.Reversal, TransactionTypeEnum.Authorization, TransactionTypeEnum.AuthorizationCommit].join('|') + "&orderStatus=" + OrderStatus.Success}`,
        "Authorization": `transactionType:or=${[TransactionTypeEnum.Authorization, TransactionTypeEnum.AuthorizationCommit, TransactionTypeEnum.Reversal].join('|') + "&orderStatus=" + OrderStatus.Success}`,
        "ConvenienceFee": " ",
        "NoConvenienceFee": "convenienceFeeIsClientAbsorbed=false&convenienceFee=0.00",
        "FinanceACH": `${achTimeZoneURL() + '&paymentType=' + PaymentType.ECheck + '&transactionStatus=Approved'}`
    }

    const modifiedURL = transactionType === TransactionTypeEnum.ChargebackReversal ? `transactionType=${TransactionTypeEnum.Chargeback + "&orderStatus=" + OrderStatus.Success}` : defaultUrl;

    const formattedURL = parametersMap[reportType] ? `${parametersMap[reportType]}` : `${modifiedURL}`
    const constructedURL = new URL(baseApiUrl + urlSchema + formattedURL)

    return constructedURL
};