import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTachometerAlt, faDollarSign, faChartLine, faThLarge, faUsersCog, faAngleDown, faChartBar, faClipboardListCheck, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { Nav, Image, Button, Navbar, Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IAppState } from '../redux/storeTypes';
import { Routes } from "../routes";
import msbLogo from "../assets/img/msb-logo.svg";
import { User, UserTypeEnum } from "../models/User";
import { resetClientBankingDetails, resetClientsStoreAction } from "../redux/actions/clients/clients";
import { cancelFetchTransactions, resetPaymentStoreAction } from "../redux/actions/payments/paymentTransactions";
import { resetRolesStoreAction } from "../redux/actions/roles";
import { clearOrderAction } from "../redux/actions/payments/orderManagement";
import { Client, IntegrationType, PaymentChannelTypeEnum } from "../models/Client";
import PermissionModule, { Permissions } from '../components/usePermissions';
import { resetReportStoreAction } from "../redux/actions/reports/report";

interface SidebarProps {
    currentUser: User;
    client: Client;
}

const Sidebar = ({ currentUser, client }: SidebarProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { pathname } = location;
    const [show, setShow] = useState(false);
    const showClass = show ? "show" : "";
    const onCollapse = () => setShow(!show);

    const CollapsibleNavItem = (props: any) => {
        const { eventKey, title, icon, children = null } = props;
        const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

        return (
            <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
                <Accordion.Toggle as={Nav.Link} eventKey="0">
                    <span>
                        <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
                        <span className="sidebar-text">{title}</span>
                        <span className="sidebar-icon sidebar-icon-right"><FontAwesomeIcon icon={faAngleDown} /> </span>
                    </span>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="accordion-nav">
                    <Nav className="flex-column">
                        {children}
                    </Nav>
                </Accordion.Collapse>
            </Accordion>
        );
    };

    const handleOnClick = () => {
        setShow(false);
    }

    const handleClientsOnClick = () => {
        dispatch(resetClientsStoreAction("Sidebar"));
        setShow(false);
    }

    const handlePaymentsOnClick = () => {
        dispatch(cancelFetchTransactions());
        dispatch(clearOrderAction());
        dispatch(resetPaymentStoreAction("Sidebar"));
        setShow(false);
    }

    const handleCreateOrderOnClick = () => {
        dispatch(clearOrderAction());
        setShow(false);
    }

    const handleCustomerOnClick = () => {
        setShow(false);
    }

    const handleReportsOnClick = () => {
        dispatch(cancelFetchTransactions());
        dispatch(resetPaymentStoreAction("Sidebar"));
        dispatch(resetReportStoreAction("Sidebar"));
        dispatch(resetClientBankingDetails("Sidebar"))
        setShow(false);
    }

    const handleRolesOnClick = () => {
        dispatch(resetRolesStoreAction("Sidebar"));
        setShow(false);
    }

    const hasPaymentChannel = (paymentChannelTypeEnum: PaymentChannelTypeEnum) => {
        var hasPaymentChannel = false;
        if (client && currentUser.userTypeEnum === UserTypeEnum.Client) {
            client.departments.forEach(department => {
                if (department.paymentChannels?.find(pc => pc.paymentChannelType === PaymentChannelTypeEnum[paymentChannelTypeEnum] && pc.integrationType !== IntegrationType[IntegrationType.TylerEagle] && pc.isActive)) {
                    hasPaymentChannel = true;
                }
            });
        }
        return hasPaymentChannel;
    }

    const NavItem = (props: any) => {
        const { title, link, target, icon, image, badgeText } = props;
        const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
        const navItemClassName = link === pathname ? "active" : "";

        if (props.onClick) {
            return (
                <Nav.Item className={navItemClassName} onClick={props.onClick}>
                    <Nav.Link to={props.link} as={Link} target={target} className={classNames}>
                        <span>
                            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
                            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}

                            <span className="sidebar-text">{title}</span>
                        </span>
                    </Nav.Link>
                </Nav.Item>
            );
        } else {
            return (
                <Nav.Item className={navItemClassName} onClick={handleOnClick}>
                    <Nav.Link to={props.link} as={Link} target={target} className={classNames}>
                        <span>
                            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
                            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}
                            <span className="sidebar-text">{title}</span>
                        </span>
                    </Nav.Link>
                </Nav.Item>
            );
        }
    };

    const NavientNav = () => {
        return (
            <Nav className="flex-column pt-3 pt-md-0">
                {/* Dashboard */}
                <NavItem title="Dashboard" link={Routes.DashboardOverview.path} icon={faTachometerAlt} />

                {/* Clients */}
                <PermissionModule
                    permission=
                    {[
                        Permissions.ManageClientsDepartmentsPaymentChannelsCreate,
                        Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                        Permissions.ManageClientsDepartmentsPaymentChannelsUpdate,
                        Permissions.ManageClientsDepartmentsPaymentChannelsDelete
                    ]}
                >
                    <NavItem title="Clients" link={Routes.Clients.path} icon={faThLarge} onClick={handleClientsOnClick} />
                </PermissionModule>

                {/* Reports */}
                <CollapsibleNavItem eventKey="reports/" title="Reports" icon={faChartLine}>
                    <NavItem title="All Transactions" link={Routes.TransactionsReport.path} onClick={handleReportsOnClick} />
                    <NavItem title="Authorizations" link={Routes.AuthorizationsReport.path} onClick={handleReportsOnClick} />
                    <NavItem title="Chargebacks" link={Routes.ChargebacksReport.path} onClick={handleReportsOnClick} />
                    <NavItem title="Declined" link={Routes.DeclinedReport.path} onClick={handleReportsOnClick} />
                    <NavItem title="Funding" link={Routes.FundingReport.path} onClick={handleReportsOnClick} />
                    <NavItem title="Payments" link={Routes.PaymentsReport.path} onClick={handleReportsOnClick} />
                    <NavItem title="Pending Payments" link={Routes.PendingPaymentsReport.path} onClick={handleReportsOnClick} />
                    <NavItem title="Refunds" link={Routes.RefundsReport.path} onClick={handleReportsOnClick} />
                    <NavItem title="Voids" link={Routes.VoidsReport.path} onClick={handleReportsOnClick} />
                </CollapsibleNavItem>

                {/* Audit Reports */}
                <PermissionModule
                    permission={[Permissions.AuditReportsExecute]}
                >
                    <CollapsibleNavItem eventKey="audit/" title="Audit" icon={faClipboardListCheck}>
                        <NavItem title="Convenience Fee" link={Routes.ConvenienceFee.path} onClick={handleReportsOnClick}/>
                        <NavItem title="No Convenience Fee" link={Routes.NoConvenienceFee.path} onClick={handleReportsOnClick}/>
                        <NavItem title="Fee Audit" link={Routes.FeeAudit.path} onClick={handleReportsOnClick}/>
                        <NavItem title="Compliance Exceptions" link={Routes.ComplianceExceptions.path} onClick={handleReportsOnClick}/>
                    </CollapsibleNavItem>
                </PermissionModule>

                {/* Finance Reports */}
                <PermissionModule permission={[Permissions.FinanceReportsExecute]}>
                    <CollapsibleNavItem eventKey="finance/" title="Finance" icon={faChartBar}>
                        <PermissionModule permission={[Permissions.ChargebackExecute]}>
                            <>
                                <NavItem title="Create Chargeback" link={Routes.CreateChargeback.path} onClick={handlePaymentsOnClick} />
                                <NavItem title="Chargeback Reversal" link={Routes.ChargebackReversals.path} onClick={handlePaymentsOnClick} />
                            </>
                        </PermissionModule>
                        <NavItem title="eMAF" link={Routes.EMafReport.path} />
                        <NavItem title="Reconciliations" link={Routes.ReconciliationReport.path} />
                        <NavItem title="Worldpay Transactions" link={Routes.WorldpayTransactionsReport.path} />
                        <NavItem title="Invoice Transactions" link={Routes.InvoiceReport.path} onClick={handleReportsOnClick} />
                        <NavItem title="Fund By Timezone (ACH)" link={Routes.ACHTimezoneReport.path} onClick={handleReportsOnClick} />
                        <PermissionModule
                            permission=
                            {[
                                Permissions.ClientBankingCreate,
                                Permissions.ClientBankingRead,
                                Permissions.ClientBankingUpdate,
                                Permissions.ClientBankingDelete
                            ]}
                        >
                            <NavItem title="Client Banking" link={Routes.ClientBanking.path} onClick={handleReportsOnClick} />
                        </PermissionModule>
                    </CollapsibleNavItem>
                </PermissionModule>

                {/* Users Roles/Permissions */}
                <CollapsibleNavItem eventKey="access/" title="Users" icon={faUsersCog}>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageNavientInternalUserCreate,
                            Permissions.ManageNavientInternalUserRead,
                            Permissions.ManageNavientInternalUserUpdate,
                            Permissions.ManageNavientInternalUserDelete
                        ]}
                    >
                        <NavItem title="Navient Access" link={Routes.NavientUsers.path} />
                    </PermissionModule>
                    <NavItem title="Navient Roles" link={Routes.NavientRoles.path} onClick={handleRolesOnClick} />
                    <NavItem title="Client Roles" link={Routes.ClientRoles.path} onClick={handleRolesOnClick} />
                </CollapsibleNavItem>
            </Nav>
        )
    }

    const ClientNav = () => {
        return (
            <Nav className="flex-column pt-3 pt-md-0">
                {/* Dashboard */}
                <NavItem title="Dashboard" link={Routes.DashboardOverview.path} icon={faTachometerAlt} />

                {/* Payments */}
                <CollapsibleNavItem eventKey="payments/" title="Payments" icon={faDollarSign}>
                    {hasPaymentChannel(PaymentChannelTypeEnum.PointOfSale) ?
                        <PermissionModule permission={[Permissions.ProcessTransactionsExecute]}>
                            <NavItem title="Point of Sale" link={Routes.PointOfSale.path} onClick={handleCreateOrderOnClick} />
                        </PermissionModule>
                        : <></>}
                    {hasPaymentChannel(PaymentChannelTypeEnum.QuickPay) ?
                        <PermissionModule permission={[Permissions.ProcessTransactionsExecute]}>
                            <NavItem title="Quick Pay" link={Routes.QuickPay.path} onClick={handleCreateOrderOnClick} />
                        </PermissionModule>
                        : <></>}
                    {hasPaymentChannel(PaymentChannelTypeEnum.TerminalPay) ?
                        <PermissionModule permission={[Permissions.ProcessTransactionsExecute]}>
                            <NavItem title="Terminal Pay" link={Routes.TerminalPay.path} onClick={handleCreateOrderOnClick} />
                        </PermissionModule>
                        : <></>}
                    <PermissionModule permission={[Permissions.RefundAPaymentExecute]}>
                        <NavItem title="Refund Payment" link={Routes.RefundPayment.path} onClick={handlePaymentsOnClick} />
                    </PermissionModule>
                    <PermissionModule permission={[Permissions.VoidAPaymentExecute]}>
                        <NavItem title="Void Payment" link={Routes.VoidPayment.path} onClick={handlePaymentsOnClick} />
                    </PermissionModule>
                </CollapsibleNavItem>

                <PermissionModule permission={[Permissions.ManagePMoFExecute]}>
                    <CollapsibleNavItem eventKey="customer/" title="Customer" icon={faUsers}>
                        <NavItem title="Manage Payment Method on File" link={Routes.ManagePMoF.path} onClick={handleCustomerOnClick} />
                    </CollapsibleNavItem>
                </PermissionModule>

                {/* Reports */}
                <CollapsibleNavItem eventKey="reports/" title="Reports" icon={faChartLine}>
                    <PermissionModule permission={[Permissions.AllTransactionExecute]}>
                        <NavItem title="All Transactions" link={Routes.TransactionsReport.path} onClick={handleReportsOnClick} />
                    </PermissionModule>
                    <PermissionModule permission={[Permissions.AuthorizationsExecute]}>
                        <NavItem title="Authorizations" link={Routes.AuthorizationsReport.path} onClick={handleReportsOnClick} />
                    </PermissionModule>
                    <PermissionModule permission={[Permissions.ChargebacksExecute]}>
                        <NavItem title="Chargebacks" link={Routes.ChargebacksReport.path} onClick={handleReportsOnClick} />
                    </PermissionModule>
                    <PermissionModule permission={[Permissions.DeclinedExecute]}>
                        <NavItem title="Declined" link={Routes.DeclinedReport.path} onClick={handleReportsOnClick} />
                    </PermissionModule>
                    <PermissionModule permission={[Permissions.FundingExecute]}>
                        <NavItem title="Funding" link={Routes.FundingReport.path} onClick={handleReportsOnClick} />
                    </PermissionModule>
                    <PermissionModule permission={[Permissions.PaymentsExecute]}>
                        <NavItem title="Payments" link={Routes.PaymentsReport.path} onClick={handleReportsOnClick} />
                    </PermissionModule>
                    <PermissionModule permission={[Permissions.PendingPaymentsExecute]}>
                        <NavItem title="Pending Payments" link={Routes.PendingPaymentsReport.path} onClick={handleReportsOnClick} />
                    </PermissionModule>
                    <PermissionModule permission={[Permissions.RefundsExecute]}>
                        <NavItem title="Refunds" link={Routes.RefundsReport.path} onClick={handleReportsOnClick} />
                    </PermissionModule>
                    <PermissionModule permission={[Permissions.ShoppingCartExecute]}>
                        <NavItem title="Shopping Cart" link={Routes.ShoppingCartReport.path} onClick={handleReportsOnClick} />
                    </PermissionModule>
                    <PermissionModule permission={[Permissions.VoidsExecute]}>
                        <NavItem title="Voids" link={Routes.VoidsReport.path} onClick={handleReportsOnClick} />
                    </PermissionModule>
                </CollapsibleNavItem>

                {/* User Roles/Permissions */}
                <PermissionModule permission={[Permissions.ClientUserManagementExecute]}>
                    <CollapsibleNavItem eventKey="access/" title="Access" icon={faUsersCog}>
                        <NavItem title="Users" link={Routes.ClientUsers.path} />
                        <NavItem title="Roles" link={Routes.ClientRoles.path} />
                    </CollapsibleNavItem>
                </PermissionModule>
            </Nav>
        )
    }

    const EmptyNav = () => {
        return (
            <Nav className="flex-column pt-3 pt-md-0">
            </Nav>
        )
    }

    const SidebarNav = () => {
        if (currentUser.userTypeEnum === UserTypeEnum.Navient) {
            return (<NavientNav />);
        } else if (currentUser.userTypeEnum === UserTypeEnum.Client) {
            return (<ClientNav />);
        } else {
            return (<EmptyNav />);
        }
    }

    return (
        <>
            <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none d-print-none">
                <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
                    <Image src={msbLogo} className="navbar-brand-light" />
                </Navbar.Brand>
                <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
                    <span className="navbar-toggler-icon" />
                </Navbar.Toggle>
            </Navbar>
            <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-tertiary text-white d-print-none`}>
                    <div className="sidebar-inner">
                        <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="d-flex align-items-center">
                            </div>
                            <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Nav.Link>
                        </div>
                        <div className="d-sm-none d-md-block">
                            <Image src={msbLogo} className="mb-4" />
                        </div>
                        <SidebarNav />
                    </div>
                </SimpleBar>
            </CSSTransition>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        currentUser: state.auth.currentUser,
        client: state.clients.client
    };
};

export default connect(mapStateToProps)(Sidebar);