import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import { User, UserTypeEnum } from "../../../models/User";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { IAppState } from "../../../redux/storeTypes";
import { connect, useDispatch } from "react-redux";
import { setSelectedDepartmentPaymentChannels } from "../../../redux/actions/payments/paymentTransactions";

interface SelectorProps {
    reportType: any;
    currentUser: User;
    paymentChannelsByUser: any;
    clientNames: any;
    onSelectionChange: any;
    selectedDepartmentPaymentChannelItems?: any
}

const DepartmentPaymentChannelSelector = ({ reportType, currentUser, paymentChannelsByUser, clientNames, onSelectionChange, selectedDepartmentPaymentChannelItems }: SelectorProps) => {

    const dispatch = useDispatch();
    const [selectedItems, setSelectedItems] = useState(selectedDepartmentPaymentChannelItems || {
        clients: new Set(),
        departments: new Set(),
        paymentChannels: new Set(),
    });

    const buildOptions = (data: any, userType: any, clientForDepartments: any) => {
        if (userType === UserTypeEnum.Navient) {
            return data?.flatMap((client: any) => [
                { label: client.name, value: client.msbId, type: 'client' },
                ...client.departments
                    .filter((department: any) => Object.keys(department.paymentChannels).length > 0)
                    .flatMap((department: any) => [
                        { label: `${department.name}`, value: department.msbId, type: 'department', clientId: client.msbId },
                        ...Object.entries(department.paymentChannels).map(([id, name]) => ({
                            label: `${name}`,
                            value: id,
                            type: 'paymentChannel',
                            departmentId: department.msbId,
                        }))
                    ])
            ]);
        } else if (userType === UserTypeEnum.Client && clientForDepartments) {
            const client = clientNames.find((cn: any) => cn.msbId === clientForDepartments?.clientMSBId);
            return [
                { label: client?.businessName, value: client?.msbId, type: 'client' },
                ...data
                    ?.filter((department: any) => Object.keys(department.paymentChannels).length > 0)
                    .flatMap((department: any) => [
                        { label: `${department.name}`, value: department.msbId, type: 'department', clientId: client?.msbId },
                        ...Object.entries(department.paymentChannels).map(([id, name]) => ({
                            label: `${name}`,
                            value: id,
                            type: 'paymentChannel',
                            departmentId: department.msbId,
                        }))
                    ])
            ];
        }
        return [];
    };

    const options = buildOptions(paymentChannelsByUser, currentUser.userTypeEnum, currentUser.userTypeEnum === UserTypeEnum.Client ? currentUser.userClients[0] : []);

    const selectClient = (clientId: any) => {
        const clientDepartments = options.filter((opt: any) => opt.clientId === clientId && opt.type === 'department');
        const clientDepartmentIds = clientDepartments.map((dept: any) => dept.value);

        const clientChannels = options.filter((opt: any) => clientDepartmentIds.includes(opt.departmentId) && opt.type === 'paymentChannel');
        const clientChannelIds = clientChannels.map((channel: any) => channel.value);

        setSelectedItems((prev: any) => ({
            clients: new Set(prev.clients).add(clientId),
            departments: new Set([...prev.departments, ...clientDepartmentIds]),
            paymentChannels: new Set([...prev.paymentChannels, ...clientChannelIds])
        }));
    };

    const selectDepartment = (departmentId: any) => {
        const department = options.find((opt: any) => opt.value === departmentId && opt.type === 'department');
        const departmentChannels = options.filter((opt: any) => opt.departmentId === departmentId && opt.type === 'paymentChannel');
        const departmentChannelIds = departmentChannels.map((channel: any) => channel.value);

        setSelectedItems((prev: any) => ({
            clients: department ? new Set(prev.clients).add(department.clientId) : prev.clients,
            departments: new Set(prev.departments).add(departmentId),
            paymentChannels: new Set([...prev.paymentChannels, ...departmentChannelIds])
        }));
    };

    const selectPaymentChannel = (channelId: any, departmentId: any) => {
        const department = options.find((opt: any) => opt.value === departmentId && opt.type === 'department');
        const clientId = department?.clientId;

        setSelectedItems((prev: any) => ({
            clients: clientId ? new Set(prev.clients).add(clientId) : prev.clients,
            departments: departmentId ? new Set(prev.departments).add(departmentId) : prev.departments,
            paymentChannels: new Set(prev.paymentChannels).add(channelId)
        }));
    };

    const getSelectedItemsForParent = () => {
        const filteredDepartments = new Set(selectedItems.departments);
        let filteredPaymentChannels = new Set(selectedItems.paymentChannels);

        selectedItems.departments.forEach((departmentId: any) => {
            const departmentChannels = options.filter(
                (opt: any) => opt.departmentId === departmentId && opt.type === 'paymentChannel'
            ).map((opt: any) => opt.value);

            const allChannelsSelected = departmentChannels.every((channelId: any) =>
                selectedItems.paymentChannels.has(channelId)
            );

            if (allChannelsSelected) {
                departmentChannels.forEach((channelId: any) => filteredPaymentChannels.delete(channelId));
            } else {
                filteredDepartments.delete(departmentId);
            }
        });

        onSelectionChange({
            departmentIds: Array.from(filteredDepartments),
            paymentChannelIds: Array.from(filteredPaymentChannels)
        });
    };

    useEffect(() => {
        getSelectedItemsForParent();
        dispatch(setSelectedDepartmentPaymentChannels(selectedItems))
    }, [selectedItems]);

    const filteredOptions = options?.filter((opt: any) => {
        if (opt.type === 'client') {
            const clientDepartments = options.filter((o: { clientId: any; type: string; }) => o.clientId === opt.value && o.type === 'department');
            const clientChannels = options.filter((o: { departmentId: any; type: string; }) => clientDepartments.some((dept: { value: any; }) => dept.value === o.departmentId) && o.type === 'paymentChannel');

            const anyUnselectedDepartment = clientDepartments.some((dept: { value: unknown; }) => !selectedItems.departments.has(dept.value));
            const anyUnselectedChannel = clientChannels.some((channel: { value: unknown; }) => !selectedItems.paymentChannels.has(channel.value));

            return anyUnselectedDepartment || anyUnselectedChannel;
        }
        if (opt.type === 'department') {
            const departmentChannels = options.filter((o: { departmentId: any; type: string; }) => o.departmentId === opt.value && o.type === 'paymentChannel');
            const anyUnselectedChannel = departmentChannels.some((channel: { value: unknown; }) => !selectedItems.paymentChannels.has(channel.value));
            return !selectedItems.departments.has(opt.value) || anyUnselectedChannel;
        }
        if (opt.type === 'paymentChannel') {
            return !selectedItems.paymentChannels.has(opt.value);
        }
        return true;
    });

    const removeClient = (clientId: any) => {
        const clientDepartments = options.filter((opt: any) => opt.clientId === clientId && opt.type === 'department');
        const clientDepartmentIds = clientDepartments.map((dept: any) => dept.value);

        const clientChannels = options.filter((opt: any) => clientDepartmentIds.includes(opt.departmentId) && opt.type === 'paymentChannel');
        const clientChannelIds = clientChannels.map((channel: any) => channel.value);

        setSelectedItems((prev: any) => ({
            clients: new Set([...prev.clients].filter(id => id !== clientId)),
            departments: new Set([...prev.departments].filter(id => !clientDepartmentIds.includes(id))),
            paymentChannels: new Set([...prev.paymentChannels].filter(id => !clientChannelIds.includes(id)))
        }));
    };

    const removeDepartment = (departmentId: any) => {
        const departmentChannels = options.filter((opt: any) => opt.departmentId === departmentId && opt.type === 'paymentChannel');
        const departmentChannelIds = departmentChannels.map((channel: any) => channel.value);

        setSelectedItems((prev: any) => ({
            clients: prev.clients,
            departments: new Set([...prev.departments].filter(id => id !== departmentId)),
            paymentChannels: new Set([...prev.paymentChannels].filter(id => !departmentChannelIds.includes(id)))
        }));
    };

    const removePaymentChannel = (channelId: any) => {
        setSelectedItems((prev: any) => ({
            clients: prev.clients,
            departments: prev.departments,
            paymentChannels: new Set([...prev.paymentChannels].filter(id => id !== channelId))
        }));
    };

    const selectedHierarchy: any = Array.from(selectedItems.clients).reduce((acc: any, clientId: any) => {
        const client = options.find((opt: any) => opt.value === clientId && opt.type === 'client');
        if (client) {
            const departments: any = Array.from(selectedItems.departments).filter(departmentId =>
                options.some((opt: any) => opt.value === departmentId && opt.clientId === clientId)
            ).reduce((deptAcc: any, departmentId: any) => {
                const department = options.find((opt: any) => opt.value === departmentId && opt.type === 'department');
                if (department) {
                    const paymentChannels = Array.from(selectedItems.paymentChannels).filter(channelId =>
                        options.some((opt: any) => opt.value === channelId && opt.departmentId === departmentId)
                    ).map(channelId => {
                        const channel = options.find((opt: any) => opt.value === channelId);
                        return channel ? { label: channel.label, value: channel.value } : null;
                    }).filter(Boolean);

                    if (paymentChannels.length > 0) {
                        deptAcc[departmentId] = { label: department.label, paymentChannels };
                    }
                }
                return deptAcc;
            }, {});

            if (Object.keys(departments).length > 0) {
                acc[clientId] = { label: client.label, departments };
            }
        }
        return acc;
    }, {});

    const highlightText = (text: string, searchTerm: string) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        const parts = text.split(regex);

        return parts.map((part, index) =>
            part.toLowerCase() === searchTerm.toLowerCase() ? (
                <span key={index} style={{ fontWeight: 'bold', color: '#007bff' }}>{part}</span>
            ) : (
                part
            )
        );
    };

    return (
        <>
            <Row>
                <Col>
                    <Form.Label>
                        {reportType === "ClientBanking" ? 'All Clients and Departments' : 'All Payment Channels'}
                    </Form.Label>
                    <Select
                        key={JSON.stringify(filteredOptions)}
                        isMulti
                        closeMenuOnSelect={false}
                        menuIsOpen={true}
                        controlShouldRenderValue={false}
                        options={filteredOptions}
                        onChange={(selectedOptions: any) => {
                            selectedOptions.forEach((option: any) => {
                                switch (option.type) {
                                    case 'client':
                                        selectClient(option.value);
                                        break;
                                    case 'department':
                                        selectDepartment(option.value);
                                        break;
                                    case 'paymentChannel':
                                        selectPaymentChannel(option.value, option.departmentId);
                                        break;
                                    default:
                                        break;
                                }
                            });
                        }}
                        formatOptionLabel={(option: any, { inputValue }) => {
                            const getHierarchyLabel = (option: any) => {
                                switch (option.type) {
                                    case 'paymentChannel':
                                        const department = filteredOptions.find((opt: any) => opt.value === option.departmentId);
                                        const client = filteredOptions.find((opt: any) => opt.value === department?.clientId);
                                        return client ? `${client.label} > ${department?.label} > ${option.label}` : option.label;

                                    case 'department':
                                        const clientForDept = filteredOptions.find((opt: any) => opt.value === option.clientId);
                                        return clientForDept ? `${clientForDept.label} > ${option.label}` : option.label;

                                    case 'client':
                                    default:
                                        return option.label;
                                }
                            };
                            const hierarchyLabel = getHierarchyLabel(option);
                            return <span>{highlightText(hierarchyLabel, inputValue)}</span>;
                        }}
                        placeholder={reportType === "ClientBanking" ? 'All Clients and Departments' : 'All Payment Channels'}
                        noOptionsMessage={() => "No options available"}
                        components={{ ClearIndicator: null }}
                        styles={{
                            option: (provided, { data }) => ({
                                ...provided,
                                fontWeight: data.type === 'client' ? 700 : data.type === 'department' ? 600 : 400,
                                paddingLeft: data.type === 'client' ? 10 : data.type === 'department' ? 20 : 30,
                                color: data.type === 'client' ? '#000' : data.type === 'department' ? '#333' : '#666',
                            }),
                            menu: (provided) => ({ ...provided, maxHeight: '420px', overflowY: 'auto' }),
                            control: (provided) => ({ ...provided }),
                            dropdownIndicator: (provided) => ({ ...provided, display: 'none' }),
                            indicatorSeparator: (provided) => ({ ...provided, display: 'none' })
                        }}
                    />
                </Col>
                <Col>
                    <Form.Label>
                        {reportType === "ClientBanking" ? 'Selected Clients and Departments' : 'Selected Departments / Payment Channels'}
                    </Form.Label>
                    <div style={{ border: '1px solid #d9d9d9', padding: '0.563rem 0.75rem', height: '360px', maxHeight: '360px', overflowY: 'auto', borderRadius: '0.25rem' }}>
                        {!_.isEmpty(selectedHierarchy) ?
                            <>
                                {Object.entries(selectedHierarchy).map(([clientId, client]: any) => (
                                    <div key={clientId}>
                                        <div style={{ padding: '8px 12px' }}>
                                            <strong style={{ fontWeight: 800 }}>{client.label}</strong>
                                            <FontAwesomeIcon
                                                icon={faTimesCircle}
                                                style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
                                                onClick={() => removeClient(clientId)}
                                            />
                                        </div>
                                        {Object.entries(client.departments).map(([departmentId, department]: any) => (
                                            <>
                                                <div key={departmentId} style={{ marginLeft: '4px', padding: '8px 12px' }}>
                                                    <strong style={{ fontWeight: 700 }}>{department.label}</strong>
                                                    <FontAwesomeIcon
                                                        icon={faTimes}
                                                        style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
                                                        onClick={() => removeDepartment(departmentId)}
                                                    />
                                                </div>
                                                <div>
                                                    {department.paymentChannels.map((channel: any) => (
                                                        <div key={channel.value} style={{ marginLeft: '24px', padding: '8px 12px' }}>
                                                            <span style={{ color: '#666' }}>{channel.label}</span>
                                                            <FontAwesomeIcon
                                                                icon={faTimes}
                                                                style={{ cursor: 'pointer', color: 'gray', marginLeft: '0.5rem' }}
                                                                onClick={() => removePaymentChannel(channel.value)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                ))}
                            </>
                            :
                            <span>No selection</span>
                        }
                    </div>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        selectedDepartmentPaymentChannelItems: state.paymentTransactions.selectedDepartmentPaymentChannelItems,
    };
};

export default connect(mapStateToProps)(DepartmentPaymentChannelSelector);

