import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import _ from "lodash";
import moment from "moment";
import { TransactionTypeEnum, PaymentType } from '../../../models/Payment';
import { CurrencyAmountFormatter } from '../../../components/Formatters';

export const exportCSVData = (orderDetails: any, reportDetails: any, clientNames?: any, departmentNames?: any, paymentChannelNames?: any) => {

    const combinedData = reportDetails?.orderIdentifiers.flatMap((reportDetail: any) => {

        const matchingOrder = orderDetails.find((orderDetail: any) => 
            orderDetail?.orderIdentifier === reportDetail?.orderIdentifier &&
            TransactionTypeEnum[reportDetail?.transactionType] === orderDetail?.transactionType
        );

        if (!matchingOrder) return [];

        return [{
            clientId: reportDetails?.clientId || '',
            clientName: reportDetails?.clientName || '',
            departmentId: reportDetails?.departmentId || '',
            departmentName: reportDetails?.departmentName || '',
            paymentChannelId: matchingOrder?.paymentChannelId || '',
            paymentChannelName: paymentChannelNames.find((dn: any) => dn.msbId === matchingOrder?.paymentChannelId)?.name || '',
            debitFeeMethod: reportDetail?.debitFeeMethod || '',
            customerFunding: reportDetail?.customerFunding || '',
            transactionId: matchingOrder?.orderIdentifier || '',
            transactionType: matchingOrder?.transactionType || '',
            paymentType: matchingOrder?.paymentType || '',
            orderLines: matchingOrder?.orderLines || '',
            initiatedBy: matchingOrder?.initiatedBy || '',
            lastFourOnCard: matchingOrder?.lastFourOnCard || '',
            reason: matchingOrder?.reason || '',
            transactionDate: moment(reportDetails?.transactionDate).format('MM-DD-YYYY') || '',
            modifiedDate: reportDetails?.transactionDate || '',
            subtotal: matchingOrder?.amount !== undefined ? matchingOrder?.amount : '',
            convenienceFee: matchingOrder?.convenienceFee !== undefined ? matchingOrder?.convenienceFee : '',
            totalAmount: matchingOrder?.totalAmount !== undefined ? matchingOrder?.totalAmount : '',
            remainingBalance: matchingOrder?.remainingBalance !== undefined ? matchingOrder?.remainingBalance : '',
            customerName: matchingOrder?.nameOnCard || '',
            cardType: matchingOrder?.cardLogo && `${matchingOrder?.cardLogo + ((matchingOrder?.isDebitCardTransaction) ? " Debit" : " Credit")}` || matchingOrder?.paymentType || '',
            customerPhone: matchingOrder?.phone || '',
            customerEmail: matchingOrder?.email || '',
            addressLine1: matchingOrder?.addressLine1 || '',
            addressLine2: matchingOrder?.addressLine2 || '',
            city: matchingOrder?.city || '',
            state: matchingOrder?.state || '',
            zip: matchingOrder?.zip || ''
        }];
    });
    
    const tableStyle = {
        headerRow: {
            font: { color: { argb: 'FFFFFFF' }, bold: true },
            alignment: { vertical: 'middle', horizontal: 'center' },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF00757A' } },
            border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' } }
        },
        bodyRow: {
            alignment: { vertical: 'middle', horizontal: 'center' },
            border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' } }
        },
        titleRow: {
            font: { color: { argb: 'FFFFFFF' }, bold: true, size: 18 },
            alignment: { vertical: 'middle', horizontal: 'center' },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF053B57' } },
            border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' } }
        },
        boldCellStyle: {
            font: { bold: true, size: 16 },
            alignment: { horizontal: 'center' }
        },
        boldSideHeadingStyle: {
            font: { bold: true, size: 14 },
            alignment: { horizontal: 'center' }
        }
    }

    const filteredCashCheckPayments = combinedData.filter(
        (data: any) => data.paymentType !== PaymentType.PhysicalCheck && data.paymentType !== PaymentType.Cash &&
            data.paymentType !== PaymentType[6] && data.paymentType !== PaymentType[7]
    );

    const miscData = combinedData.filter(
        (data: any) => data.paymentType === PaymentType.PhysicalCheck || data.paymentType === PaymentType.Cash ||
            data.paymentType === PaymentType[6] || data.paymentType === PaymentType[7]
    );

    const workbook = new ExcelJS.Workbook();
    generateSheet1(workbook, filteredCashCheckPayments, paymentChannelNames, tableStyle);
    generateSheet2(workbook, filteredCashCheckPayments, tableStyle);
    generateSheet3(workbook, filteredCashCheckPayments, paymentChannelNames, tableStyle);
    generateSheet4(workbook, filteredCashCheckPayments, clientNames, departmentNames, paymentChannelNames, tableStyle);
    if (miscData.length > 0) {
        generateSheet5(workbook, miscData, tableStyle);
    }

    workbook.xlsx.writeBuffer().then((buffer: any) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const dataSet = combinedData[0];
        const fileName = `${dataSet?.clientName}_${dataSet?.departmentName}_FundingReport_${dataSet?.transactionDate}`
        saveAs(blob, `${fileName}.xlsx`);
    });

}

const generateSheet1 = (workbook: any, combinedData: any, paymentChannelNames?: any, tableStyle?: any) => {
    const sheet = workbook.addWorksheet('Summary');

    helperForSummaryDebitFee(sheet, combinedData, tableStyle);
    sheet.addRow([]);
    helperForDepartmentSummary(sheet, combinedData, tableStyle);
    sheet.addRow([]);
    helperForPaymentChannelSummary(sheet, combinedData, paymentChannelNames, tableStyle);
    sheet.addRow([]);
    helperForItemSummary(sheet, combinedData, paymentChannelNames, tableStyle);
    sheet.eachRow({ includeEmpty: false }, (row: any) => {
        row.eachCell((cell: any) => {
            Object.assign(cell, tableStyle.bodyRow);
        })
    })
    setSheetWidths(sheet, 24);
}


const generateSheet2 = (workbook: any, combinedData: any, tableStyle?: any) => {
    const sheet = workbook.addWorksheet('Detail.Department');
    const groups = {} as any;

    combinedData.forEach((row: any) => {
        if (!groups[row.transactionType]) {
            groups[row.transactionType] = [];
        }
        groups[row.transactionType].push(row)
    })

    const tableProperties = ['clientName', 'departmentName', 'paymentChannelName', 'initiatedBy', 'transactionId', 'lastFourOnCard', 'reason', 'transactionDate', 'transactionType', 'subtotal', 'convenienceFee', 'totalAmount', 'remainingBalance', 'customerName', 'cardType']
    const sumProperties = ['subtotal', 'convenienceFee', 'totalAmount'];

    Object.keys(groups).forEach((groupName: any) => {
        const groupData = groups[groupName];

        sheet.addRow([`${groupName}`]).eachCell((cell: any) => {
            Object.assign(cell, tableStyle.titleRow)
        });
        sheet.mergeCells(sheet.lastRow.getCell(1).address, sheet.lastRow.getCell(tableProperties.length).address)
        const headers = tableProperties.map((property: any) => property.charAt(0).toUpperCase() + property.slice(1));

        sheet.addRow(headers).eachCell((cell: any) => {
            Object.assign(cell, tableStyle.headerRow)
        });

        groupData.forEach((row: any) => {
            const rowData = tableProperties.map((header: any) => (header == 'subtotal' || header == 'convenienceFee' || header == 'totalAmount' || header == 'remainingBalance')
                ? CurrencyAmountFormatter.format(row[header]) : row[header])
            sheet.addRow(rowData);
        });

        sheet.eachRow({ includeEmpty: false }, (row: any) => {
            row.eachCell((cell: any) => {
                Object.assign(cell, tableStyle.bodyRow);
            })
        })

        const totalsRow = {} as any;
        groupData.forEach((row: any) => {
            sumProperties.forEach((key: any) => {
                if (!isNaN(row[key])) {
                    totalsRow[key] = (totalsRow[key] || 0) + row[key];
                }
            })
        })
        const totalsData = tableProperties.map(header => (header == 'subtotal' || header == 'convenienceFee' || header == 'totalAmount')
            ? CurrencyAmountFormatter.format(totalsRow[header]) : totalsRow[header] || '');
        const modifiedTotals = addTitles(totalsData, 'Total')
        sheet.addRow([...modifiedTotals]).eachCell((cell: any) => {
            cell.font = { bold: true };
            Object.assign(cell, tableStyle.boldCellStyle)
        });
        sheet.addRow([]);
    });

    const grandTotalsRow = {} as any;
    Object.values(groups).forEach((typeData: any) => {
        typeData.forEach((row: any) => {
            sumProperties.forEach((property: any) => {
                if (!isNaN(row[property])) {
                    grandTotalsRow[property] = (grandTotalsRow[property] || 0) + row[property];
                }
            })

        })
    })

    const grandTotalsData = tableProperties.map(property => (property == 'subtotal' || property == 'convenienceFee' || property == 'totalAmount')
        ? CurrencyAmountFormatter.format(grandTotalsRow[property]) : grandTotalsRow[property] || '');
    const modifiedGrandTotals = addTitles(grandTotalsData, 'Grand Total')
    sheet.addRow(modifiedGrandTotals).eachCell((cell: any) => {
        cell.font = { bold: true };
        Object.assign(cell, tableStyle.boldCellStyle)
    });

    setSheetWidths(sheet, 24, 24)
}

const generateSheet3 = (workbook: any, combinedData: any, paymentChannelNames?: any, tableStyle?: any) => {
    const sheet = workbook.addWorksheet('Detail.Channel');
    const groups = {} as any;

    combinedData.forEach((row: any) => {
        if (!groups[row.paymentChannelId]) {
            groups[row.paymentChannelId] = [];
        }
        if (!groups[row.paymentChannelId][row.transactionType]) {
            groups[row.paymentChannelId][row.transactionType] = [];
        }
        groups[row.paymentChannelId][row.transactionType].push(row)
    })

    const tableProperties = ['clientName', 'departmentName', 'paymentChannelName', 'initiatedBy', 'transactionId', 'lastFourOnCard', 'reason', 'transactionDate', 'transactionType', 'subtotal', 'convenienceFee', 'totalAmount', 'remainingBalance', 'customerName', 'cardType']
    const sumProperties = ['subtotal', 'convenienceFee', 'totalAmount']
    const grandTotalArray = [] as any;

    Object.keys(groups).forEach((id: any) => {
        const secondLevelGroups = groups[id];
        Object.keys(secondLevelGroups).forEach((type: any) => {
            const typeAndSecondPropData = secondLevelGroups[type];
            const channelName = paymentChannelNames.find((dn: any) => dn.msbId === id)?.name || '';

            sheet.addRow([`${channelName}`]).eachCell((cell: any) => {
                Object.assign(cell, tableStyle.titleRow)
            });
            sheet.mergeCells(sheet.lastRow.getCell(1).address, sheet.lastRow.getCell(tableProperties.length).address)
            sheet.addRow([`${type}`]).eachCell((cell: any) => {
                Object.assign(cell, tableStyle.titleRow)
            });
            sheet.mergeCells(sheet.lastRow.getCell(1).address, sheet.lastRow.getCell(tableProperties.length).address)

            const headers = tableProperties.map((property: any) => property.charAt(0).toUpperCase() + property.slice(1));
            sheet.addRow(headers).eachCell((cell: any) => {
                Object.assign(cell, tableStyle.headerRow)
            });

            typeAndSecondPropData.forEach((row: any) => {
                const rowData = tableProperties.map(header => (header == 'subtotal' || header == 'convenienceFee' || header == 'totalAmount' || header == 'remainingBalance') ? CurrencyAmountFormatter.format(row[header]) : row[header])
                sheet.addRow(rowData);
            });

            sheet.eachRow({ includeEmpty: false }, (row: any) => {
                row.eachCell((cell: any) => {
                    Object.assign(cell, tableStyle.bodyRow);
                })
            })

            const totalsRow = {} as any;
            typeAndSecondPropData.forEach((row: any) => {
                sumProperties.forEach((key: any) => {
                    if (!isNaN(row[key])) {
                        totalsRow[key] = (totalsRow[key] || 0) + row[key];
                    }
                })
            })
            const totalsData = tableProperties.map(header => totalsRow[header] || '');
            const totalsRowData = tableProperties.map(header => (header == 'subtotal' || header == 'convenienceFee' || header == 'totalAmount') ? CurrencyAmountFormatter.format(totalsRow[header]) : totalsRow[header] || '');
            const modifiedTotals = addTitles(totalsRowData, 'Total')
            sheet.addRow([...modifiedTotals]).eachCell((cell: any) => {
                cell.font = { bold: true };
                Object.assign(cell, tableStyle.boldCellStyle)
            })
            grandTotalArray.push(totalsData);
            sheet.addRow([]);
        });
    })

    const grandTotals = _.zipWith(...grandTotalArray, (...items) => {
        let sum = items.filter(item => typeof item === 'number' && !isNaN(item)).reduce((acc: any, cur: any) => acc + cur, 0) as any;
        return sum === 0 ? '' : CurrencyAmountFormatter.format(sum)
    })

    const modifiedGrandTotals = addTitles(grandTotals, 'Grand Total')
    sheet.addRow([...modifiedGrandTotals]).eachCell((cell: any) => {
        cell.font = { bold: true };
        Object.assign(cell, tableStyle.boldCellStyle)
    })
    setSheetWidths(sheet, 24, 24);
}

const generateSheet4 = (workbook: any, combinedData: any, clientNames?: any, departmentNames?: any, paymentChannelNames?: any, tableStyle?: any) => {
    const sheet = workbook.addWorksheet('Detail.Item');
    const groups = {} as any;

    combinedData.forEach((row: any) => {
        const key = `${row?.clientId}\\${row?.departmentId}\\${row?.modifiedDate}`
        if (!groups[key]) {
            groups[key] = [];
        }
        row?.orderLines.forEach((line: any) => {
            const lineKey = line?.itemName;
            if (!groups[key][lineKey]) {
                groups[key][lineKey] = [];
            }
            groups[key][lineKey].push({ ...row, line });
        })
    })

    const tableProperties = ['clientName', 'departmentName', 'paymentChannelName', 'initiatedBy', 'transactionId', 'lastFourOnCard', 'reason', 'transactionDate', 'transactionType', 'customerName', 'cardType', 'customerPhone', 'customerEmail', 'addressLine1', 'addressLine2', 'city', 'state', 'zip']
    const lineProperties = ['itemName', 'itemReferenceNumber', 'quantity', 'unitPrice', 'itemTotal']
    const sumProperties = ['quantity', 'unitPrice', 'itemTotal']
    const tableTotals = [] as any;
    const elementsOrder = ['clientName', 'departmentName', 'paymentChannelName', 'initiatedBy', 'transactionId', 'lastFourOnCard', 'reason', 'itemReference', 'itemName', 'unitPrice', 'quantity', 'itemTotal', 'transactionDate', 'transactionType', 'customerName', 'cardType', 'customerPhone', 'customerEmail', 'addressLine1', 'addressLine2', 'city', 'state', 'zip']

    Object.entries(groups).forEach(([mainKey, mainGroup]: any) => {
        const [clientId, departmentId, modifiedDate] = mainKey.split('\\');
        Object.entries(mainGroup).forEach(([lineName, groupData]: any) => {
            const clientName = clientNames.find((cn: any) => cn.msbId === clientId)?.businessName || '';
            const departmentName = departmentNames.find((dn: any) => dn.msbId === departmentId)?.name || '';
            const formattedDate = moment(modifiedDate).format('MM-DD-YYYY');
            const title = `${clientName} - ${departmentName} - ${formattedDate} - ${lineName}`;
            sheet.addRow([title]).eachCell((cell: any) => {
                Object.assign(cell, tableStyle.titleRow)
            });
            sheet.mergeCells(sheet.lastRow.getCell(1).address, sheet.lastRow.getCell(tableProperties.length + lineProperties.length).address)

            const headers = tableProperties.map((property: any) => property.charAt(0).toUpperCase() + property.slice(1));

            const lineHeaders = lineProperties.map((property: any) => property.charAt(0).toUpperCase() + property.slice(1))

            sheet.addRow([...headers, ...lineHeaders]).eachCell((cell: any) => {
                Object.assign(cell, tableStyle.headerRow)
            });

            sheet.eachRow({ includeEmpty: false }, (row: any) => {
                row.eachCell((cell: any) => {
                    Object.assign(cell, tableStyle.bodyRow);
                })
            })

            groupData.forEach((details: any) => {
                const lineDetail = details?.line;
                const changeUnitPriceByTransaction = (details?.transactionType == TransactionTypeEnum[5]
                    || details?.transactionType == TransactionTypeEnum[3]
                    || details?.transactionType == TransactionTypeEnum[9]
                ) ? -Math.abs(lineDetail.unitPrice) : lineDetail.unitPrice;
                const itemTotal = lineDetail.quantity * changeUnitPriceByTransaction;
                lineDetail.itemTotal = itemTotal;
                lineDetail.unitPrice = changeUnitPriceByTransaction;
            })

            groupData.forEach((row: any) => {
                const rowData = tableProperties.map((property: any) => row[property]);
                const orderLine = [row?.line]
                const lineData = orderLine.map((line: any) => lineProperties.map((property: any) => (property == 'unitPrice' || property == 'itemTotal') ? CurrencyAmountFormatter.format(line[property]) : line[property] || ''));
                lineData.forEach((data: any) => {
                    sheet.addRow([...rowData, ...data]);
                    const newRow = sheet.lastRow;
                    newRow.eachCell((cell: any) => {
                        Object.assign(cell, tableStyle.bodyRow);
                    })
                })
            });

            const totalsRow = sumProperties.map((property: any) => {
                let total = 0;
                groupData.forEach((row: any) => {
                    total += row[property] || 0;
                    const orderLine = [row?.line]
                    orderLine.forEach((line: any) => {
                        total += line[property] || 0;
                    });
                });
                return total;
            });
            const modifiedTotal = totalsRow.map((item, index) => index === 1 || index === 2 ? CurrencyAmountFormatter.format(item) : item)
            sheet.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Totals', ...modifiedTotal]).eachCell((cell: any) => {
                cell.font = { bold: true };
                Object.assign(cell, tableStyle.boldCellStyle)
            });
            tableTotals.push(totalsRow);
            sheet.addRow([]);
        })
    });

    const grandTotals = sumProperties.map((_, index) => {
        let total = 0;
        tableTotals.forEach((tableTotal: any) => {
            total += tableTotal[index] || 0;
        });
        return total
    });
    const modifiedGrandTotal = grandTotals.map((item, index) => index === 1 || index === 2 ? CurrencyAmountFormatter.format(item) : item)

    sheet.addRow(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Grand Total', ...modifiedGrandTotal]).eachCell((cell: any) => {
        cell.font = { bold: true };
        Object.assign(cell, tableStyle.boldCellStyle)
    });
    setSheetWidths(sheet, 16);
}

const setSheetWidths = (sheet: any, maxWidth: any, customWidth?: any) => {
    sheet?.columns?.forEach((column: any) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell: any) => {
            const cellValue = cell.value;
            if (cellValue !== undefined && cellValue !== null) {
                const cellLength = typeof cellValue === 'number' ? cellValue.toString().length : cellValue.toString().length + 2;
                maxLength = Math.max(maxLength, cellLength)
            }
        })
        column.width = customWidth ? customWidth : Math.min(maxLength, maxWidth);
    })
}

const generateSheet5 = (workbook: any, combinedData: any, tableStyle?: any) => {
    const sheet = workbook.addWorksheet('MiscTender');
    helperForSummaryDebitFee(sheet, combinedData, tableStyle, true);
    sheet.addRow([]);
    helperForDepartmentSummary(sheet, combinedData, tableStyle, true);
    sheet.addRow([]);
    helperForTransactions(sheet, combinedData, tableStyle)
    setSheetWidths(sheet, 24);
}

const addTitles = (array: any, text: any) => {
    let foundIndex = _.findIndex(array, item => item !== '');
    if (foundIndex > 0) array[foundIndex - 1] = text;
    return array
}

const helperForTransactions = (sheet: any, combinedData: any, tableStyle?: any) => {

    const tableProperties = [
        'clientName', 'departmentName', 'paymentChannelName', 'initiatedBy', 'transactionId', 'lastFourOnCard',
        'reason', 'transactionDate', 'transactionType', 'subtotal', 'convenienceFee', 'totalAmount',
        'remainingBalance', 'customerName', 'paymentType'
    ];
    const sumProperties = ['subtotal', 'convenienceFee', 'totalAmount'];

    sheet.addRow(['Transactions']).eachCell((cell: any) => {
        Object.assign(cell, tableStyle.titleRow)
    });
    sheet.mergeCells(sheet.lastRow.getCell(1).address, sheet.lastRow.getCell(tableProperties.length).address)

    sheet.addRow([])

    const headers = tableProperties.map((property: any) => property.charAt(0).toUpperCase() + property.slice(1));
    sheet.addRow(headers).eachCell((cell: any) => {
        Object.assign(cell, tableStyle.headerRow);
    });

    combinedData.forEach((row: any) => {
        const rowData = tableProperties.map((header: any) =>
            (header === 'subtotal' || header === 'convenienceFee' || header === 'totalAmount' || header === 'remainingBalance')
                ? CurrencyAmountFormatter.format(row[header])
                : row[header] || ''
        );
        sheet.addRow(rowData);
    });

    sheet.eachRow({ includeEmpty: false }, (row: any) => {
        row.eachCell((cell: any) => {
            Object.assign(cell, tableStyle.bodyRow);
        });
    });

    const totalsRow = {} as any;
    combinedData.forEach((row: any) => {
        sumProperties.forEach((key: any) => {
            if (!isNaN(row[key])) {
                totalsRow[key] = (totalsRow[key] || 0) + row[key];
            }
        });
    });

    const totalsData = tableProperties.map((header: any) =>
        (sumProperties.includes(header)) ? CurrencyAmountFormatter.format(totalsRow[header] || 0) : ''
    );
    const modifiedTotals = addTitles(totalsData, 'Total');
    sheet.addRow([...modifiedTotals]).eachCell((cell: any) => {
        cell.font = { bold: true };
        Object.assign(cell, tableStyle.boldCellStyle);
    });
};


const helperForSummaryDebitFee = (sheet?: any, combinedData?: any, tableStyle?: any, noFunding?: boolean) => {

    const debitMethodTypes = _.uniq(_.map(combinedData, 'debitFeeMethod'));
    const sumsByType = {} as any;
    const title = ` ${_.uniq(_.map(combinedData, 'clientName'))} - ${_.uniq(_.map(combinedData, 'departmentName'))}`;

    combinedData.forEach((item: any) => {
        const { debitFeeMethod, transactionType, subtotal, totalAmount, customerFunding } = item;
        if (!sumsByType[debitFeeMethod]) {
            if (debitFeeMethod == 'Net') {
                sumsByType[debitFeeMethod] = { debitFeeMethod, customerFunding, totalCredits: 0, totalDebits: 0, totalFunded: 0 }
            }
            else {
                sumsByType[debitFeeMethod] = { debitFeeMethod, customerFunding, totalCredits: 0, totalDebits: 0 }
            }
        }
        sumsByType[debitFeeMethod].totalCredits += (transactionType == TransactionTypeEnum[1] || transactionType == TransactionTypeEnum[7] || transactionType == TransactionTypeEnum[10]) ? subtotal : 0;
        sumsByType[debitFeeMethod].totalDebits += (transactionType == TransactionTypeEnum[3] || transactionType === TransactionTypeEnum[5] || transactionType === TransactionTypeEnum[9]) ? subtotal : 0;
        if (debitFeeMethod == 'Net') { sumsByType[debitFeeMethod].totalFunded += subtotal || 0 }

    });

    const formattedOutput = Object.values(sumsByType);
    const transformedData = formattedOutput.flatMap((item: any, index: any) => {
        return Object.entries(item).map(([key, value]: any) => {
            return {
                debitFeeMethod: item.debitFeeMethod,
                header: key.charAt(0).toUpperCase() + key.slice(1),
                result: (key == 'debitFeeMethod' || key == 'customerFunding') ? value : CurrencyAmountFormatter.format(value)
            }
        })
    })

    const filteredTransformedData = noFunding
        ? transformedData.filter((item) => item.header !== 'DebitFeeMethod' && item.header !== 'CustomerFunding')
        : transformedData;

    let startColumn = 1;
    sheet.addRow([`${title}`]).eachCell((cell: any) => {
        Object.assign(cell, tableStyle?.titleRow || '');
    });

    sheet.mergeCells('A1:B1')
    debitMethodTypes.forEach((type, index) => {
        const filteredData = filteredTransformedData.filter((item: any) => item?.debitFeeMethod === type);
        filteredData.forEach((item, rowIndex) => {
            sheet.getCell(rowIndex + 2, startColumn).value = item.header;
            sheet.getCell(rowIndex + 2, startColumn).style = tableStyle.boldSideHeadingStyle;
            sheet.getCell(rowIndex + 2, startColumn + 1).value = item.result;
        })
        startColumn += 4;
    })

}

const helperForDepartmentSummary = (sheet?: any, combinedData?: any, tableStyle?: any, noFunding?: boolean) => {

    const tableProperties = ['type', 'counts', 'sumOfSubTotal', 'sumOfConvenienceFee', 'sumOfTotalAmount']
    const sumProperties = ['counts', 'sumOfSubTotal', 'sumOfConvenienceFee', 'sumOfTotalAmount']

    sheet.addRow(['Department Summary']).eachCell((cell: any) => {
        Object.assign(cell, tableStyle.titleRow)
    });
    sheet.mergeCells(sheet.lastRow.getCell(1).address, sheet.lastRow.getCell(tableProperties.length).address)

    sheet.addRow([])

    const typeOfTables = ['transactionType', noFunding ? 'paymentType' : 'cardType']

    typeOfTables.forEach((type, index) => {
        const subTitle = type == 'transactionType' ? 'Transaction Type' : 'Payment Type';

        sheet.addRow([`By ${subTitle}`]).eachCell((cell: any) => {
            cell.font = { bold: true };
            Object.assign(cell, tableStyle.boldCellStyle)
        });
        const groupByType = {} as any;
        combinedData.forEach((row: any) => {
            const groupTypeValue = row[type] || 'Other';
            if (!groupByType[groupTypeValue]) {
                groupByType[groupTypeValue] = [];
            }
            groupByType[groupTypeValue].push(row);
        });

        const modifiedData = _.map(groupByType, (transactions, type) => ({
            type: type,
            counts: transactions?.length,
            sumOfSubTotal: _.sumBy(transactions, 'subtotal'),
            sumOfConvenienceFee: _.sumBy(transactions, 'convenienceFee'),
            sumOfTotalAmount: _.sumBy(transactions, 'totalAmount')
        }));

        sheet.mergeCells(sheet.lastRow.getCell(1).address, sheet.lastRow.getCell(tableProperties.length).address)
        const headers = tableProperties.map((property: any) => property.charAt(0).toUpperCase() + property.slice(1));

        sheet.addRow(headers).eachCell((cell: any) => {
            Object.assign(cell, tableStyle.headerRow)
        });

        modifiedData.forEach((row: any) => {
            const rowData = tableProperties.map(header => (header != 'type' && header != 'counts') ? CurrencyAmountFormatter.format(row[header]) : row[header])
            sheet.addRow(rowData);
        });

        sheet.eachRow({ includeEmpty: false }, (row: any) => {
            row.eachCell((cell: any) => {
                Object.assign(cell, tableStyle.bodyRow);
            })
        })

        const totalsRow = {} as any;
        modifiedData.forEach((row: any) => {
            sumProperties.forEach((key: any) => {
                if (!isNaN(row[key])) {
                    totalsRow[key] = (totalsRow[key] || 0) + row[key];
                }
            })
        })
        const totalsData = tableProperties.map(header => (header == 'counts' || header == 'type') ? (totalsRow[header] || '') : (CurrencyAmountFormatter.format(totalsRow[header]) || ''));
        const modifiedTotals = addTitles(totalsData, 'Grand Total')
        sheet.addRow([...modifiedTotals]).eachCell((cell: any) => {
            cell.font = { bold: true };
            Object.assign(cell, tableStyle.boldCellStyle)
        });
        sheet.addRow([])

    })

}

const helperForPaymentChannelSummary = (sheet?: any, combinedData?: any, paymentChannelNames?: any, tableStyle?: any) => {

    const headers = ['Row Labels', 'Count of Transaction ID', 'Sum of Subtotal', 'Sum of Convenience Fee', 'Sum of Total amount'];

    sheet.addRow(['Channel Summary']).eachCell((cell: any) => {
        Object.assign(cell, tableStyle.titleRow)
    });
    sheet.mergeCells(sheet.lastRow.getCell(1).address, sheet.lastRow.getCell(headers.length).address)

    sheet.addRow([])
    const typeOfTables = ['transactionType', 'cardType'];

    typeOfTables.forEach((groupByType, index) => {
        const subTitle = groupByType == 'transactionType' ? 'Transaction Type' : 'Payment Type';
        sheet.addRow([`By ${subTitle}`]).eachCell((cell: any) => {
            cell.font = { bold: true };
            Object.assign(cell, tableStyle.boldCellStyle)
        });

        const groupsForPaymentChannel = {} as any;
        sheet.mergeCells(sheet.lastRow.getCell(1).address, sheet.lastRow.getCell(headers.length).address)

        combinedData.forEach((row: any) => {
            if (!groupsForPaymentChannel[row.paymentChannelId]) {
                groupsForPaymentChannel[row.paymentChannelId] = [];
            }
            if (!groupsForPaymentChannel[row.paymentChannelId][row[groupByType]]) {
                groupsForPaymentChannel[row.paymentChannelId][row[groupByType]] = [];
            }
            groupsForPaymentChannel[row.paymentChannelId][row[groupByType]].push(row)
        })

        sheet.addRow(headers).eachCell((cell: any) => {
            Object.assign(cell, tableStyle.headerRow)
        });

        let row = sheet.rowCount;
        let grandTotalTransactions = 0, grandTotalSubtotal = 0, grandTotalConvenienceFee = 0, grandTotalAmount = 0;

        for (const [label, types] of Object.entries(groupsForPaymentChannel) as any) {
            let totalTransactions = 0, totalSubtotal = 0, totalConvenienceFee = 0, totalOfTotalAmount = 0;
            const channelName = paymentChannelNames.find((dn: any) => dn.msbId === label)?.name || '';
            sheet.addRow([channelName, '', '', '', '', '']).eachCell((cell: any) => {
                cell.font = { bold: true };
                Object.assign(cell, tableStyle.boldSideHeadingStyle)
            });
            for (const [type, transactions] of Object.entries(types) as any) {
                const transactionCount = transactions.length;
                const [subtotal, convenienceFee, totalAmount] = transactions.reduce((acc: any, t: any) => {
                    acc[0] += t.subtotal;
                    acc[1] += t.convenienceFee >= 0 ? t.convenienceFee : -t.convenienceFee;
                    acc[2] += t.totalAmount;
                    return acc;
                }, [0, 0, 0]);

                totalTransactions += transactionCount;
                totalSubtotal += subtotal;
                totalConvenienceFee += convenienceFee;
                totalOfTotalAmount += totalAmount;
                sheet.addRow([type, transactionCount, CurrencyAmountFormatter.format(subtotal), CurrencyAmountFormatter.format(convenienceFee), CurrencyAmountFormatter.format(totalAmount)]);
                row++;
            }

            sheet.addRow(['Totals', totalTransactions, CurrencyAmountFormatter.format(totalSubtotal), CurrencyAmountFormatter.format(totalConvenienceFee), CurrencyAmountFormatter.format(totalOfTotalAmount)]).eachCell((cell: any) => {
                cell.font = { bold: true };
                Object.assign(cell, tableStyle.boldSideHeadingStyle)
            });
            row++;

            grandTotalTransactions += totalTransactions;
            grandTotalSubtotal += totalSubtotal;
            grandTotalConvenienceFee += totalConvenienceFee;
            grandTotalAmount += totalOfTotalAmount

        }
        sheet.addRow(['Grand Total', grandTotalTransactions, CurrencyAmountFormatter.format(grandTotalSubtotal), CurrencyAmountFormatter.format(grandTotalConvenienceFee), CurrencyAmountFormatter.format(grandTotalAmount)]).eachCell((cell: any) => {
            cell.font = { bold: true };
            Object.assign(cell, tableStyle.boldCellStyle)
        });
        sheet.addRow([])
    })
}


const helperForItemSummary = (sheet?: any, combinedData?: any, paymentChannelNames?: any, tableStyle?: any) => {
    const headers = ['Row Labels', 'Count of Transaction ID', 'Sum of Item Total'];
    sheet.addRow(['Item Summary']).eachCell((cell: any) => {
        Object.assign(cell, tableStyle.titleRow)
    });
    sheet.mergeCells(sheet.lastRow.getCell(1).address, sheet.lastRow.getCell(headers.length).address)
    sheet.addRow([])

    const groupsForItem = {} as any;

    combinedData.forEach((row: any) => {
        if (!groupsForItem[row.transactionType]) {
            groupsForItem[row.transactionType] = [];
        }
        row?.orderLines.forEach((line: any) => {
            const lineKey = line?.itemName;
            const changeUnitPriceByTransaction = (row?.transactionType == TransactionTypeEnum[5]
                || row?.transactionType == TransactionTypeEnum[3]
                || row?.transactionType == TransactionTypeEnum[9]
            ) ? -Math.abs(line.unitPrice) : line.unitPrice;
            const itemTotal = line.quantity * changeUnitPriceByTransaction;
            line.itemTotal = itemTotal;
            line.unitPrice = changeUnitPriceByTransaction;
            if (!groupsForItem[row.transactionType][lineKey]) {
                groupsForItem[row.transactionType][lineKey] = [];
            }
            groupsForItem[row.transactionType][lineKey].push({ ...row, line });
        })
    })

    sheet.addRow(headers).eachCell((cell: any) => {
        Object.assign(cell, tableStyle.headerRow)
    });

    let row = sheet.rowCount;
    let grandTotalTransactions = 0, grandTotalSubtotal = 0, grandTotalConvenienceFee = 0, grandTotalAmount = 0;

    for (const [label, types] of Object.entries(groupsForItem) as any) {
        let totalTransactions = 0, totalSubtotal = 0, totalConvenienceFee = 0, totalOfTotalAmount = 0;
        sheet.addRow([label, '', '']).eachCell((cell: any) => {
            Object.assign(cell, tableStyle.boldSideHeadingStyle)
        })
        for (const [type, transactions] of Object.entries(types) as any) {
            const transactionCount = transactions.length;
            const [itemTotal] = transactions.reduce((acc: any, t: any) => {
                acc[0] += t.line.itemTotal
                //convenienceFee, totalAmount
                // acc[0] += t.subtotal;
                // acc[1] += t.convenienceFee >= 0 ? t.convenienceFee : -t.convenienceFee;
                // acc[2] += t.totalAmount;
                return acc;
            }, [0]);

            totalTransactions += transactionCount;
            totalSubtotal += itemTotal;
            // totalConvenienceFee += convenienceFee;
            // totalOfTotalAmount += totalAmount;
            sheet.addRow([type, transactionCount, CurrencyAmountFormatter.format(itemTotal)]);
            row++;
        }

        sheet.addRow(['Totals', totalTransactions, CurrencyAmountFormatter.format(totalSubtotal)]).eachCell((cell: any) => {
            Object.assign(cell, tableStyle.boldSideHeadingStyle)
        });
        row++;

        grandTotalTransactions += totalTransactions;
        grandTotalSubtotal += totalSubtotal;
        // grandTotalConvenienceFee += totalConvenienceFee;
        // grandTotalAmount += totalOfTotalAmount

    }
    sheet.addRow(['Grand Total', grandTotalTransactions, CurrencyAmountFormatter.format(grandTotalSubtotal)]).eachCell((cell: any) => {
        Object.assign(cell, tableStyle.boldCellStyle)
    });
}



