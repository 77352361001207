
import { useDispatch } from "react-redux";
import { achFinanceFormSchema } from '../components/AdvancedSearchColumns';
import Report from './fundByTimeZoneReport'
import { DateFormatter, CurrencyFormatter, ConvenienceFeeFormatter, TimeZoneConverter } from '../../../components/Formatters';
import { achTransactionDetailAction } from '../../../redux/actions/payments/paymentTransactions';
import { ReportType } from "../../../models/Reports";
import { Routes } from "../../../routes";

import { selectFilter } from 'react-bootstrap-table2-filter';
import { CSVConvenienceFeeFormatter, CSVCurrencyFormatter, CSVDateFormatter } from "../../../components/CSVFormatters";

export const rowStyle = (row: any) => {
    const style = { backgroundColor: '' };
    if (row.transactionType == 'Chargeback') {
        style.backgroundColor = '#E6F7FD';
    } else if (row.transactionType == 'Void') {
        style.backgroundColor = '#FEFFE6';
    } else if (row.transactionType == 'Reversal') {
        style.backgroundColor = '#FEFFE6';
    } else if (row.transactionType == 'Refund') {
        style.backgroundColor = '#FFF2E8';
    } else if (row.transactionType == 'Return') {
        style.backgroundColor = '#FFF2E8';
    }
    return style;
};

const ACHTimezoneReport = ({ reportColumns }: any) => {
    const dispatch = useDispatch();
    const actionToken = "ReportDetails";
    const reportTitle = 'Fund By Timezone (ACH)';
    const reportType = ReportType.FinanceACH;
    const reportRoute = Routes.ACHTimezoneReport.path;
    const transactionType = 0;

    const viewDetailsFormatter = (cell: any, row: any) => {
        const breadCrumbDetails = { reportTitle, reportRoute }
        return (
            <span><a onClick={() => { dispatch(achTransactionDetailAction(row, row?.eCheckPayment?.orderId, actionToken, breadCrumbDetails)) }}>View Details</a></span>
        )
    }


    const columns = [
        {
            dataField: 'eCheckPayment.msbId',
            text: 'ID',
            editable: false,
            sort: false,
            hidden: true,
            csvExport: false,
            configurable: false
        }, {
            dataField: 'eCheckPayment.clientId',
            text: 'Client',
            editable: false,
            sort: true,
            default: true,
            configurable: true
        }, {
            dataField: 'clientTimezoneText',
            text: 'Client Timezone',
            editable: false,
            sort: true,
            default: true,
            configurable: true
        }, {
            dataField: 'eCheckPayment.departmentId',
            text: 'Department',
            editable: false,
            sort: true,
            default: true,
            configurable: true
        }, {
            dataField: 'eCheckPayment.paymentChannelId',
            text: 'Payment Channel',
            editable: false,
            sort: true,
            default: false,
            configurable: true
        }, {
            dataField: 'eCheckPayment.orderId',
            text: 'Order ID',
            editable: false,
            sort: true,
            default: true,
            configurable: true
        }, {
            dataField: 'inFile',
            text: 'In File',
            editable: false,

            style: () => {
                return { minWidth: '150px' };
            },
            formatter: (cell: any, row: any) => {
                if (row?.writtenToFileAt) {
                    return 'InFile';
                } else {
                    return 'NotInFile';
                }
            },
            csvFormatter: (cell: any, row: any) => {
                if (row?.writtenToFileAt) {
                    return 'InFile';
                } else {
                    return 'NotInFile';
                }
            },
            filter: selectFilter({
                options: {
                    'InFile': 'InFile',
                    'NotInFile': 'NotInFile',
                },
                // @ts-ignore
                onFilter: (filterVal: any, data: any) => {
                    if (filterVal === 'InFile') {
                        return data.filter((item: any) => item.writtenToFileAt !== undefined);
                    } else if (filterVal === 'NotInFile') {
                        return data.filter((item: any) => item.writtenToFileAt === undefined);
                    }
                    else {
                        return data;
                    }
                },
            }),
        }, {
            dataField: 'writtenToFileAt',
            text: 'Written To File On',
            editable: false,
            sort: true,
            csvFormatter: CSVDateFormatter,
            formatter: DateFormatter,
            default: true,
            configurable: true
        }, {
            dataField: 'transactionId',
            text: 'Transaction ID',
            editable: false,
            sort: true,
            default: true,
            configurable: true
        }, {
            dataField: 'transactionType',
            text: 'Transaction type',
            editable: false,
            sort: true,
            default: true,
            configurable: true
        }, {
            dataField: 'createdAt',
            text: 'Transaction date(Viewers Timezone)',
            editable: false,
            sort: true,
            default: true,
            csvFormatter: CSVDateFormatter,
            formatter: DateFormatter,
            configurable: true
        }, {
            dataField: 'clientTimeZone',
            isDummyField: true,
            text: 'Transaction date(Client Timezone)',
            editable: false,
            sort: true,
            default: true,
            configurable: true
        }, {
            dataField: 'centralTimeZone',
            isDummyField: true,
            text: 'Transaction date(Central Timezone)',
            editable: false,
            sort: true,
            default: true,
            configurable: true
        }, {
            dataField: 'amount',
            text: 'Subtotal',
            editable: false,
            sort: true,
            csvFormatter: CSVCurrencyFormatter,
            formatter: CurrencyFormatter,
            configurable: true
        }, {
            dataField: 'convenienceFee',
            text: 'Convenience fee',
            editable: false,
            sort: true,
            csvFormatter: CSVConvenienceFeeFormatter,
            formatter: ConvenienceFeeFormatter,
            configurable: true
        }, {
            dataField: 'totalAmount',
            text: 'Total amount',
            editable: false,
            sort: true,
            default: true,
            csvFormatter: CSVCurrencyFormatter,
            formatter: CurrencyFormatter,
            configurable: true
        }, {
            dataField: 'accountHolderName',
            text: 'Customer name',
            editable: false,
            sort: true,
            default: true,
            configurable: true
        }, {
            dataField: 'zip',
            text: 'Zip code',
            editable: false,
            sort: true,
            configurable: true
        }, {
            dataField: '',
            isDummyField: true,
            text: 'Actions',
            editable: false,
            csvExport: false,
            formatter: viewDetailsFormatter,
            configurable: false
        }
    ];

    return (
        <>
            <Report
                reportTitle={reportTitle}
                reportType={reportType}
                reportColumns={columns}
                breadCrumbRoute={reportRoute}
                transactionType={transactionType}
                rowStyle={rowStyle}
                formSchema={achFinanceFormSchema}
                searchSummary={true}
            />
        </>
    );
};




export default ACHTimezoneReport;

