import { User, UserTypeEnum } from '../models/User';

export const formatDynamicSearchParameters = (key: any, value: any, currentUser?: User, searchValues?:any) => {

    const containsSeparator = value.toString().includes("|");
    const isClientUser = (currentUser?.userTypeEnum === UserTypeEnum.Client);

    const parametersMap: any = {
        "createdAt": ":from",
        "totalAmount": ":from",
        "amount": ":from",
        "nameOnCard": ":like",
        "initiatedBy": ":like",
        "departmentId": (isClientUser && containsSeparator) ? ":xor" : containsSeparator ? ":or" : "",
        "transactionType":  containsSeparator ? ":or" : "",
        "itemReferenceNumber": searchValues?.itemReferenceToggle ? ":like" : ""
    }

    const formattedParameter = parametersMap[key] ? `${key}${parametersMap[key]}` : `${key}`

    return formattedParameter
};

