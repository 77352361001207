import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from 'react-router-dom';
import { Button, ButtonToolbar, Dropdown, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { BusinessTypeEnum, Client, Department, MerchantProcessor as Merchant, SubMerchantUnderWritingStatusEnum} from '../../models/Client';
import { IFormActionProps } from './components/forms/FormActions';
import FormHeader from './components/forms/FormHeader';
import { Routes } from "../../routes";
import PageSectionContainer from '../../components/layout/PageSectionContainer';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { IActionResult } from "../../redux/storeTypes";
import Table from '../../components/Table';
import { DateFormatter, CurrencyFormatter } from '../../components/Formatters';
import PermissionModule, { Permissions } from '../../components/usePermissions';
import { sendErrorToastAction, sendSuccessToastAction } from "../../redux/actions/toast";
import FormHeaderConfirmation from "../../components/FormHeaderConfirmation";
import icoWarning from "../../assets/img/icons/ico-warning-outline.svg";
import {
    getMerchantAction,
    GET_MERCHANT_FAILURE, GET_MERCHANT_REQUEST, GET_MERCHANT_SUCCESS,
    resetStoreMerchantAction,
    RESET_STORE_MERCHANT_REQUEST, RESET_STORE_MERCHANT_SUCCESS,
    setStoreMerchantAction,
    DELETE_MERCHANT_REQUEST, DELETE_MERCHANT_SUCCESS, DELETE_MERCHANT_FAILURE,
    deleteMerchantAction,
    onboardMerchantAction,
    ONBOARD_MERCHANT_REQUEST,
    ONBOARD_MERCHANT_SUCCESS,
    ONBOARD_MERCHANT_FAILURE
}
    from '../../redux/actions/clients/merchants';

export interface IMerchantsProps {
    client: Client,
    formActionProps?: IFormActionProps,
    sizePerPage?: number,
    actionResult: IActionResult,
    mode: string,
}

const Merchants = ({ client, sizePerPage, actionResult, mode }: IMerchantsProps) => {
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState<string>("");
    const [merchant, setMerchant] = useState<Merchant>(new Merchant());
    const [merchantSummaryModal, setMerchantSummaryModal] = useState<boolean>(false);
    const [merchantToDelete, setMerchantToDelete] = useState<Merchant>();
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

    const actionToken = "Merchants";
    const merchantDeleteActionToken = "MerchantDelete";
    const merchantOnboardActionToken = "MerchantOnboard";

    const onImpersonate = (departmentMsbId: string) => {
        var url = `${window.location.protocol}//${window.location.hostname}`;

        var clientDepartment: any = {};
        clientDepartment.clientMsbId = client.msbId!;
        clientDepartment.departmentMsbId = departmentMsbId;

        localStorage.setItem("impersonateDepartment", JSON.stringify(clientDepartment));
        window.open(url, '_blank');
    }

    useEffect(() => {
        if (actionResult && actionResult.result && actionResult.type === RESET_STORE_MERCHANT_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === RESET_STORE_MERCHANT_SUCCESS) {
                setRedirect(Routes.MerchantAdd.path);
            }
        }
        if (actionResult && actionResult.result && actionResult.type === GET_MERCHANT_REQUEST && actionResult.token === actionToken) {
            if (actionResult.result === GET_MERCHANT_SUCCESS) {
                setRedirect(Routes.MerchantEdit.path);
            } else if (actionResult.result === GET_MERCHANT_FAILURE) {

            }
        }
        if (actionResult && actionResult.result && actionResult.type === DELETE_MERCHANT_REQUEST && actionResult.token === merchantDeleteActionToken) {
            if (actionResult.result === DELETE_MERCHANT_SUCCESS) {
                dispatch(sendSuccessToastAction("Merchant details were successfully deleted."));
            } else if (actionResult.result === DELETE_MERCHANT_FAILURE) {
                dispatch(sendErrorToastAction("Merchant details could not be deleted."));
            }
        }
        if (actionResult && actionResult.result && actionResult.type === ONBOARD_MERCHANT_REQUEST && actionResult.token === merchantOnboardActionToken) {
            if (actionResult.result === ONBOARD_MERCHANT_SUCCESS) {
                dispatch(sendSuccessToastAction("Merchant was successfully onboarded."));
            } else if (actionResult.result === ONBOARD_MERCHANT_FAILURE) {
                dispatch(sendErrorToastAction("Merchant could not be onboarded. Reason(s) : " + actionResult.message));
            }
        }
    }, [actionResult]);

    const dropDownOptions = (cell: any, merchant: Merchant) => {
        return (
            <Dropdown className="req-action-dropdown">
                <Dropdown.Toggle className="approvalAction" variant="link">
                    Manage
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <>
                            <Dropdown.Item onSelect={() => {
                                dispatch(getMerchantAction(merchant.msbId!, actionToken));
                                setMerchant(merchant);
                            }}
                            >
                                Edit Merchant
                            </Dropdown.Item>
                            <Dropdown.Item onSelect={() => {
                                dispatch(onboardMerchantAction(merchant.msbId!, merchantOnboardActionToken));
                                setMerchant(merchant);
                            }}
                            >
                                Onboard Merchant
                            </Dropdown.Item>
                            {/*
                            <Dropdown.Item
                                onSelect={() => {
                                    dispatch(getMerchantAction(merchant.msbId!, 'merchantSummary'));
                                    setMerchantSummaryModal(true);
                                }}
                            >
                                Merchant Summary
                            </Dropdown.Item>
                            */}
                            <Dropdown.Item
                                onSelect={() => { doDeleteMerchant(merchant) }}
                            >
                                Delete Merchant
                            </Dropdown.Item>
                        </>
                    </PermissionModule>
                    {/*
                    <Dropdown.Item onClick={() => onImpersonate(merchant.msbId!)} style={{ borderTop: '1px solid #F0F0F0', marginTop: '4px', paddingTop: '12px', boxShadow: 'inset 0px 4px 0px 0px #fff' }}>
                        Impersonate Merchant User
                    </Dropdown.Item>
                    */}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const doDeleteMerchant = (merchant: Merchant) => {
        setMerchantToDelete(merchant);
        setShowConfirmationModal(true);
    }

    const deleteMerchant = (e: any) => {
        dispatch(deleteMerchantAction(merchantToDelete!.msbId!, merchantDeleteActionToken));
        setShowConfirmationModal(false);
    }

    const renderConfirmationModal = () => (
        <Modal show={showConfirmationModal} className="modal-confirmation">
            <Modal.Body>
                <FormHeaderConfirmation iconImg={icoWarning} title="Delete client" />
                <div className="confirmation-body">
                    <p>Are you sure you want to delete this client?</p>
                    <Form.Group className="confirmation-footer">
                        <Button variant="outline-secondary" onClick={() => setShowConfirmationModal(false)}>
                            Cancel
                        </Button>
                        <Button onClick={(e) => deleteMerchant(e)}>
                            Confirm
                        </Button>
                    </Form.Group>
                </div>
            </Modal.Body>
        </Modal>
    );

    const cellRouteFormatter = (cell: any, row: Merchant) => {
        return (
            <a tabIndex={0} onClick={() => {
                dispatch(getMerchantAction(row.msbId!, actionToken));
                setMerchant(row);
            }}>{cell}</a>
        );
    }

    const cellBusinessTypeFormatter = (cell: any, merchant: Merchant) => {
        return BusinessTypeEnum[cell];
    }

    const cellDbaNameFormatter = (cell: any, row: Merchant) => {
        return row?.vantivConfiguration?.dbaName;
    }

    const cellStatusFormatter = (cell: number, row: Merchant) => {
        let color: string = "#D9D9D9";

        switch (SubMerchantUnderWritingStatusEnum[row?.vantivConfiguration?.underWritingStatus as keyof typeof SubMerchantUnderWritingStatusEnum]) {
            case SubMerchantUnderWritingStatusEnum.Unknown:
                color = "#D9D9D9";
                break;
            case SubMerchantUnderWritingStatusEnum.Pending:
                color = "#FAAD14";
                break;
            case SubMerchantUnderWritingStatusEnum.Approved:
                color = "#52C41A";
                break;
            case SubMerchantUnderWritingStatusEnum.Rejected:
                color = "#F5222D";
                break;
            default:
                color = "#722ED1";
                break;
        }

        return (
            <span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: `${color}` }} />{row?.vantivConfiguration?.underWritingStatus}</span>
        );
    }

    const columns = [
        {
            dataField: 'msbId',
            text: 'Id',
            sort: false,
            hidden: true,
            editable: false
        }, {
            dataField: 'merchantIdentifier',
            text: 'Merchant Identifier',
            editable: false,
            formatter: cellRouteFormatter
        }, {
            dataField: 'businessType',
            text: 'Business Type',
            editable: false,
            formatter: cellBusinessTypeFormatter
        }, {
            dataField: 'dbaName',
            text: 'DBA Name',
            editable: false,
            formatter: cellDbaNameFormatter
        }, {
            dataField: 'updatedAt',
            text: 'Last updated',
            formatter: DateFormatter,
            editable: false
        }, {
            dataField: 'underWritingStatus',
            text: 'Status',
            editable: false,
            sort: true,
            formatter: cellStatusFormatter
        }, {
            dataField: 'id',
            text: 'Actions',
            editable: false,
            formatter: dropDownOptions
        }
    ];

    function addMerchantAction() {
        return (
            <PermissionModule
                permission=
                {[
                    Permissions.ManageClientsDepartmentsPaymentChannelsCreate
                ]}
            >
                <ButtonToolbar>
                    <Button onClick={() => {
                        dispatch(resetStoreMerchantAction(actionToken));
                    }}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" /> Add Merchant
                    </Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }

    if (redirect !== "") {
        return (<Redirect push to={redirect} />)
    } else {
        return (
            <>
                {renderConfirmationModal()}
                {mode !== 'tableView' ? <FormHeader title="Client Merchants" description="Below are the client's merchants." /> : null}

                <Modal size={'lg'} show={merchantSummaryModal} onHide={() => setMerchantSummaryModal(false)}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        {/*
                        <MerchantSummaryModal closeModal={() => setMerchantSummaryModal(false)} />
                        */}
                    </Modal.Body>
                </Modal>

                <div className="manage-card">
                    {client.departments &&
                        <ToolkitProvider keyField="id" data={client.departments} columns={columns} search>
                            {
                                (props: any) => (
                                    <PageSectionContainer title={`${client.businessName} Merchants`} toolbar={addMerchantAction()}>
                                        <Table
                                            keyField="msbId"
                                            data={client.merchantProcessors.filter(mp => mp.processor === "Vantiv")}
                                            columns={columns}
                                        />
                                    </PageSectionContainer>
                                )
                            }
                        </ToolkitProvider>
                    }
                </div>

                {mode !== 'tableView' ?
                    <div className="manage-footer">
                        <Button type="button" variant="link" onClick={() => setRedirect(Routes.Clients.path)} style={{ float: "right" }}>Cancel</Button>
                    </div>
                    : null}
            </>
        );
    }
};

export default Merchants;
