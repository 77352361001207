import * as React from "react";
import { useEffect, useState } from "react";
import { ButtonToolbar, Button, Dropdown, Modal } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import PageSectionContainer from '../../../../components/layout/PageSectionContainer';
import Table from '../../../../components/Table';
import {
    SAVE_PRINTER_REQUEST, SAVE_PRINTER_SUCCESS, SAVE_PRINTER_FAILURE,
    DELETE_PRINTER_REQUEST, DELETE_PRINTER_SUCCESS, DELETE_PRINTER_FAILURE
} from '../../../../redux/actions/clients/printers';
import { sendErrorToastAction, sendSuccessToastAction } from '../../../../redux/actions/toast';
import { IDepartmentManagementnProps } from '../DepartmentManagement';
import { deletePrinterAction } from '../../../../redux/actions/clients/printers';

import { ViewPrinterForm, ManagePrinterForm } from "../forms/department/DepartmentPrinterForms";
import FormHeader from "../forms/FormHeader";
import PermissionModule, { Permissions } from '../../../../components/usePermissions';

const DepartmentPrintersSubSection = ({ department, isFetching, actionResult }: IDepartmentManagementnProps) => {
    const dispatch = useDispatch();
    const [printerMsbId, setPrinterMsbId] = useState("");
    const [viewPrinterForm, showViewPrinterForm] = useState(false);
    const [managePrinterForm, showManagePrinterForm] = useState(false);

    let printerSaveActionToken = "PrinterSaveActionToken";
    let printerDeleteActionToken = "PrinterDeleteActionToken";

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === SAVE_PRINTER_REQUEST && actionResult.token === printerSaveActionToken) {
                if (actionResult.result === SAVE_PRINTER_SUCCESS) {
                    showManagePrinterForm(false);
                    dispatch(sendSuccessToastAction("Printer successfully updated."));
                } else if (actionResult.result === SAVE_PRINTER_FAILURE) {
                    dispatch(sendErrorToastAction("Printer could not be updated."));
                }
            }

            if (actionResult.type === DELETE_PRINTER_REQUEST && actionResult.token === printerDeleteActionToken) {
                if (actionResult.result === DELETE_PRINTER_SUCCESS) {
                    dispatch(sendSuccessToastAction("Printer successfully deleted."));
                } else if (actionResult.result === DELETE_PRINTER_FAILURE) {
                    dispatch(sendErrorToastAction("Printer could not be deleted."));
                }
            }
        }
    }, [actionResult]);  

    const statusFormatter = (cell: any) => {
        if (cell == 0) return (<span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: '#D9D9D9' }} />Offline</span>);
        if (cell == 1) return (<span style={{ display: 'flex', alignItems: 'center' }}><FontAwesomeIcon icon={faCircle} size="xs" className="btn-icon" style={{ color: '#52C41A' }} />Online</span>);
        return "Unknown";
    }

    const actionsFormatter = (cell: any, _row: any) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="link" id="manage-contact-dropdown">Manage</Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onSelect={() => {
                        setPrinterMsbId(cell);
                        showViewPrinterForm(true);
                    }}>
                        View Printer
                    </Dropdown.Item>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsRead,
                            Permissions.ManageClientsDepartmentsPaymentChannelsUpdate
                        ]}
                    >
                        <Dropdown.Item onSelect={() => {
                            setPrinterMsbId(cell);
                            showManagePrinterForm(true);
                        }}>
                            Edit Printer
                        </Dropdown.Item>
                    </PermissionModule>
                    <PermissionModule
                        permission=
                        {[
                            Permissions.ManageClientsDepartmentsPaymentChannelsDelete
                        ]}
                    >
                        <Dropdown.Item onSelect={() => {
                            dispatch(deletePrinterAction(cell, printerDeleteActionToken))
                        }}>
                            Delete Printer
                        </Dropdown.Item>
                    </PermissionModule>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    function doToolbar() {
        return (
            <PermissionModule
                permission=
                {[
                    Permissions.ManageClientsDepartmentsPaymentChannelsCreate
                ]}
            >
                <ButtonToolbar>
                    <Button onClick={() => {
                        setPrinterMsbId("");
                        showManagePrinterForm(true)
                    }}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="btn-icon" />
                        Add Printer
                    </Button>
                </ButtonToolbar>
            </PermissionModule>
        )
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Printer name',
            editable: false
        }, {
            dataField: 'statusEnum',
            text: 'Status',
            editable: false,
            formatter: statusFormatter
        }, {
            dataField: 'msbId',
            text: 'Actions',
            editable: false,
            formatter: actionsFormatter,
        }
    ];

    return (
        <>
            <Modal show={viewPrinterForm} onHide={() => showViewPrinterForm(false)}>
                <Modal.Header closeButton />
                <Modal.Body className="add-entry">
                    <h2 className="mb-4">View Printer</h2>
                    <ViewPrinterForm
                        department={department!}
                        printerMsbId={printerMsbId}
                        formActionProps={{
                            showSpinner: isFetching,
                            hasSubmit: true,
                            onEdit: () => {
                                showViewPrinterForm(false);
                                showManagePrinterForm(true);
                            },
                            customProps: {
                                submitButtonDisplay: "Close"
                            }
                        }}
                        postSubmit={() => showViewPrinterForm(false)}
                    />
                </Modal.Body>
            </Modal>

            <Modal show={managePrinterForm} onHide={() => showManagePrinterForm(false)}>
                <Modal.Header closeButton />
                <Modal.Body className="add-entry">
                  <h2>{printerMsbId ? "Edit Printer" : "Add Printer"}</h2>
                  <p>Enter the Printer details below.</p>
                  <ManagePrinterForm
                      department={department!}
                      printerMsbId={printerMsbId}
                      actionToken={printerSaveActionToken}
                      formActionProps={{
                          showSpinner: isFetching,
                          hasSubmit: true,
                          isEditMode: printerMsbId ? true : false,
                          onCancel: () => showManagePrinterForm(false),
                          customProps: {
                              submitButtonDisplay: "Save"
                          }
                      }}
                  />
                </Modal.Body>
            </Modal>

            <FormHeader title="Printers" description="Available Printers are listed below." />

            <div className="manage-card">
              <PageSectionContainer title="Manage Printers" toolbar={doToolbar()}>
                  <Table 
                    keyField="msbId" 
                    columns={columns} 
                        data={department!.departmentsPrinters || []}
                  />
              </PageSectionContainer>
            </div>
        </>
    );
};

export default DepartmentPrintersSubSection;