import { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Card, Container, ListGroup } from 'react-bootstrap';
import { WorldpaySummary } from '../../../models/Reports';

import { IAppState } from '../../../redux/storeTypes';
import { Crumb } from "../../../models/Crumb";
import PageHeader from "../../../components/layout/PageHeader";
import { Routes } from "../../../routes";
import PageSectionContainer from "../../../components/layout/PageSectionContainer";
import Table from '../../../components/Table';
import { CurrencyFormatter, DateFormatter, DateOnlyFormatter } from "../../../components/Formatters";
import moment from "moment";
import _ from "lodash";

export interface IWorldpayTransactionsDetailsProps {
    summary: WorldpaySummary,
}

const WorldpayTransactionsDetails = ({ summary }: IWorldpayTransactionsDetailsProps) => {
    const [redirect, setRedirect] = useState<string>("");

    var crumbs = new Array<Crumb>();
    crumbs.push(new Crumb("Worldpay Transactions Report", Routes.WorldpayTransactionsReport.path));
    crumbs.push(new Crumb(`${summary.clientName} ${summary.departmentName} Details`, Routes.WorldpayTransactionsReportDetails.path));

    if (redirect != "") return <Redirect push to={redirect} />;

    enum CardInputCode {
        Default = 0,
        Unknown = 1,
        MagstripeRead = 2,
        ContactlessMagstripeRead = 3,
        ManualKeyed = 4,
        ManualKeyedMagstripeFailure = 5,
        ChipRead = 6,
        ContactlessChipRead = 7,
        ManualKeyedChipReadFailure = 8,
        MagstripeReadChipReadFailure = 9,
        MagstripeReadNonTechnicalFallback = 10
    }

    enum TerminalType {
        Unknown = 0,
        PointOfSale = 1,
        ECommerce = 2,
        MOTO = 3,
        FuelPump = 4,
        ATM = 5,
        Voice = 6,
        Mobile = 7,
        WebSiteGiftCard = 8
    }

    const columns = [
        {
            dataField: 'referenceNumber',
            text: 'Reference number',
            editable: false
        },
        {
            dataField: 'transactionID',
            text: 'Transaction ID',
            editable: false
        },
        {
            dataField: 'transactionType',
            text: 'Transaction type',
            editable: false
        },
        {
            dataField: 'transactionStatus',
            text: 'Transaction status',
            editable: false
        },
        {
            dataField: 'expressTransactionDateTime',
            text: 'Transaction date/time',
            editable: false,
            sort: true,
            formatter: JsonDateFormatter,
        },
        {
            dataField: 'transactionAmount',
            text: 'Amount',
            editable: false,
            formatter: CurrencyFormatter
        },
        {
            dataField: 'cardLogo',
            text: 'Card logo',
            editable: false
        },
        {
            dataField: 'cardType',
            text: 'Card type',
            editable: false
        },
        {
            dataField: 'cardNumberMasked',
            text: 'Card number',
            editable: false
        },
        {
            dataField: 'cardInputCode',
            text: 'Card input type',
            editable: false,
            formatter: (code: number) => { return _.startCase(CardInputCode[code]) }
        },
        {
            dataField: 'terminalID',
            text: 'Terminal ID',
            editable: false
        },
        {
            dataField: 'terminalType',
            text: 'Terminal type',
            editable: false,
            formatter: (type: number) => { return _.startCase(TerminalType[type]) }
        },
        {
            dataField: 'hostBatchID',
            text: 'Host batch ID',
            editable: false
        },
    ];

    return (
        <>
            <PageHeader title={`${summary.clientName} ${summary.departmentName} Details`} crumbs={crumbs} />

            <Container fluid className="container-payment-details">

                <PageSectionContainer>
                    <div className="container-transaction-details">
                        <div className="transactionTitle">
                            <h2>{`${summary.clientName} ${summary.departmentName} Details`}</h2>
                            <p>Below are the Worldpay transactions report details.</p>
                        </div>

                        <div className="transactionList">
                            <ListGroup variant="flush">
                                <ListGroup.Item className="p-0">
                                    <div className="transactionDetail">
                                        <h3>Client</h3>
                                        {summary.clientName}
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Department</h3>
                                        {summary.departmentName}
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Merchant ID</h3>
                                        {summary.merchantNumber}
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Merchant type</h3>
                                        {summary.merchantType}
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Transaction date</h3>
                                        {DateOnlyFormatter(summary.transactionDateTime)}
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Sale amount</h3>
                                        {CurrencyFormatter(summary.salesAmount)}
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Sale count</h3>
                                        {summary.salesCount}
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Credits amount</h3>
                                        {CurrencyFormatter(summary.creditsAmount)}
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Credits count</h3>
                                        {summary.creditsCount}
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Net amount</h3>
                                        {CurrencyFormatter(summary.netAmount)}
                                    </div>
                                    <div className="transactionDetail">
                                        <h3>Net count</h3>
                                        {summary.netCount}
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </div>

                        <hr />

                        <Card className="cf-card" style={{ paddingBottom: '16px' }}>
                            <Card.Header>Transactions</Card.Header>
                            <Card.Body>
                                <>
                                    {(summary.transactionItems || []).length <= 0 ? (
                                        <>
                                            <h2 className="fw-bold">No records were found</h2>
                                            <span>Please do another search to find the record you are looking for.</span>
                                        </>
                                    ) : (
                                        <Table
                                            keyField="WorldpayTransactions"
                                            columns={columns}
                                            data={summary.transactionItems} />
                                    )}
                                </>
                            </Card.Body>
                        </Card>
                    </div>

                </PageSectionContainer>
            </Container>
        </>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        summary: state.reports.searchState.worldpaySummary
    };
};

export default connect(mapStateToProps)(WorldpayTransactionsDetails);

export const JsonDateFormatter = (date: string) => {
    const dateString = moment.utc(new Date(date)).local().format('MM-DD-YYYY h:mm:ss a');
    return (
        <span>{dateString}</span>
    );
}
